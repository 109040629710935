import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { fetchFeedbackList } from '../../../../service/ReportsService';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { OverlayPanel } from 'primereact/overlaypanel';


const FeedBackList = () => {
    const [feedbackList, setFeedbackList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const menu = useRef();

    const [currentDateRange, setCurrentDateRange] = useState(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`);
    const op = useRef();

    const [payload, setPayload] = useState({
        startDate: moment().startOf('day'),
        stopDate: moment().endOf('day')
    })
   

    useEffect(() => {
        (async function getAllFeedbacks() {
            setIsLoadingData(true);
            const list = await fetchFeedbackList(payload);
            const init = list.data.map((_x) => ({
                ..._x,
                timestamp: moment(_x.timestamp).toISOString(),
            }))
            setFeedbackList(init || []);
            setFilteredList(init.filter((_x) => moment(_x.timestamp).isBetween(payload.startDate, payload.stopDate) && _x.feedback) || []);
            setIsLoadingData(false);
        })()
    }, []);

    /**
      * Export as CSV
      */
    const exportCSV = () => {
        if (dt && dt.current) {
            dt.current.exportCSV();
        }
    }

    
    const onChangePicker = ({ selection }) => {
        setPayload({
            startDate: moment(selection.startDate).startOf('day'),
            stopDate: moment(selection.endDate).endOf('day'),
        })
        setCurrentDateRange(`${moment(selection.startDate).startOf('day').format('MMM DD, YYYY')} - ${moment(selection.endDate).endOf('day').format('MMM DD, YYYY')}`)
    }

    /**
    * Export as pdf
    */
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, [...filteredList.map((_x) => ({
                    ..._x,
                    timestamp: moment(_x.timestamp).format('MM/DD/YYYY h:mm:ss a')
                }))], {
                    columnStyles: {
                        0: { cellWidth: 25 },
                        1: { cellWidth: 70 },
                        2: { cellWidth: 60 },
                        3: { cellWidth: 25 },
                    }
                });
                doc.save("feedbacks.pdf");
            });
        });
    };

    /**
    * Export columns
    */
    const exportColumns = feedbackList?.[0]
        ? Object.keys(feedbackList?.[0]).reduce((a, c) => {
            const result = c?.replace(/([A-Z])/g, " $1");
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

            a.push({
                title: finalResult,
                dataKey: c,
            });

            return a;
        }, [])
        : [];

    /**
    * Save as excel
    * @param {*} buffer 
    * @param {*} fileName 
    */
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };

    /**
    * Export as excel
    */
    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet([...filteredList.map((_x) => ({
                ..._x,
                timestamp: moment(_x.timestamp).format('MM/DD/YYYY h:mm:ss a')
            }))]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "feedbacks");
        });
    };

    const onRefresh = async () => {
        setIsLoadingData(true);
        const list = await fetchFeedbackList(payload);
        const init = list.data.map((_x) => ({
            ..._x,
            timestamp: moment(_x.timestamp).toISOString(),
        }))
        setCurrentDateRange(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`)
        setPayload({
            startDate: moment().startOf('day'),
            stopDate: moment().endOf('day')
        });
        setFeedbackList(init || []);
        setFilteredList(init.filter((_x) => moment(_x.timestamp).isBetween(payload.startDate, payload.stopDate)  && _x.feedback) || []);
        if(dt.current) {
            dt.current.reset()
        } 
        setIsLoadingData(false);
    }

    const createdOnBody = (rowData) => {
        return moment(rowData.timestamp).format('MM/DD/YYYY');
    }

    const exportFn = (e) => {
        if (e.field == "timestamp") {
            return moment(e.data).format('MM/DD/YYYY h:mm:ss a');
        }
        return e.data;
    }

    const applyChanges = async (e) => {
        setIsLoadingData(true);
        e.preventDefault();
        op.current.hide(e);
        if (dt.current) {
            dt.current.reset();
        }
        const list = await fetchFeedbackList(payload);
        const init = list.data.map((_x) => ({
            ..._x,
            timestamp: moment(_x.timestamp).toISOString(),
        }))
        setFeedbackList(init || []);
        setFilteredList(init.filter((_x) => moment(_x.timestamp).isBetween(payload.startDate, payload.stopDate)  && _x.feedback) || []);
        setIsLoadingData(false);          
    }


    const sortFn = (e) => {
        if (e.order == 1) {
            return filteredList.sort((left,right) => moment.utc(left.timestamp).diff(moment.utc(right.timestamp)))
        }
        return filteredList.sort((left,right) => moment.utc(right.timestamp).diff(moment.utc(left.timestamp)))
    }



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2 flex">
                    {/* <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} tooltip="Add New Intent" tooltipOptions={{position: 'bottom'}}/>
                    <Button label="" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} tooltip="Delete Intent" tooltipOptions={{position: 'bottom'}} /> */}
                 
                    <div className="p-col-12 p-md-4">
                        <div className="p-inputgroup">
                            <InputText value={currentDateRange} readOnly />
                            <Button icon="pi pi-calendar" type="button" onClick={(e) => op.current.toggle(e)} />
                        </div>
                    </div>

                    <OverlayPanel ref={op} showCloseIcon id="overlay_panel" >
                        <DateRangePicker
                            onChange={onChangePicker}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[{
                                startDate: payload.startDate.toDate(),
                                endDate: payload.stopDate.toDate(),
                                key: 'selection'
                            }]}
                            direction="horizontal"
                        />
                        <Button type="button" label="Apply" onClick={applyChanges} />
                    </OverlayPanel>

                    <Button label="" icon="pi pi-refresh" className="p-button-info mx-2" onClick={onRefresh} tooltip="Refresh" tooltipOptions={{ position: 'bottom' }} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                {/* <Button label="Filter" icon="pi pi-ellipsis-v" className="p-button-success mr-2" onClick={openNew} tooltip="Filter"/> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={(event) => menu.current.toggle(event)} />
                <Menu
                    model={[
                        {
                            label: "Export as CSV",
                            icon: "pi pi-file-o",
                            command: () => exportCSV(),
                        },
                        {
                            label: "Export as Excel",
                            icon: "pi pi-file-excel",
                            command: () => exportExcel(),
                        },
                        {
                            label: "Export as PDF",
                            icon: "pi pi-file-pdf",
                            command: () => exportPdf(),
                        },
                    ]}
                    popup
                    ref={menu}
                    id="popup_menu"
                />
            </React.Fragment>
        )
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Feedbacks</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={filteredList}
                        exportFunction={exportFn}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} feedbacks"
                        globalFilter={globalFilter} emptyMessage="No data available." header={header} responsiveLayout="scroll" loading={isLoadingData}>
                        <Column field="session_id" header="SESSION ID" sortable></Column>
                        <Column field="comments" header="COMMENTS" sortable></Column>
                        <Column field="feedback" header="FEEDBACKS" sortable></Column>
                        <Column sortFunction={sortFn} field="timestamp" header="CREATED ON" body={createdOnBody} sortable></Column>
                        {/* <Column  header="ACTIONS"  body={actionBodyTemplate}></Column> */}
                    </DataTable>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FeedBackList, comparisonFn);