import React from 'react';
import classNames from 'classnames';
import { createTeamAPI } from "../../../../service/SettingsService";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import {addAgentHolidays} from '../../../../service/SettingsService';


const AddHolidayDialog = (props) => {
    const formik = useFormik({
        initialValues: {
            description: "",
            date: ""
        },
        validate: (data) => {
            let errors = {};

            if (!data.date) {
                errors.date = "This field is required.";
            }

            if (!data.description) {
                errors.description = "This field is required.";
            }

            return errors;
        },
        onSubmit: async (payload) => {
            try {
                const response = await addAgentHolidays(payload)
                if (response ===200) {
                    formik.resetForm();
                    props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'Holiday Added', life: 3000 });
                } else {
                    props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });   
                }
            } catch (e) {
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };


    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Create Holiday" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="field">
                <label htmlFor="description">Description</label>
                <InputTextarea id="description" value={formik.values.description} onChange={formik.handleChange} required rows={3} cols={20} className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                {getFormErrorMessage('description')}
            </div>
            <div className="field">
                <label htmlFor="date">Date</label>
                <Calendar dateFormat="mm-dd-yy" id="date" name="date" value={formik.values.date} onChange={formik.handleChange} />
                {getFormErrorMessage('date')}
            </div>
        </Dialog>
    )
}

export default AddHolidayDialog;