import * as alertType from "./types";

const types = {
  ...alertType,
};


export const showAlert =
  (payload) =>
  (dispatch) => {
    dispatch({ type: types.SHOW_ALERT, payload: payload });
  };

export const hideAlert =
  () =>
  (dispatch) => {
    dispatch({
      type: types.RESET_ALERT,
    });
  };
