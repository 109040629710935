import React, { useEffect } from 'react';
import classNames from 'classnames';
import { updateTeamAPI, addAgentHolidays, updateAgentHours } from "../../../../service/SettingsService";
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputSwitch } from 'primereact/inputswitch';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';


const EditHoursDialog = (props) => {
    const formik = useFormik({
        initialValues: {
            id: "",
            day: "",
            startTime: "",
            endTime: "",
            active: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.day) {
                errors.day = "This field is required.";
            }

            if (!data.startTime) {
                errors.startTime = "This field is required.";
            }
            if (!/^([01]\d|2[0-3]):?([0-5]\d)$/.test(data.startTime)) {
                errors.startTime = "Invalid data provided.";
            }
            if (!data.endTime) {
                errors.endTime = "This field is required.";
            }
            if (!/^([01]\d|2[0-3]):?([0-5]\d)$/.test(data.endTime)) {
                errors.endTime = "Invalid data provided.";
            }

            if (diff(data.startTime, data.endTime).includes("-")) {
                errors.endTime = "End time less than start time.";
            }

            return errors;
        },
        onSubmit: async (payload) => {
            try {
                const response = await updateAgentHours(payload)
                if (response === 200) {
                    formik.resetForm();
                    props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'Hours Updated', life: 3000 });
                } else {
                    props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
                }
            } catch (e) {
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }
        },
    });

    useEffect(() => {
        formik.setValues({
            ...props.hoursToBeEdited,
            active: Boolean(props.hoursToBeEdited.active)
            // date: moment(props.hoursToBeEdited.date).toDate()
        });
    }, [])

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    function removeColon(s) {
        if (s.length == 4)
            s = s.replace(":", "");

        if (s.length == 5)
            s = s.replace(":", "");

        return parseInt(s);
    }

    // Main function which finds difference
    function diff(s1, s2) {

        // change string (eg. 2:21 --> 221, 00:23 --> 23)
        let time1 = removeColon(s1);

        let time2 = removeColon(s2);


        // difference between hours
        let hourDiff = parseInt(time2 / 100 - time1 / 100 - 1);

        // difference between minutes
        let minDiff = parseInt(time2 % 100 + (60 - time1 % 100));

        if (minDiff >= 60) {
            hourDiff++;
            minDiff = minDiff - 60;
        }

        // convert answer again in string with ':'
        let res = (hourDiff).toString() + ':' + (minDiff).toString();
        return res;
    }


    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Edit Hours" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="field">
                <label htmlFor="day">Day</label>
                <InputText readOnly id="day" value={formik.values.day} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('day') })} />
                {getFormErrorMessage('day')}
            </div>
            <div className="field">
                <label htmlFor="startTime">Start Time</label>
                <InputMask id="startTime" mask="99:99" value={formik.values.startTime} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('startTime') })} />
                {/* <TimePicker id="startTime" name="startTime"    onChange={(value) => formik.setFieldValue("startTime", value)} /> */}
                {/* <Calendar timeOnly id="startTime" name="startTime" value={formik.values.startTime} onChange={formik.handleChange} /> */}
                {getFormErrorMessage('startTime')}
            </div>
            <div className="field">
                <label htmlFor="endTime">End Time</label>
                <InputMask id="endTime" mask="99:99" value={formik.values.endTime} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('endTime') })} />
                {/* <TimePicker id="endTime" name="endTime" value={formik.values.endTime}  onChange={(value) => formik.setFieldValue("endTime", value)} /> */}
                {/* <Calendar timeOnly id="endTime" name="endTime" value={formik.values.endTime} onChange={formik.handleChange} /> */}
                {getFormErrorMessage('endTime')}
            </div>
            <div className="field" style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem'
            }}>
                <label htmlFor="active">Status</label>
                <InputSwitch id="active" name="active" checked={formik.values.active} onChange={(event) => formik.setFieldValue("active", event.value)} />
                {getFormErrorMessage('active')}
            </div>
        </Dialog>
    )
}

export default EditHoursDialog;