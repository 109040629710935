import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "./pages/Routes";
import { useEffect } from "react";
import * as types from "./redux/user/types";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// import { LocalizationProvider } from '@mui/x-date-pickers';

const App = (props) => {
    const [shouldLoadApp, setShouldLoadApp] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
      const authUser = getAuth();
      const unsubscribeUser = onAuthStateChanged(authUser, async (user) => {
        // detaching the listener
        if (user) {
          console.log(user);
          const tokenDetails = await user?.getIdToken();
          const accessTokenDetails = user?.accessToken;
          const refreshTokenDetails = user?.stsTokenManager?.refreshToken;
          localStorage.setItem("id_token", tokenDetails || "");
          localStorage.setItem("access_token", accessTokenDetails || "");
          // localStorage.setItem("refresh_token", refreshTokenDetails || "");
          localStorage.setItem('isAdmin', user.uid);
          localStorage.setItem('IsUE', user.email);

          const userPayload = {
            userId: user?.uid || "",
            emailAddress: user?.email || "",
            role: "CUSTOMER",
            theme: "light",
          };
          dispatch({
            type: types.AUTHENTICATE_USER_SUCCESS,
            payload: { user: userPayload },
          });
          setShouldLoadApp(true);
        } else {
          dispatch({ type: types.LOGOUT_USER_SUCCESS });
          setShouldLoadApp(true);
        }
      });
      return () => unsubscribeUser(); // unsubscribing from the listener when the component is unmounting.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    if (!shouldLoadApp) {
      return null;
    }

    return (
        <Routes {...props}/>
    );

}

export default App;
