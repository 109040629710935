import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UnprotectedRoute({
  component: Component,
  ...rest
}) {
  const isUserLoggedIn = useSelector((state) => state.user.userId);
  
  return (
    <Route
      {...rest}
      render={(componentProps) =>
        !isUserLoggedIn ? (
            <Component {...componentProps} />
        ) : (
          <Redirect to="/live-agent-request" />
        )
      }
    />
  );
}