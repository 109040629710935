import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from '../AppTopbar';
import { AppFooter } from '../AppFooter';
import { AppMenu } from '../AppMenu';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/user/actions';


const DashboardLayout = ({children}) => {
    const [layoutMode, setLayoutMode] = useState('overlay');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const menu = [
        {
            label: '',
            items: [
                { label: 'Live Agent Request', icon: 'pi pi-fw pi-comments', to: '/live-agent-request' }
            ]
        },
        {
            items: [
                {
                    label: 'Chats',
                    icon: 'pi pi-fw pi-comment',
                    items: [
                        { label: 'Completed Chats', icon: 'pi pi-fw pi-comment', to: '/chats/completed-chats' },
                        { label: 'Hudson Chats', icon: 'pi pi-fw pi-comment', to: '/chats/hudson-chats' },
                        { label: "Archived Chats", icon: "pi pi-fw pi-comment", to: "/chats/archived-chats" },
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label: 'Accounts',
                    icon: 'pi pi-fw pi-sliders-h',
                    items: [
                        { label: 'Chats', icon: 'pi pi-fw pi-comment', to: '/meter-submission/chats' },
                        { label: 'Meters', icon: 'pi pi-fw pi-sliders-h', to: '/meter-submission/meters' },
                        // { label: 'Confirmed Reading', icon: 'pi pi-fw pi-sliders-h', to: '/meter-submission/confirmed-reading' }
                    ]
                }
            ]
        },
        {
           
            items: [
                {
                    label: 'Reports',
                    icon: 'pi pi-fw pi-chart-line',
                    items: [
                        { label: 'Analytics', icon: 'pi pi-fw pi-chart-bar', to: '/reports/analytics' },
                        { label: 'Analytics (Graph)', icon: 'pi pi-fw pi-chart-line', to: '/reports/analytics-graph' },
                        { label: 'Feedback List', icon: 'pi pi-fw pi-list', to: '/reports/feedback-list' }
                    ]
                }
            ]
        },
        {
            items: [
                {
                    label:'Settings',
                    icon: 'pi pi-fw pi-cog',
                    items: [
                        { label: 'Create Team', icon: 'pi pi-fw pi-users', to: '/settings/create-team' },
                        { label: 'User Management', icon: 'pi pi-fw pi-user-edit', to: '/settings/user-management' },
                        { label: 'Agent Holidays', icon: 'pi pi-fw pi-list', to: '/settings/agent-holidays'},
                        { label: 'Agent Hours', icon: 'pi pi-fw pi-list', to: '/settings/agent-hours'},
                        { label: 'Configurations', icon: 'pi pi-fw pi-cog', to: '/settings/configurations'},
                        { label: 'Intents List', icon: 'pi pi-fw pi-list', to: '/settings/intents-list', badge: 'BETA' }
                    ]
                }
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const onLogout = () => {
        dispatch(logout());
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} onLogout={onLogout}/>

            <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                  {children}
                </div>
                {/* <AppFooter layoutColorMode={layoutColorMode} /> */}
            </div>
            <chat-widget id="widget" session-id="slfpoh0iexdma2uewnoae2q3" theme="green"> </chat-widget>
            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );

}

export default DashboardLayout;
