import * as userType from "./types";

const types = {
  ...userType,
};

const initialState = {
  userId: null,
  emailAddress: null,
  role: "",
  theme: "light",
};

export default function userReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        ...action.payload?.user,
      };

    case types.LOGOUT_USER_SUCCESS:
      localStorage.removeItem('id_token');
      localStorage.removeItem('access_token');
      // localStorage.removeItem('refresh_token');
      return initialState;

    case types.TOGGLE_DARK_MODE:
      return {
        ...state,
        theme: state.theme === "dark" ? "light" : "dark",
      };

    default:
      return state;
  }
}
