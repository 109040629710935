import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import LoginScreen from "./Authentication/Login/Login";
import ForgotPasswordScreen from "./Authentication/ForgotPassword/ForgotPassword";
import TeamManagement from './Dashboard/Settings/Team Management/CreateTeam';
import TreeDemo from '../components/TreeDemo';
import UnprotectedRoute from "../components/UnprotectedRoute";
import ProtectedRoute from "../components/ProtectedRoute";
import UserManagement from "./Dashboard/Settings/User Management/UserManagement";
import IntentsList from "./Dashboard/Settings/Intents/List";
import FeedbackList from "./Dashboard/Reports/FeedBack/List";
import Analytics from "./Dashboard/Reports/Analytics/Analytics";
import AnalyticsGraph from "./Dashboard/Reports/Analytics/Graph";
import MeterSubmission from "./Dashboard/Meter Submission/MeterSubmission";
import CompletedChats  from "./Dashboard/Chats/Completed/CompletedChats";
import HudsonChats from "./Dashboard/Chats/Hudson/HudsonChat";
import ArchivedChats from "./Dashboard/Chats/Archived/ArchivedChat";
import LiveAgentChats from "./Dashboard/LiveAgent/LiveAgentChats";
import Holidays from "./Dashboard/Settings/Holidays/Holidays";
import AgentHours from "./Dashboard/Settings/AgentHours/AgentHours";
import Configuration from "./Dashboard/Settings/Configuration/Configuration";

export default function Routes({location}) {
  return (
    <Router>
      <Switch>
        <UnprotectedRoute path="/" exact component={LoginScreen} />
        <UnprotectedRoute path="/forgot-password" exact component={ForgotPasswordScreen} />
        <ProtectedRoute path="/live-agent-request" exact component={LiveAgentChats} />
        <ProtectedRoute path="/chats/completed-chats" exact component={CompletedChats} />
        <ProtectedRoute path="/chats/hudson-chats" exact component={HudsonChats} />
        <ProtectedRoute path="/chats/archived-chats" exact component={ArchivedChats} />
        <ProtectedRoute path="/meter-submission/chats" exact component={HudsonChats} />
        <ProtectedRoute path="/meter-submission/meters" exact component={MeterSubmission} />
        {/* <ProtectedRoute path="/meter-submission/confirmed-reading" exact component={TreeDemo} /> */}
        <ProtectedRoute path="/reports/analytics" exact component={Analytics} componentProps={{colorMode: 'light', location: location}} />
        <ProtectedRoute path="/reports/analytics-graph" exact component={AnalyticsGraph} componentProps={{colorMode: 'light', location: location}} />
        <ProtectedRoute path="/reports/feedback-list" exact component={FeedbackList} />
        <ProtectedRoute path="/settings/create-team" exact component={TeamManagement} />
        <ProtectedRoute path="/settings/user-management" exact component={UserManagement} />
        <ProtectedRoute path="/settings/intents-list" exact component={IntentsList} />
        <ProtectedRoute path="/settings/agent-holidays" exact component={Holidays} />
        <ProtectedRoute path="/settings/agent-hours" exact component={AgentHours} />
        <ProtectedRoute path="/settings/configurations" exact component={Configuration} />
      </Switch>
    </Router>
  );
}
