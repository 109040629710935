import React from 'react';
import '../assets/layout/chat.css';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
 } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import avatar from '../assets/img/profile/avatar-4.png';
import chevron from '../assets/img/chevron_right.png';
// import LaunchIcon from '@mui/icons-material/Launch';


export const ChatTemplate = (props) => {

    console.log(props);
      
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="chat-conversation p-3 p-lg-3" data-simplebar="init">
            <div className="simplebar-wrapper" style={{ margin: "-24px" }}>
                <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                </div>
                <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: '-17px', bottom: '0px' }}>
                        <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                            <div className="simplebar-content" style={{ padding: '0px', overflowY: 'auto', maxHeight: '100%' }}>
                                <ul className="list-unstyled mb-0">
                                    {
                                        props.historyData.map((data, index) => (
                                            <div key={data.id}>
                                                {data.user != undefined && (data.user).includes("E000") != true && <li className="right">
                                                    <div className="conversation-list">
                                                        <div className="chat-avatar">
                                                            <div className="chat-user-img align-self-center mr-3">
                                                                <div className="avatar-xs">
                                                                    {
                                                                        props.activeUser &&
                                                                         <span className="avatar-title rounded-circle bg-soft-primary" style={{ backgroundColor: '#FF9800', borderRadius:'50%', padding:'15px' }}>
                                                                            {(props.activeUser.activeUserInfo.toString() == '' ? props.activeUser.activeSessionIdInfo.toString().charAt(0) : props.activeUser.activeUserInfo.toString().charAt(0))}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="user-chat-content">
                                                            <div className="ctext-wrap" style={{ display: 'inline-block' }}>
                                                                <div className="ctext-wrap-content">
                                                                    <p className="mb-0">
                                                                        {data.user || ''}
                                                                    </p>
                                                                    <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{data.timestamp || ''}</span></p>
                                                                    <p className="chat-time mb-0">
                                                                        <i className="ri-time-line align-middle"></i>
                                                                        <span className="align-middle"> {data.sentimentScore || ''}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {props.activeUser && props.activeUser.activeUserInfo && <p className="conversation-name">{capitalize(props.activeUser.activeUserInfo) || ''}</p>}
                                                        </div>
                                                    </div>
                                                </li>
                                                }

                                                {data.agent != undefined && data.agent.length > 0 && <li>
                                                    <div className="conversation-list">
                                                        <div className="chat-avatar">
                                                            <img src={avatar} alt="" />
                                                        </div>
                                                        <div className="user-chat-content">
                                                            <div className="ctext-wrap">
                                                                <div className="ctext-wrap-content">
                                                                    <p className="mb-0">
                                                                        {data.agent || ''}
                                                                    </p>
                                                                    <p className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle">{data.timestamp || ''}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="conversation-name">
                                                                {'Hudson'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                }

                                                {data.simpleMessagesActionArray != undefined && data.simpleMessagesActionArray.length > 0 && <li>
                                                    <div className="conversation-list">
                                                        <div className="chat-avatar">
                                                            <img src={avatar} alt="" />
                                                        </div>
                                                        <div className="user-chat-content">
                                                            {data.simpleMessagesActionArray.map((simpleMessagesActionArrayObj) => (
                                                                <div className="ctext-wrap" key={simpleMessagesActionArrayObj.text}>
                                                                    <div className="ctext-wrap-content">
                                                                        <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${simpleMessagesActionArrayObj.text}` }}>

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="conversation-name">
                                                                {'Hudson'} <p style={{ color: 'black', textAlign: 'left' }} className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle"> {data.timestamp || ''}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                }

                                                {data.richAgent != undefined && data.richAgent.length > 0 && <li>
                                                    <div className="conversation-list">
                                                        <div className="chat-avatar">
                                                            <img src={avatar} alt="" />
                                                        </div>
                                                        <div className="user-chat-content">
                                                            {data.simpleMessagesActionArray.map((simpleMessagesActionArrayObj) => (
                                                                <div className="ctext-wrap" key={simpleMessagesActionArrayObj.text}>
                                                                    <div className="ctext-wrap-content">
                                                                        <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${simpleMessagesActionArrayObj.text}` }}>

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {data.richAgent.map((agentObj,i) => (
                                                                <div key={`${agentObj.name}_${i}`}>
                                                                    {(() => {
                                                                        switch (agentObj.name) {
                                                                            case "image":
                                                                                return <div>
                                                                                    {agentObj.image.map((imgObj) => (
                                                                                        <div className="CardDiv-class" key={imgObj.imageUrl}>
                                                                                            {/* <Card> */}
                                                                                                <img style={{ width: '500px' }} src={imgObj.imageUrl} alt={imgObj.alttext} />
                                                                                            {/* </Card> */}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>;
                                                                            case "simpleMessages":
                                                                                return <div>
                                                                                    {agentObj.simpleMessages.map((smsg) => (
                                                                                        <div className="ctext-wrap" key={smsg.text}>
                                                                                            <div className="ctext-wrap-content">
                                                                                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: `${smsg.text}` }}>

                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>;
                                                                            case "suggestionChips":
                                                                                return <div>
                                                                                    <div style={{ marginTop: '5px', width: '500px' }}>
                                                                                        {agentObj.buttonChip.map((btnChipObj,i) => (
                                                                                            <div key={i}>
                                                                                                {btnChipObj.btnChip.map((btnListObj,j) => (
                                                                                                    <Chip key={`${btnListObj.stringValue}_${i}_${j}`} className="chip-class" label={btnListObj.stringValue} />
                                                                                                ))
                                                                                                }
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>;
                                                                            case "accordion":
                                                                                return <div>
                                                                                    {agentObj.accordion.map((accordObj) => (
                                                                                        <Card key={accordObj.description} style={{ marginTop: '5px', width: '500px' }}>
                                                                                            <div style={{ width: '500px' }} id="descriptionWrapper">
                                                                                                <div className="description-line" dangerouslySetInnerHTML={{ __html: `${accordObj.description}` }} ></div>
                                                                                            </div>
                                                                                            <Accordion allowZeroExpanded>
                                                                                                <AccordionItem uuid="a">
                                                                                                    <AccordionItemHeading>
                                                                                                        <AccordionItemButton >
                                                                                                            {accordObj.subtitle}
                                                                                                        </AccordionItemButton>
                                                                                                    </AccordionItemHeading>
                                                                                                    <AccordionItemPanel>
                                                                                                        <div>
                                                                                                            <div className="list-unstyled chat-list chat-user-list">
                                                                                                                <div dangerouslySetInnerHTML={{ __html: `${accordObj.text}` }}></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </AccordionItemPanel>
                                                                                                </AccordionItem>
                                                                                            </Accordion>
                                                                                        </Card>
                                                                                    ))}
                                                                                </div>;
                                                                            case "buttonChips":
                                                                                return <div>
                                                                                    <div style={{ marginTop: '5px', width: '500px' }}>
                                                                                        {agentObj.buttonChips.map((btnObj) => (
                                                                                            <div key={btnObj.chipValue}>
                                                                                                <Chip className="chip-class" label={btnObj.chipValue} image={btnObj.imageUrl}></Chip>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            case "descriptionButton":
                                                                                return <div>
                                                                                    <Card>
                                                                                        {agentObj.description.map((descObj) => (
                                                                                            <div className="description-line" key={descObj.text}>
                                                                                                <p dangerouslySetInnerHTML={{ __html: `${descObj.text}` }}></p>
                                                                                            </div>
                                                                                        ))}
                                                                                        <div style={{ marginTop: '5px', width: '500px' }}>
                                                                                            {agentObj.buttonsList.map((btnListObj) => (
                                                                                                <div key={btnListObj.text}>
                                                                                                    <a className="chip-class"><img src={chevron}></img><div>{btnListObj.text}</div></a>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                            case "linkOutSuggestion":
                                                                                return <div>
                                                                                    <div style={{ marginTop: '5px', width: '500px' }}>
                                                                                        {agentObj.linkOutSuggestion.map((linkOutObj) => (
                                                                                            <div key={linkOutObj.text}>
                                                                                                <Chip className="chip-class" label={linkOutObj.text} style={{pointerEvents: 'none'}} removable removeIcon={'pi pi-external-link'} />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    })()}
                                                                </div>
                                                            ))}
                                                            <div className="conversation-name">
                                                                {'Hudson'} <p style={{ color: 'black', textAlign: 'left' }} className="chat-time mb-0"><i className="ri-time-line align-middle"></i> <span className="align-middle"> {data.timestamp || ''}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                }

                                            </div>
                                        ))
                                    }


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="simplebar-placeholder" style={{ width: 'auto', height: '1150px' }}></div>
            </div>
            <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                <div className="simplebar-scrollbar" style={{ transform: 'translate3d(0px, 0px, 0px)', display: 'none' }}></div>
            </div>
            <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                <div className="simplebar-scrollbar" style={{ height: '108px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
            </div>
        </div>
    )
}