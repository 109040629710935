import React, { useEffect, useState, useRef } from "react";
import { ChatTemplate } from "../../../../components/ChatTemplate";
import { fetchChatThreadBySessionId, fetchCenhudChatHistory, fetchChatDataUpdate, updateArchive, fetchIndividualChatDetails } from "../../../../service/ChatsService";
import moment from 'moment';
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { DateRangePicker } from "react-date-range";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { VirtualScroller } from 'primereact/virtualscroller';
import { Divider } from "primereact/divider";
import { Avatar } from 'primereact/avatar';
import { ProgressSpinner } from 'primereact/progressspinner';
import EmptyRecords from "../EmptyRecords";



// import PhoneIcon from '@mui/icons-material/Phone';
// import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
// import AddLocationIcon from '@mui/icons-material/AddLocation';
// import ContactsIcon from '@mui/icons-material/Contacts';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EditUserDialogChats from "../EditUserDialog";
import { Toast } from "primereact/toast";

const HudsonChats = (props) => {

   const [historyData, setHistoryData] = useState([]);
   const [chatData, setChatData] = useState([]);
   const [currentChat, setCurrentChat] = useState([]);
   const [sessionIDList, setSessionIDList] = useState([]);
   const [activeData, setActiveData] = useState(null);
   const [currentDateRange, setCurrentDateRange] = useState(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`);
   const [payload, setPayload] = useState({
      startDate: moment().startOf('day'),
      stopDate: moment().endOf('day')
   })

   const op = useRef();
   const toast = useRef();
   const [loading, setLoading] = useState(true);
   const [chatLoading, setChatLoading] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);

   useEffect(() => {
      (
         async function chatHistory() {
            setLoading(true);
            let params = new URLSearchParams(window.location.search);
            let querySessionID = params.get('sessionid');
            if (querySessionID != undefined && querySessionID != '') {
               await fetchIndividualChat({
                  nameValue: '',
                  sessionIdValue: '',
                  ...payload,  
               }, querySessionID);
            } else {
               await fetchChatHistory({
                  nameValue: '',
                  sessionIdValue: '',
                  ...payload,
               })
            }
            setLoading(false);
         }
      )()

   }, [])

   const onRefresh = () => {
      (
         async function chatHistory() {
            setLoading(true);
            await fetchChatHistory({
               nameValue: '',
               sessionIdValue: '',
               ...payload,
            })
            setLoading(false);
         }
      )()
   }

   const fetchIndividualChat = async (payload, querySessionID) => {
      let langCode = localStorage.getItem('team');
      if (langCode == "ChatTeam - EN") {
         langCode = "en-US";
      } else if (langCode == "ChatTeam - ES") {
         langCode = "es-ES";
      } else {
         langCode = "All";
      }
      try {
         const responseData = await fetchIndividualChatDetails(payload, querySessionID)
         // We get the API response and receive data in JSON format...
         const data = responseData.data;
         // ...then we update the users state
         //console.log(JSON.stringify(data));
         if (data && data != '') {
                  var output = [];
                  for (var key in data) {
                     try {

                        if (data[key] && data[key].session_id) {

                           if (data[key] && data[key].Name) {
                              var objName = data[key].Name;
                           }
                           else {
                              objName = '';
                           }

                           if (data[key] && data[key].email) {
                              var objEmail = data[key].email;
                           }
                           else {
                              objEmail = '';
                           }

                           if (data[key] && data[key].phone) {
                              var objPhone = data[key].phone;
                           }
                           else {
                              objPhone = '';
                           }

                           if (data[key] && data[key].address) {
                              var objAddress = data[key].address;
                           }
                           else {
                              objAddress = "";
                           }

                           if (data[key] && data[key].comments) {
                              var objComments = data[key].comments;
                           }
                           else {
                              objComments = "";
                           }
                           if (data[key] && data[key].customerType) {
                              var objCustomerType = data[key].customerType;
                           }
                           else {
                              objCustomerType = "";
                           }

                           var objUpdatedByName = "";
                           var objUpdatedTs = "";
                           var objAction = "";
                           var sessionHistory = [];
                           if (data[key] && data[key].History) {
                              for (var obj in data[key].History) {
                                 objUpdatedByName = data[key].History[obj].updatedByName;
                                 var sessionstartdatetime = new Date(data[key].History[obj].updatedTs * 1000).toLocaleString("en-us", { timeZone: "America/New_York" });
                                 objUpdatedTs = sessionstartdatetime.replace(",", "");
                                 objAction = data[key].History[obj].action;
                                 var sessionHis = {
                                    objUpdatedByName: data[key].History[obj].updatedByName,
                                    objUpdatedTs: sessionstartdatetime.replace(",", ""),
                                    objAction: data[key].History[obj].action,
                                    unixtime: data[key].History[obj].updatedTs
                                 }
                                 sessionHistory.push(sessionHis);
                              }
                           }
                           var sortedArray = sessionHistory.sort((n1, n2) => {
                              if (n1.unixtime > n2.unixtime) {
                                 return 1;
                              }

                              if (n1.unixtime < n2.unixtime) {
                                 return -1;
                              }

                              return 0;
                           });

                           sessionHistory = sortedArray.reverse();

                           var sessionstartdatetime = new Date(data[key].timestamp_new).toLocaleString("en-us", { timeZone: "America/New_York" });
                           var sessionStartTimeFormated = sessionstartdatetime.replace(",", "");
                           var last2char = sessionStartTimeFormated.slice(-2);
                           sessionStartTimeFormated = sessionStartTimeFormated.slice(0, -6);
                           sessionStartTimeFormated = sessionStartTimeFormated + ' ' + last2char;
                           var accountInfoArray = [];
                           if (data[key] && data[key].accounts) {
                              for (var obj in data[key].accounts) {
                                 var accounts = {
                                    accountNumber: data[key].accounts[obj].number + '-' + data[key].accounts[obj].checkDigit,
                                    name: data[key].accounts[obj].name,
                                    amountDue: parseFloat(data[key].accounts[obj].amountDue).toFixed(2),
                                    address: data[key].accounts[obj].serviceAddress.line1 + ', ' + data[key].accounts[obj].serviceAddress.line2
                                 }
                                 accountInfoArray.push(accounts);
                              }
                           }
                           if (langCode == data[key].Language) {
                              var result = {
                                 session_id: data[key].session_id,
                                 name: objName,
                                 timestamp: sessionStartTimeFormated,
                                 phone: objPhone,
                                 email: objEmail,
                                 address: objAddress,
                                 comments: objComments,
                                 customerType: objCustomerType,
                                 userDetailsUpdatedBY: objUpdatedByName,
                                 userDetailsUpdatedTs: objUpdatedTs,
                                 lastAction: objAction,
                                 sessionHistoryArray: sessionHistory,
                                 Thread: Array(),
                                 accountInfo: accountInfoArray,
                                 Language: data[key].Language
                              };
                              output.push(result);
                           } else if (langCode == "All") {
                              var result = {
                                 session_id: data[key].session_id,
                                 name: objName,
                                 timestamp: sessionStartTimeFormated,
                                 phone: objPhone,
                                 email: objEmail,
                                 address: objAddress,
                                 comments: objComments,
                                 customerType: objCustomerType,
                                 userDetailsUpdatedBY: objUpdatedByName,
                                 userDetailsUpdatedTs: objUpdatedTs,
                                 lastAction: objAction,
                                 sessionHistoryArray: sessionHistory,
                                 Thread: Array(),
                                 accountInfo: accountInfoArray,
                                 Language: data[key].Language
                              };
                              output.push(result);
                           }
                        }
                     } catch (e) {
                        console.log('error', e);
                     }
                  }
               if (output && output.length > 0) {
                  output = output.reverse();
                  setChatData(output)
                  setSessionIDList(output);
                  if (output.length > 0) {
                     viewChatHistoryData(0, output);
                  } else {
                     try {
                        setHistoryData([])
                     } catch (e) {
                        console.log('error', e);
                     }
                  }
               } else {
                  setHistoryData([]);
               }
            }  else {
               setHistoryData([])
               setChatData([]);
               setCurrentChat([]);
               setSessionIDList([]);
               setActiveData(null);
           }
      } catch (e) {
         console.log(e);
      }
   }


   const fetchChatHistory = async (payload) => {
      let langCode = localStorage.getItem('team');
      if (langCode == "ChatTeam - EN") {
         langCode = "en-US";
      } else if (langCode == "ChatTeam - ES") {
         langCode = "es-ES";
      } else {
         langCode = "All";
      }
      try {

         const responseData = await fetchCenhudChatHistory(payload, '')
         const data = responseData.data;

         if (data && data != '') {
            var output = [];
            for (var key in data) {
               if (data[key] && data[key].Name) {
                  var objName = data[key].Name;
               }
               else {
                  objName = '';
               }

               if (data[key] && data[key].email) {
                  var objEmail = data[key].email;
               }
               else {
                  objEmail = '';
               }

               if (data[key] && data[key].phone) {
                  var objPhone = data[key].phone;
               }
               else {
                  objPhone = '';
               }

               if (data[key] && data[key].address) {
                  var objAddress = data[key].address;
               }
               else {
                  objAddress = "";
               }

               if (data[key] && data[key].comments) {
                  var objComments = data[key].comments;
               }
               else {
                  objComments = "";
               }
               if (data[key] && data[key].customerType) {
                  var objCustomerType = data[key].customerType;
               }
               else {
                  objCustomerType = "";
               }
               var objUpdatedByName = "";
               var ObjpdatedUserName = "";
               var ObjUpdatedTeamName = "";
               var objUpdatedTs = "";
               var objAction = "";
               var sessionHistory = [];
               if (data[key] && data[key].History) {
                  for (var obj in data[key].History) {
                     objUpdatedByName = data[key].History[obj].updatedByName;
                     var sessionstartdatetime = new Date(data[key].History[obj].updatedTs * 1000).toLocaleString("en-us", { timeZone: "America/New_York" });
                     objUpdatedTs = sessionstartdatetime.replace(",", "");
                     objAction = data[key].History[obj].action;
                     var sessionHis = {
                        objUpdatedByName: data[key].History[obj].updatedByName,
                        objUpdatedTs: sessionstartdatetime.replace(",", ""),
                        objAction: data[key].History[obj].action,
                        unixtime: data[key].History[obj].updatedTs
                     }
                     sessionHistory.push(sessionHis);
                  }
               }
               var sortedArray = sessionHistory.sort((n1, n2) => {
                  if (n1.unixtime > n2.unixtime) {
                     return 1;
                  }

                  if (n1.unixtime < n2.unixtime) {
                     return -1;
                  }

                  return 0;
               });

               sessionHistory = sortedArray.reverse();


               var escallationArr = [];
               var readval = false;
               var readIcon = 'person_add'
               if (data[key] && data[key].escalations) {
                  for (var id in data[key].escalations) {
                     if (data[key].escalations[id].Read == true) {
                        readval = true;
                        readIcon = 'person'
                     }
                     var escallateddatetime = new Date(data[key].escalations[id].ReceivedOn).toLocaleString("en-us", { timeZone: "America/New_York" });
                     var escallateddatetimeFormated = escallateddatetime.replace(",", "");
                     var last2char = escallateddatetimeFormated.slice(-2);
                     escallateddatetimeFormated = escallateddatetimeFormated.slice(0, -6);
                     escallateddatetimeFormated = escallateddatetimeFormated + ' ' + last2char;
                     var escallation = {
                        escallationsID: data[key].escalations[id].escalationid,
                        escallatedTime: escallateddatetimeFormated,
                     }
                     escallationArr.push(escallation);
                  }
               }

               if (data[key] && data[key].Escalations) {
                  for (var id in data[key].Escalations) {
                     if (data[key].Escalations[id].Read == true) {
                        readval = true;
                        readIcon = 'person'
                     }
                     var escallateddatetime = new Date(data[key].Escalations[id].ReceivedOn).toLocaleString("en-us", { timeZone: "America/New_York" });
                     var escallateddatetimeFormated = escallateddatetime.replace(",", "");
                     var last2char = escallateddatetimeFormated.slice(-2);
                     escallateddatetimeFormated = escallateddatetimeFormated.slice(0, -6);
                     escallateddatetimeFormated = escallateddatetimeFormated + ' ' + last2char;
                     var escallationObj = {
                        escallationsID: id,
                        escallatedTime: escallateddatetimeFormated,
                     }
                     escallationArr.push(escallationObj);
                  }
               }

               if (escallationArr.length > 0) {
                  escallationArr.sort(sortByDesc('escallatedTime'));
               }

               var sessionstartdatetime = new Date(data[key].timestamp_new).toLocaleString("en-us", { timeZone: "America/New_York" });
               var sessionStartTimeFormated = sessionstartdatetime.replace(",", "");
               var last2char = sessionStartTimeFormated.slice(-2);
               sessionStartTimeFormated = sessionStartTimeFormated.slice(0, -6);
               sessionStartTimeFormated = sessionStartTimeFormated + ' ' + last2char;
               var accountInfoArray = [];
               if (data[key] && data[key].accounts) {
                  for (var obj in data[key].accounts) {
                     var accounts = {
                        accountNumber: data[key].accounts[obj].number + '-' + data[key].accounts[obj].checkDigit,
                        name: data[key].accounts[obj].name,
                        amountDue: parseFloat(data[key].accounts[obj].amountDue).toFixed(2),
                        address: (data[key].accounts[obj].serviceAddress ? data[key].accounts[obj].serviceAddress.line1 : '') + ', ' + (data[key].accounts[obj].serviceAddress ? data[key].accounts[obj].serviceAddress.line2 : '')
                     }
                     accountInfoArray.push(accounts);
                  }
               }
               if (langCode == data[key].Language) {
                  var result = {
                     session_id: data[key].session_id,
                     name: objName,
                     timestamp: sessionStartTimeFormated,
                     phone: objPhone,
                     email: objEmail,
                     address: objAddress,
                     comments: objComments,
                     customerType: objCustomerType,
                     read: readval,
                     readIcon: readIcon,
                     //updatedByName: objUpdatedByName,
                     userDetailsUpdatedBY: objUpdatedByName,
                     userDetailsUpdatedTs: objUpdatedTs,
                     updatedUserName: ObjpdatedUserName,
                     updatedTeamName: ObjUpdatedTeamName,
                     escallationsArr: escallationArr,
                     lastAction: objAction,
                     sessionHistoryArray: sessionHistory,
                     Thread: Array(),
                     accountInfo: accountInfoArray,
                     Language: data[key].Language
                  };
                  output.push(result);
               } else if (langCode == "All") {
                  var result = {
                     session_id: data[key].session_id,
                     name: objName,
                     timestamp: sessionStartTimeFormated,
                     phone: objPhone,
                     email: objEmail,
                     address: objAddress,
                     comments: objComments,
                     customerType: objCustomerType,
                     read: readval,
                     readIcon: readIcon,
                     //updatedByName: objUpdatedByName,
                     userDetailsUpdatedBY: objUpdatedByName,
                     userDetailsUpdatedTs: objUpdatedTs,
                     updatedUserName: ObjpdatedUserName,
                     updatedTeamName: ObjUpdatedTeamName,
                     escallationsArr: escallationArr,
                     lastAction: objAction,
                     sessionHistoryArray: sessionHistory,
                     Thread: Array(),
                     accountInfo: accountInfoArray,
                     Language: data[key].Language
                  };
                  output.push(result);
               }
            }
            if (output && output.length > 0) {
               output = output.reverse();
               setChatData(output)
               setSessionIDList(output);
               if (output.length > 0) {
                  viewChatHistoryData(0, output);
               }
               else {
                  try {
                     setHistoryData([])
                  } catch (e) {
                     console.log('error', e);
                  }
               }
            } else {
               setHistoryData([]);
            }
         } else {
            setHistoryData([])
            setChatData([]);
            setCurrentChat([]);
            setSessionIDList([]);
            setActiveData(null);
        }
      } catch (error) {
         console.log(error);
      }
   }

   const sortByDesc = (key) => {
      return (a, b) => {
         if (a[key] < b[key]) return 1;
         if (a[key] > b[key]) return -1;
         return 0;
      };
   }

   const viewChatHistoryData = async (indexNo, chatInfo) => {
      try {
         const responseData = await fetchChatThreadBySessionId(chatInfo[indexNo]['session_id'])
         const data = responseData.data;
         if (data && data != '') {
            var outputThread = [];
            let accordionArray = [];
            let simplemessageArray = [];
            let buttonChipsArray = [];
            let agentChatDataArray = [];
            let btnArr = [];
            let descriArray = [];
            let linkoutsuggestionObj = [];
            let suggestionChipArray = [];
            let imageAr = [];
            let simpleMessagesActionArray = [];
            var actionOnGoogleFlag = false;
            for (var key in data) {
               if (data[key].insertId) {
                  try {
                     var resultObj = 'result' in data[key].textPayload;
                     if (resultObj) {
                        var richText = 'payload' in data[key].textPayload.result.fulfillment.messages;
                        var sentiment = '';
                        if (data[key].textPayload.sentiment_analysis_result) {
                           if (data[key].textPayload.sentiment_analysis_result.query_text_sentiment) {
                              if (data[key].textPayload.sentiment_analysis_result.query_text_sentiment.score) {
                                 sentiment = 'Sentiment :' + data[key].textPayload.sentiment_analysis_result.query_text_sentiment.score;
                              }
                           }
                        }

                        if (richText) {
                           var richContent = data[key].textPayload.result.fulfillment.messages.payload.fields.key;
                        }
                        else {
                           richContent = "";
                        }
                        var agentReply = "";
                        if (data[key].textPayload.result.fulfillment.speech == undefined) {
                           agentReply = "Agent Responded with the intent - " + data[key].textPayload.result.metadata.intent_name;
                        }
                        else {
                           agentReply = data[key].textPayload.result.fulfillment.speech;
                        }
                        var sessionThreadtartdatetime = new Date(data[key].textPayload.timestamp).toLocaleString("en-us", { timeZone: "America/New_York" });
                        outputThread.push({
                           timestamp: sessionThreadtartdatetime.replace(",", ""),
                           id: data[key].textPayload.id,
                           user: data[key].textPayload.result.resolved_query,
                           agent: agentReply,
                           richAgent: [],
                           rich: richContent,
                           userName: data[key].textPayload.result.source,
                           sentimentScore: sentiment
                        });
                     }
                     else {
                     }
                  } catch (error) {
                     console.log('undefined in payload', error);
                  }
               } else {
                  actionOnGoogleFlag = false;
                  agentChatDataArray = [];
                  simpleMessagesActionArray = [];
                  var frmtTextPayload = JSON.parse(data[key].textPayload);
                  var sentiment = '';
                  if (frmtTextPayload) {
                     if (data[key].queryTextSentiment) {
                        if (data[key].queryTextSentiment.score) {
                           sentiment = 'Sentiment :' + data[key].queryTextSentiment.score.toFixed(1);
                        }
                     }

                     var sessionThreadtartdatetime = new Date(data[key].timestamp_new).toLocaleString("en-us", { timeZone: "America/New_York" });

                     for (var txtPayloadObj in frmtTextPayload) {
                        if (frmtTextPayload[txtPayloadObj].platform == "ACTIONS_ON_GOOGLE" && frmtTextPayload[txtPayloadObj].message == "payload") {
                           actionOnGoogleFlag = true;
                           if (frmtTextPayload[txtPayloadObj].payload.fields.metadata) {
                              if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber) {
                                 for (var entuberObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values) {
                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.order) {
                                       var orderNoValue = frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.order.stringValue;
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.name) {
                                       var nameValue = frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.name.stringValue;
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.accordion) {
                                       accordionArray = [];
                                       for (var accordObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.accordion.listValue.values) {
                                          var accordionObj = {
                                             description: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.accordion.listValue.values[accordObj].structValue.fields.description.structValue.fields.test.stringValue,
                                             subtitle: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.accordion.listValue.values[accordObj].structValue.fields.Accordian.structValue.fields.subtitle.stringValue,
                                             text: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.accordion.listValue.values[accordObj].structValue.fields.Accordian.structValue.fields.text.stringValue,
                                          }
                                          accordionArray.push(accordionObj);
                                       }
                                       var newAccordObj = {
                                          orderNo: orderNoValue,
                                          name: nameValue,
                                          accordion: accordionArray
                                       }
                                       agentChatDataArray.push(newAccordObj);
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.simpleMessages) {
                                       simplemessageArray = [];
                                       for (var smplObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.simpleMessages.listValue.values) {
                                          var simpleMsgObj = {
                                             text: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.simpleMessages.listValue.values[smplObj].structValue.fields.text.stringValue,
                                          }
                                          simplemessageArray.push(simpleMsgObj);
                                       }
                                       var newSimpleMsgObj = {}
                                       newSimpleMsgObj = {
                                          orderNo: orderNoValue,
                                          name: nameValue,
                                          simpleMessages: simplemessageArray
                                       }
                                       agentChatDataArray.push(newSimpleMsgObj);
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.buttonChips) {
                                       buttonChipsArray = [];
                                       for (var buttonObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.buttonChips.listValue.values) {
                                          var btnChipsObj = {
                                             chipValue: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.buttonChips.listValue.values[buttonObj].structValue.fields.chipValue.stringValue,
                                             imageUrl: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.buttonChips.listValue.values[buttonObj].structValue.fields.imageUrl.stringValue,
                                          }
                                          buttonChipsArray.push(btnChipsObj);
                                       }
                                       var newButtonChipsObj = {
                                          orderNo: orderNoValue,
                                          name: nameValue,
                                          buttonChips: buttonChipsArray
                                       }
                                       agentChatDataArray.push(newButtonChipsObj);
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.button) {
                                       for (var bObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.button.listValue.values) {
                                          var btn = {
                                             text: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.button.listValue.values[bObj].structValue.fields.button.structValue.fields.text.stringValue,
                                             link: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.button.listValue.values[bObj].structValue.fields.button.structValue.fields.link.stringValue,
                                          }
                                          btnArr.push(btn);
                                       }
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.description) {
                                       descriArray = [];
                                       for (var desObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.description.listValue.values) {
                                          var descriptionObj = {
                                             text: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.description.listValue.values[desObj].structValue.fields.description.structValue.fields.text.stringValue,
                                          }
                                          descriArray.push(descriptionObj);
                                       }
                                       var newDescriArrayObj = {
                                          orderNo: orderNoValue,
                                          name: 'descriptionButton',
                                          description: descriArray,
                                          buttonsList: btnArr,
                                       }
                                       agentChatDataArray.push(newDescriArrayObj)
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.linkOutSuggestion) {
                                       linkoutsuggestionObj = [];
                                       for (var lkObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.linkOutSuggestion.listValue.values) {
                                          var linkOutObj = {
                                             link: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.linkOutSuggestion.listValue.values[lkObj].structValue.fields.linkOutSuggestion.structValue.fields.link.stringValue,
                                             text: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.linkOutSuggestion.listValue.values[lkObj].structValue.fields.linkOutSuggestion.structValue.fields.text.stringValue,
                                          }
                                          linkoutsuggestionObj.push(linkOutObj);
                                       }
                                       var newLinkOutSuggestion = {
                                          orderNo: orderNoValue,
                                          name: nameValue,
                                          linkOutSuggestion: linkoutsuggestionObj
                                       }
                                       agentChatDataArray.push(newLinkOutSuggestion);
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.suggestionChips) {
                                       suggestionChipArray = [];
                                       for (var suggestionChipsObj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.suggestionChips.listValue.values) {
                                          var sugChipsObj = {
                                             btnChip: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.suggestionChips.listValue.values[suggestionChipsObj].structValue.fields.button.listValue.values,
                                          }
                                          suggestionChipArray.push(sugChipsObj);
                                       }
                                       var newSuggestionChipsObj = {
                                          name: nameValue,
                                          orderNo: orderNoValue,
                                          buttonChip: suggestionChipArray
                                       }
                                       agentChatDataArray.push(newSuggestionChipsObj);
                                    }

                                    if (frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.image) {
                                       imageAr = [];
                                       for (var imobj in frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.image.listValue.values) {
                                          var imgObj = {
                                             imageUrl: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.image.listValue.values[imobj].structValue.fields.image.structValue.fields.imageUrl.stringValue,
                                             alttext: frmtTextPayload[txtPayloadObj].payload.fields.metadata.structValue.fields.entuber.listValue.values[entuberObj].structValue.fields.image.listValue.values[imobj].structValue.fields.image.structValue.fields.alttext.stringValue,
                                          }
                                          imageAr.push(imgObj);
                                       }
                                       var newImageObj = {
                                          orderNo: orderNoValue,
                                          name: nameValue,
                                          image: imageAr
                                       }
                                       agentChatDataArray.push(newImageObj);
                                    }
                                 }
                              }
                           }
                        }

                        if (frmtTextPayload[txtPayloadObj].platform == "ACTIONS_ON_GOOGLE" && frmtTextPayload[txtPayloadObj].message == "simpleResponses") {
                           actionOnGoogleFlag = true;
                           if (frmtTextPayload[txtPayloadObj].simpleResponses) {
                              simpleMessagesActionArray = [];
                              for (var smMsgObj in frmtTextPayload[txtPayloadObj].simpleResponses.simpleResponses) {
                                 if (!frmtTextPayload[txtPayloadObj].simpleResponses.simpleResponses[smMsgObj].textToSpeech.toUpperCase().includes('Entuber'.toUpperCase())) {
                                    var simpleMsgObj1 = {
                                       text: frmtTextPayload[txtPayloadObj].simpleResponses.simpleResponses[smMsgObj].textToSpeech,
                                    }
                                    simpleMessagesActionArray.push(simpleMsgObj1);
                                 }
                              }
                           }
                        }
                     }

                     if (!actionOnGoogleFlag) {
                        simpleMessagesActionArray = [];
                        if (frmtTextPayload.length > 0) {
                           if (frmtTextPayload[0].text) {
                              var simpleMsgObj1 = {
                                 text: frmtTextPayload[0].text.text[0],
                              }
                              simpleMessagesActionArray.push(simpleMsgObj1);
                           }
                        }
                     }

                     let newchatDataArray = agentChatDataArray.sort(function (a, b) {
                        return parseFloat(a.orderNo) - parseFloat(b.orderNo);
                     });
                     outputThread.push({
                        timestamp: sessionThreadtartdatetime.replace(",", ""),
                        id: data[key].responseId,
                        user: data[key].queryInput,
                        agent: [],
                        richAgent: agentChatDataArray,
                        userName: "Hudson",
                        sentimentScore: sentiment,
                        simpleMessagesActionArray: simpleMessagesActionArray,
                     });
                  }
               }
            }

            try {
               //    this.setState({ historyData: [], activeUserInfo: [], currentChat: [], showPopup: false, activeSessionIdInfo: [], activeEmail: [], activePhoneNum: [], activeCustomerType: [], activeAddress: [] })
               var currentChatData = [{
                  name: chatInfo[indexNo]['name'],
                  email: chatInfo[indexNo]['email'], phone: chatInfo[indexNo]['phone'],
                  chatID: chatInfo[indexNo]['session_id'], address: chatInfo[indexNo]['address'],
                  comments: chatInfo[indexNo]['comments'], customerType: chatInfo[indexNo]['customerType']
               }];
               setHistoryData(outputThread);
               setCurrentChat(currentChatData[0])
               setActiveData({
                  activeUserInfo: chatInfo[indexNo]['name'],
                  activeSessionIdInfo: chatInfo[indexNo]['session_id'],
                  activeEmail: chatInfo[indexNo]['email'],
                  activePhoneNum: chatInfo[indexNo]['phone'],
                  activeCustomerType: chatInfo[indexNo]['customerType'],
                  activeAddress: chatInfo[indexNo]['address'],
                  activeUpdatedbyName: chatInfo[indexNo]['userDetailsUpdatedBY'],
                  activeUpdatedTs: chatInfo[indexNo]['userDetailsUpdatedTs'],
                  activeAction: chatInfo[indexNo]['lastAction'],
                  activeEscallationsArr: chatInfo[indexNo]['escallationsArr'],
                  activeSessionHistory: chatInfo[indexNo]['sessionHistoryArray'],
                  activeAccountInfo: chatInfo[indexNo]['accountInfo']
               })
               //    this.setState({ currentChat: currentChatData[0] })
               //    this.setState({
               //       historyData: outputThread,
               //       activeUserInfo: this.state.chatData[indexNo]['name'],
               //       activeSessionIdInfo: this.state.chatData[indexNo]['session_id'],
               //       activeEmail: this.state.chatData[indexNo]['email'],
               //       activePhoneNum: this.state.chatData[indexNo]['phone'],
               //       activeCustomerType: this.state.chatData[indexNo]['customerType'],
               //       activeAddress: this.state.chatData[indexNo]['address'],
               //       activeUpdatedbyName: this.state.chatData[indexNo]['userDetailsUpdatedBY'],
               //       activeUpdatedTs: this.state.chatData[indexNo]['userDetailsUpdatedTs'],
               //       activeAction: this.state.chatData[indexNo]['lastAction'],
               //       activeEscallationsArr: this.state.chatData[indexNo]['escallationsArr'],
               //       activeSessionHistory: this.state.chatData[indexNo]['sessionHistoryArray'],
               //       activeAccountInfo : this.state.chatData[indexNo]['accountInfo']
               //    })

            } catch (e) {
               console.log('error', e);
            }
         }
      } catch (error) {
         console.log('error', error);
      }

   }

   const rightTemplate = (
      <div>
         <InputText placeholder="Session Id" onChange={(e) => onChangeSessionID(e)} />
      </div>
   )

   const applyChanges = async (payload, e) => {
      op.current.hide(e);
      setLoading(true);
      await fetchChatHistory({
         nameValue: '',
         ...payload,
         sessionIdValue: '',
      })
      setLoading(false);
   }

   const loadChatHistory = async (index, chatData) => {
      setChatLoading(true);
      await viewChatHistoryData(index, chatData);
      setChatLoading(false);
   }


   
   const  onChangeSessionID = (e) => {
      const chatDataClone = [...chatData];
      if (e.target.value !== '') {
         setSessionIDList(chatDataClone.filter((_d) => _d.session_id.toLowerCase().includes(e.target.value.toLowerCase())) );
      } else {
         setSessionIDList(chatDataClone);
      }
   }

   const itemTemplate = (item, options) => {
      return (
         <div onClick={() => loadChatHistory(options.index, chatData)} style={{ cursor: 'pointer' }} className={`pl-2 pt-2 ${activeData && item.session_id === activeData.activeSessionIdInfo ? 'active_item' : ''}`}>
            <span className="flex">
               <Avatar label={item.session_id.toString().charAt(0) || 'A'} className="mr-3" size="xlarge" shape="circle" />
               <h6>{item.session_id}</h6>
            </span>
            <p className="chat-time mb-0" style={{ marginLeft: '5rem' }}><i className="ri-time-line align-middle"></i> <span className="align-middle">{item.timestamp || ''} - <b>{(item.Language == 'es-ES' ? 'ES' : 'EN')}</b></span></p>
            <Divider />
         </div>
      );
   };

   const onChangePicker = ({ selection }) => {
      setPayload({
         startDate: moment(selection.startDate).startOf('day'),
         stopDate: moment(selection.endDate).endOf('day'),
      })
      setCurrentDateRange(`${moment(selection.startDate).startOf('day').format('MMM DD, YYYY')} - ${moment(selection.endDate).endOf('day').format('MMM DD, YYYY')}`)
   }

   const onsubmitData = async (data) => {
      setModalVisible(false);
      setLoading(true);
      await fetchChatDataUpdate(data);
      await fetchChatHistory({
         nameValue: '',
         ...payload,
         sessionIdValue: '',
      })
      setLoading(false);
   }

   const updateArchiveEvent = async ()=> {
      setLoading(true);
      const response = await updateArchive(currentChat);
      if (response.status == 200) {
         toast.current.show({severity: 'success', summary: 'Success Message', detail: 'Archived Successfully'});
      }
      await fetchChatHistory({
         nameValue: '',
         sessionIdValue: '',
         ...payload,
      })
      setLoading(false);
   }

   const leftToolbarTemplate = () => {
      return (
         <React.Fragment>
            <div className="p-col-12 p-md-4">
               <div className="p-inputgroup">
                  <InputText value={currentDateRange} readOnly />
                  <Button icon="pi pi-calendar" type="button" onClick={(e) => op.current.toggle(e)} />
               </div>
            </div>

            <div className="m-2">
               <Button label="" icon="pi pi-refresh" className="p-button-info" onClick={onRefresh} tooltip="Refresh" tooltipOptions={{ position: 'bottom' }} />
            </div>

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" >
               <DateRangePicker
                  onChange={onChangePicker}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={[{
                     startDate: payload.startDate.toDate(),
                     endDate: payload.stopDate.toDate(),
                     key: 'selection'
                  }]}
                  direction="horizontal"
               />
               <Button type="button" label="Apply" onClick={(e) => applyChanges(payload,e)} />
            </OverlayPanel>
         </React.Fragment>
      )
   }


   return (
      <>
         <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightTemplate}/>
         <Toast ref={toast} />
         <div className="flex">
            {
               loading &&
               <div className="flex align-items-center" style={{ width: '100%', height: '100%' }}>
                  <ProgressSpinner />
               </div>
            }
            {!loading && chatData.length > 0 && (
               <>
                  <div style={{ width: '20%' }}>
                     <VirtualScroller items={sessionIDList} itemSize={50} itemTemplate={itemTemplate}
                        className="border-1 surface-border border-round" style={{ height: '100%' }} />
                  </div>
                  <div style={{ width: '60%' }}>
                     {
                        chatLoading &&
                        <div className="flex align-items-center" style={{ width: '100%', height: '100%' }}>
                           <ProgressSpinner />
                        </div>
                     }
                     {
                        !chatLoading &&
                        <ChatTemplate historyData={historyData} activeUser={activeData} chatData={chatData} currentChatData={currentChat} />
                     }
                  </div>
                  <div style={{ width: '20%', maxHeight: '100vh', overflow: 'auto' }}>
                  {activeData && (
                        <>
                           <div className="simplebar-content" >
                              <div className="flex flex-row justify-content-evenly align-items-center">
                                 <div>
                                    <Avatar label={(activeData && activeData.activeUserInfo.toString() == '' ? activeData.activeSessionIdInfo.toString().charAt(0) : activeData.activeUserInfo.toString().charAt(0)) || 'A'} style={{ backgroundColor: '#FF9800', }} size="large" shape="circle" />           
                                   
                                 </div>
                                 <h5>{activeData && (activeData.activeUserInfo || activeData.activeSessionIdInfo)} <span style={{ color: '#2196F3', cursor: 'pointer' }} ></span></h5>
                              </div>
                              <div className="flex flex-row justify-content-evenly my-4">
                                 <Button label="Edit" icon="pi pi-pencil" className="p-button-rounded" onClick={() => setModalVisible(true)}></Button>
                                 <Button label="Archive" icon="pi pi-database" className="p-button-rounded" onClick={() => updateArchiveEvent()}></Button>
                              </div>

                           </div>
                           {/* <div className="flex flex-row">
                              <Button onClick={console.log} >Edit</Button>
                           </div> */}
                           <div className="p-3 p-lg-3 border-bottom">
                              <div>
                                 <div style={{ display: activeData && activeData.activeUserInfo.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot "><i className="pi pi-user mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i>Name: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeUserInfo} </span> </h5>
                                 </div>
                                 <div style={{ display: activeData && activeData.activeUserInfo.toString() == '' ? 'block' : 'none' }}>
                                    <h5 className="font-size-12 margintopBot " style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => setModalVisible(true)}> <i className="pi pi-user mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Add Name<span >{activeData && activeData.activeUserInfo.toString() == ''} </span>

                                    </h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activeEmail.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot "   ><i className="pi pi-at mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Email Address : <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeEmail}</span></h5>
                                 </div>
                                 <div style={{ display: activeData && activeData.activeEmail.toString() == '' ? 'block' : 'none' }}>
                                    <h5 className="font-size-12 margintopBot " style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => setModalVisible(true)}><i className="pi pi-at mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i>  Add Email Address <span >{activeData && activeData.activeEmail.toString() == ''}</span></h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activePhoneNum.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot " ><i className="pi pi-phone mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Phone Number: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activePhoneNum}</span> </h5>
                                 </div>
                                 <div style={{ display: activeData && activeData.activePhoneNum.toString() == '' ? 'block' : 'none' }} >
                                    <h5 className="font-size-12 margintopBot " style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => setModalVisible(true)}><i className="pi pi-phone mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em' }}></i> Add Phone Number<span>{activeData && activeData.activePhoneNum.toString() == ''}</span> </h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activeAddress.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot " > <i className="pi pi-map-marker mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Address : <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeAddress}</span></h5>
                                 </div>
                                 <div style={{ display: activeData && activeData.activeAddress.toString() == '' ? 'block' : 'none' }}>
                                    <h5 className="font-size-12 margintopBot " style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => setModalVisible(true)}> <i className="pi pi-map-marker mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i>  Add Address <span >{activeData && activeData.activeAddress.toString() == ''}</span></h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activeCustomerType.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot " > <i className="pi pi-users mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Customer Type: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeCustomerType}</span> </h5>
                                 </div>
                                 <div style={{ display: activeData && activeData.activeCustomerType.toString() == '' ? 'block' : 'none' }}>
                                    <h5 className="font-size-12 margintopBot " style={{ color: '#2196F3', cursor: 'pointer' }} onClick={() => setModalVisible(true)}><i className="pi pi-users mx-2" style={{ color: 'rgb(0, 25, 112)', fontSize: '1em'  }}></i> Add Customer Type<span>{activeData && activeData.activeCustomerType.toString() == ''}</span> </h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activeUpdatedByUserName && activeData.activeUpdatedByUserName.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot " >Assigned To: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeUpdatedByUserName}</span> </h5>
                                 </div>
                              </div>
                              <div>
                                 <div style={{ display: activeData && activeData.activeUpdatedTeam && activeData.activeUpdatedTeam.toString() == '' ? 'none' : 'block' }}>
                                    <h5 className="font-size-12 margintopBot " >Team: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '14px' }}>{activeData && activeData.activeUpdatedTeam}</span> </h5>
                                 </div>
                              </div>
                           </div>
                        </>)}
                     <Accordion activeIndex={0}>
                        <AccordionTab header="Session History">
                           <div className="list-unstyled chat-list chat-user-list" style={{ maxHeight: '200px', overflow: 'auto' }}>
                              {activeData && activeData.activeSessionHistory.length < 1 ? 'No conversation available' : ''}
                              {activeData && activeData.activeSessionHistory.map((data, index) =>
                                 <div key={index}>
                                    <h5 key={index} style={{ fontSize: '10px' }} >Changed by: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '10px' }}>{data.objUpdatedByName}</span></h5>
                                    <h5 key={index} style={{ fontSize: '10px' }} >Changed at: <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '10px' }}>{data.objUpdatedTs}</span></h5>
                                    <h5 key={index} style={{ fontSize: '10px' }} >Action : <span style={{ color: 'rgb(4 67 115 / 1)', fontSize: '10px' }}>{data.objAction}</span></h5>
                                    <hr></hr>
                                 </div>
                              )}
                           </div>
                        </AccordionTab>
                        <AccordionTab header="Business Partner">
                           <p className="m-0">
                              <div>
                                 <div className="list-unstyled chat-list chat-user-list">
                                    <div>
                                       <p>Business Partner No:</p>
                                    </div>
                                    <div>
                                       <p >Installation Facts:</p>
                                    </div>
                                    <div>
                                       <p >Functional Location:</p>
                                    </div>
                                    <div>
                                       <p >Device:</p>
                                    </div>
                                    <div>
                                       <p >Last meter read:</p>
                                    </div>
                                    <div>
                                       <p >Geo Coordinates:</p>
                                    </div>
                                 </div>
                              </div>
                           </p>
                        </AccordionTab>
                        <AccordionTab header="Conversation History">
                           <p className="m-0">
                              No Data Found.
                           </p>
                        </AccordionTab>
                     </Accordion>
                  </div>
               </>
            )}
            {
               !loading && chatData.length == 0 && <EmptyRecords content={'No conversation available.'} />
            }
           {modalVisible && <EditUserDialogChats visible={modalVisible} propsData={currentChat} onSubmit={(data) => onsubmitData(data)} onCancel={() => setModalVisible(false)}  />}
         </div>
      </>
   )
}

export default HudsonChats;