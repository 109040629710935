import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Badge } from 'primereact/badge';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

export const AppTopbar = (props) => {

    return (
        <div className="layout-topbar">
            {/*  <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
                <span>SAKAI</span>
            </Link> */}

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-home" />
                        <span>Home</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-bell"><Badge value="2" ></Badge></i>
                        <span>Bell</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <a target='_Blank' href={"/admin/cenHud"}>
                            <span style={{ position: 'absolute', paddingTop: '12.1px', paddingLeft: '4.3px', color: 'black' }}>en</span>
                            <i className='pi pi-comment' style={{ color: '#555555' }} />
                        </a>

                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <a target='_Blank' href={"/admin/cenHud?io=2"}>
                            <span style={{ position: 'absolute', paddingTop: '12.1px', paddingLeft: '5.4px', color: 'black' }}>es</span>
                            <i className='pi pi-comment' style={{ color: '#555555' }} />
                        </a>

                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onLogout}>
                        <i className="pi pi-sign-out" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
