import { getDatabase, ref, get } from "firebase/database";
import axios from "axios";
import moment from 'moment';
import { getAuth } from "firebase/auth";



async function getadminKey() {
    const db = getDatabase();
    const key = await get(ref(db,'AdminKey'));
    return key.val();
}


export const fetchCompletedChatHistory = async (payload, queryVal) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let URL = ``;

    if (payload.startDate == undefined) {
        payload.startDate = '';
    }
    if (payload.stopDate == undefined) {
        payload.stopDate = '';
    }

    if (queryVal) {
        payload.sessionIdValue = queryVal;
    }
    var startDate = payload.startDate;
    var endDate = payload.stopDate;
    var nameValue = payload.nameValue;
    var sessionId = payload.sessionIdValue;
    if (nameValue != '' && sessionId != '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}&session_id=${sessionId}`;
    } else if (nameValue != '' && sessionId == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}`;
    } else if (sessionId != '' && nameValue == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${sessionId}`;
    }
    else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/completed?key=${tokenKey}&startDate=${startDate}&stopDate=${endDate}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/completed?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;
    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
}



export const fetchSessionHistory = async (payload, queryVal) => {
    var URL = '';
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    if (queryVal != '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${queryVal}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;

    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const fetchArchiveChatHistory = async (payload, queryVal) => {

    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let URL = ``;

    if (payload.startDate == undefined) {
        payload.startDate = '';
    }
    if (payload.stopDate == undefined) {
        payload.stopDate = '';
    }

    if (queryVal) {
        payload.sessionIdValue = queryVal;
    }
    var startDate = payload.startDate;
    var endDate = payload.stopDate;
    var nameValue = payload.nameValue;
    var sessionId = payload.sessionIdValue;
    if (nameValue != '' && sessionId != '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}&session_id=${sessionId}`;
    } else if (nameValue != '' && sessionId == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}`;
    } else if (sessionId != '' && nameValue == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${sessionId}`;
    }
    else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/archived?key=${tokenKey}&startDate=${startDate}&stopDate=${endDate}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/archived?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;
    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};

export const fetchIndividualChatDetails = async (payload, queryVal) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let URL = ''
    if (queryVal != '') {
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${queryVal}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;

    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

};

export const fetchCenhudChatHistory = async (payload, queryVal) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let URL = ``;

    if (payload.startDate == undefined) {
        payload.startDate = '';
    }
    if (payload.stopDate == undefined) {
        payload.stopDate = '';
    }

    if (queryVal) {
        payload.sessionIdValue = queryVal;
    }

    var startDate = payload.startDate;
    var endDate = payload.stopDate;
    var nameValue = payload.nameValue;
    var sessionId = payload.sessionIdValue;

    if (nameValue != '' && sessionId != '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}&session_id=${sessionId}`;
    } else if (nameValue != '' && sessionId == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}`;
    } else if (sessionId != '' && nameValue == '') {

        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${sessionId}`;
    }
    else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations?key=${tokenKey}&startDate=${startDate}&stopDate=${endDate}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;
    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const fetchChatThreadBySessionId = async (payload) => {
    var sessionId = payload;
    const token = await getAuth().currentUser.getIdToken();
    const tokenKey = await getadminKey();
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/${sessionId}?key=${tokenKey}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const updateArchive = async (currentChat) => {

    let UID = localStorage.getItem('isAdmin');
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var updatedByName = '';
    var teamName = ''
    for (var obj in keyVal) {
       if (keyVal.Name) {
        updatedByName = keyVal.Name;
       }
       if (keyVal.Team) {
          teamName = keyVal.Team;
       }
    }
    return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/archive/${currentChat.chatID}?key=${tokenKey}`,
    {
        updatedTs: moment().unix(),
        updatedBy: UID,
        updatedByName: updatedByName
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })
}



/**
 * This function is used to Update Email and phone number.
 * @param uid 
 */
export const fetchAgentEscalationChat = async (payload) => {

    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    
    let URL = '';
    var startDate = payload.startDate;
    var endDate = payload.stopDate;
    var nameValue = payload.nameValue;
    var sessionId = payload.sessionIdValue;

    if (nameValue != '' && sessionId != '') {
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}&session_id=${sessionId}`;
    } else if (nameValue != '' && sessionId == '') {
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&Name=${nameValue}`;
    } else if (sessionId != '' && nameValue == '') {
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/filter/conversations?key=${tokenKey}&session_id=${sessionId}`;
    }
    else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/escalations?key=${tokenKey}&startDate=${startDate}&stopDate=${endDate}`;
    }
    else {
        URL = `${process.env.REACT_APP_API_BASE_URL}/v1/sort/conversations/escalations?key=${tokenKey}&startDate=${startDate}&stopdate=${endDate}`;
    }

    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const fetchChatDataUpdate = async (payload) => {

    let UID = localStorage.getItem('isAdmin');
    let email = localStorage.getItem('IsUE');
    if (payload.customerType == undefined || payload.customerType == '') {
        payload.customerType = 'Existing Customer';
    }
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var updatedByName = '';
    var teamName = ''
    for (var obj in keyVal) {
       if (keyVal.Name) {
        updatedByName = keyVal.Name;
       }
       if (keyVal.Team) {
          teamName = keyVal.Team;
       }
    }

    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/edit/${payload.userId}?key=${tokenKey}`,{
        Name: payload.userName,
        email: payload.email,
        phone: payload.phone,
        address: payload.address,
        question: payload.comments,
        updatedTs: moment().unix(),
        customerType: payload.customerType,
        updatedBy: UID,
        updatedByName: updatedByName
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })

}

export async function updateReadValue(sessionId) {
    let UID = localStorage.getItem('isAdmin');
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var UpdatedUserName = '';
    var teamName = ''
    for (var obj in keyVal) {
       if (keyVal.Name) {
        UpdatedUserName = keyVal.Name;
       }
       if (keyVal.Team) {
          teamName = keyVal.Team;
       }
    }

    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/assignTo/${sessionId}?key=${tokenKey}` , {
        updatedTs: moment().unix(),
        updatedBy: UID,
        updatedByName: UpdatedUserName,
        Assign: true
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })

}

export async function updateReadFalseValue(sessionId, UpdatedUserName) {
    let UID = localStorage.getItem('isAdmin');
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var UpdatedUserName = '';
    var teamName = ''
    for (var obj in keyVal) {
       if (keyVal.Name) {
        UpdatedUserName = keyVal.Name;
       }
       if (keyVal.Team) {
          teamName = keyVal.Team;
       }
    }


    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/assignTo/${sessionId}?key=${tokenKey}`,{
        updatedTs: moment().unix(),
        updatedBy: UID,
        updatedByName: UpdatedUserName,
        Assign: false
    } , {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })
}

export const updateComplete = async (currentChatID) => {
    let UID = localStorage.getItem('isAdmin');
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var updatedByName = '';
    var teamName = ''
    for (var obj in keyVal) {
       if (keyVal.Name) {
        updatedByName = keyVal.Name;
       }
       if (keyVal.Team) {
          teamName = keyVal.Team;
       }
    }

    await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/conversations/complete/${currentChatID}?key=${tokenKey}`,{
        updatedTs: moment().unix(),
        updatedBy: UID,
        updatedByName: updatedByName
    }, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })

}