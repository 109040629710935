import React, { useEffect } from 'react';
import classNames from 'classnames';
import { updateTeamAPI } from "../../../../service/SettingsService";
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const channelOptions = [
    { label: 'Web/Chat', value: 'Web/Chat' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Phone', value: 'Phone' }
]


const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "InActive" },
];

export const EditTeamDialog = (props) => {
    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            description: "",
            channel: "",
            status: "Active"
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "This field is required.";
            }

            if (!data.description) {
                errors.description = "This field is required.";
            }

            return errors;
        },
        onSubmit: async (payload) => {
            try {
                await updateTeamAPI(payload)
                formik.resetForm();
                props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'Team Updated', life: 3000});
            } catch (e) {
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000});
            }
        },
    });

    useEffect(() => {
            formik.setValues({
                ...props.teamToBeEdited,
                name: props.teamToBeEdited.teamName
            });
    }, [])

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };


    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Edit Team" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="field">
                <label htmlFor="name">Name</label>
                <InputText id="name" value={formik.values.name} onChange={formik.handleChange} required autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                {getFormErrorMessage('name')}
            </div>
            <div className="field">
                <label htmlFor="description">Description</label>
                <InputTextarea id="description" value={formik.values.description} onChange={formik.handleChange} required rows={3} cols={20} className={classNames({ 'p-invalid': isFormFieldValid('description') })} />
                {getFormErrorMessage('description')}
            </div>
            <div className="field">
                <label htmlFor="channel">Channel</label>
                <Dropdown id="channel" name="channel" value={formik.values.channel} onChange={formik.handleChange} options={channelOptions} />
                {getFormErrorMessage('channel')}
            </div>
            <div className="field">
                <label htmlFor="status">Status</label>
                <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={statusOptions} />
                {getFormErrorMessage('status')}
            </div>
        </Dialog>
    )
}