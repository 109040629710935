/**
 * Renames the alert messages for better user experience.
 * @param message 
 * @returns 
 */
 export const renameAlertMessage = (message) => {
    switch (message) {
        case 'auth/invalid-email': return 'Invalid Email';
        case 'auth/wrong-password': return 'Wrong Password';
        case "auth/invalid-credential": return "Invalid Credentials.";
        case "auth/user-not-found": return "There is no user corresponding to this identifier. The user may have been deleted.";
        case "auth/popup-closed-by-user": return "Popup has been closed by the user.";
        case "auth/email-already-in-use": return "Email already in use. Please use a different email";
        default: return message;
    }
}  
/**
 * Renames the alert messages for better user experience.
 * @param message 
 * @returns 
 */
 export const renameAlertSummary = (summary) => {
    switch (summary) {
        case 'error': return 'Error: ';
        case 'warning': return 'Warning: ';
        case "success": return "Success: ";
        case "info": return "Info: ";
        default: return summary;
    }
}  