import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ConfirmedTab from './ConfirmedTab';
import SubmittedTab from './SubmittedTab';

const tabHeaders = ['Submitted','Confirmed'];

const MeterSubmission = () => {
    const [activeIndex, setActiveIndex] = useState(0);


    return (
        <div className="grid">
            <div className="col-12">
            <div className="card">
                    <h5>Meters</h5>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={tabHeaders[0]}>
                            {activeIndex === 0 && <SubmittedTab/>}
                        </TabPanel>
                        <TabPanel header={tabHeaders[1]}>
                            {activeIndex === 1 && <ConfirmedTab/>}
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MeterSubmission, comparisonFn);
