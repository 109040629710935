import React, { useState, useEffect } from 'react';
import { fetchMeterReading } from '../../../service/MeterReadingService';
import MeterContents from './MeterContents';
import moment from 'moment';

const SubmittedTab = (props) => {

    const [currentTableData, setCurrentTableData] = useState([]);
    const [isLoadingConfirmedData, setIsLoadingConfirmedData] = useState(false)

    useEffect(() => {
        (
            async function getMeterSubmissionData() {
                await submittedMeterReadingsFetch({
                    nameValue: '',
                    startDate: moment().startOf('day'),
                    stopDate: moment().endOf('day'),
                    sessionIdValue: '',
                })
            }
        )()

    }, []);

    const submittedMeterReadingsFetch = async (payload) => {
        try {
            setIsLoadingConfirmedData(true);
            const responseData = await fetchMeterReading(payload)
            const data = responseData.data
            if (data && data != '') {
                setCurrentTableData(data || []);
            } else {
                console.log('show no data available');
            }
        } catch (e) {
            console.log('error', e);
        } finally {
            setIsLoadingConfirmedData(false);
        }
    }

    const onRefresh = async () => {
        await submittedMeterReadingsFetch({
            nameValue: '', startDate: moment().startOf('day'),
            stopDate: moment().endOf('day'), sessionIdValue: '',
        })
    }


    const applyChanges = async (payload) => {
        await submittedMeterReadingsFetch({ nameValue: '', sessionIdValue: '', ...payload })
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <MeterContents data={currentTableData} isLoading={isLoadingConfirmedData} onRefresh={onRefresh} applyChanges={applyChanges} status="SUBMITTED" />
                </div>
            </div>
        </div>
    )
}
export default SubmittedTab;