import { getDatabase, ref, get,set,update } from "firebase/database";
import axios from "axios";
import moment from 'moment';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


async function getadminKey() {
    const db = getDatabase();
    const key = await get(ref(db,'AdminKey'));
    return key.val();

}

export const fetchMeterReading = async (payload) => {
    let URL = ''
    if (payload.startDate == undefined) {
        payload.startDate = '';
    }
    if (payload.stopDate == undefined) {
        payload.stopDate = '';
    }

    let startDate = payload.startDate;
    let endDate = payload.stopDate;
    let sessionId = payload.sessionIdValue;

    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    
    if (sessionId != '') {
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getNewMeterUploadImage?key=${tokenKey}&sessionID=${sessionId}`;
    } else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getNewMeterUploadImage?key=${tokenKey}&startDate=${startDate}&stopDate=${endDate}`;
    } else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getNewMeterUploadImage?key=${tokenKey}&startDate=${getStartDate}&stopDate=${getEndDate}`;
    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};

export const fetchConfirmedMeterReading = async (payload) => {
    var URL = ''
    if (payload.startDate == undefined) {
        payload.startDate = '';
    }
    if (payload.stopDate == undefined) {
        payload.stopDate = '';
    }

    var startDate = payload.startDate;
    var endDate = payload.stopDate;
    var nameValue = payload.nameValue;
    var sessionId = payload.sessionIdValue;

    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    if (sessionId != '') {
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getApprovedMeterUploadImage?key=` + await getadminKey() + `&sessionID=${sessionId}`;
    } else if (startDate != '' && endDate != '') {
        var formatDate = "MM/DD/yyyy"
        startDate = moment(startDate).format(formatDate);
        endDate = moment(endDate).add(1, 'days').format(formatDate);
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getApprovedMeterUploadImage?key=` + await getadminKey() + `&startDate=${startDate}&stopDate=${endDate}`;
    }
    else {
        var end = new Date();
        var formatDate = "MM/DD/yyyy"
        var getStartDate = moment(end).format(formatDate);
        var getEndDate = moment(end).add(1, 'days').format(formatDate);
        URL =  `${process.env.REACT_APP_API_BASE_URL}/v1/getApprovedMeterUploadImage?key=` + await getadminKey() + `&startDate=${getStartDate}&stopDate=${getEndDate}`;
    }
    return await axios.get(URL, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
};

export const fetchMeterReadingDetails = async (payload) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    var sessionId = payload;
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/getMeterUploadDetails/${sessionId}?key=${tokenKey}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}


export const fetchMeterReadingDetailsConfirmed = async (payload) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    var sessionId = payload;
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/getMeterUploadDetailsConfirmed/${sessionId}?key=${tokenKey}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
}

export const updateConfirmedMeterReading = async (sessionId, statusValue) => {
    let UID = localStorage.getItem('isAdmin');
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();

    const db = getDatabase();
    const key = await get(ref(db,`User Fanout/${UID}`));
    const keyVal = key.val();

    var updatedByName = '';
    for (var obj in keyVal) {
       if (keyVal.Name) {
            updatedByName = keyVal.Name;
       }
    }

    var payload={
        "approvedstatus" : statusValue,
        "approvedBy" :updatedByName
    }
    return await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/v1/updateMeterUploadConfirmed/${sessionId}?key=${tokenKey}`, payload,{
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
}