import React, { useEffect, useState } from 'react';
import {  fetchSettingsData, updateSettingsData } from "../../../../service/SettingsService";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { normalizeOnlyNums } from '../../../../helpers/validations';

export const UserSettingsDialog = (props) => {
    const formik = useFormik({
        initialValues: {
            resolveTimer: "",
            enableOverride: false,
            enableState: false,
        },
        onSubmit: async (data) => {
            try {
                await updateSettings(data);
                formik.resetForm();
                props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'User Added', life: 3000 });
            } catch (e) {
                console.error(e);
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }

        },
    });

    const updateSettings = async (payload) => {
        await updateSettingsData(payload);
    }

    useEffect(() => {
        (
            async function getSettings() {
                try {
                    await fetchSettingsData();
                } catch (e) {
                    console.log(e);
                }
            }
        )()
    }, [])



    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Edit Settings" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="field">
                <label htmlFor="resolveTimer">Auto resolve unassigned Live Agent Request(minutes)</label>
                <InputText id="resolveTimer"
                    value={formik.values.resolveTimer}
                    onChange={(e) => {
                        e.target.value = normalizeOnlyNums(e.target.value);
                        formik.handleChange(e);
                    }}
                    autoFocus
                    className={classNames({ 'p-invalid': isFormFieldValid('resolveTimer') })}
                />
                {getFormErrorMessage('resolveTimer')}
            </div>
            <div className="flex field align-items-center">
                <label htmlFor="enableOverride">Override the current live agent availability check</label>
                <InputSwitch id="enableOverride"
                    value={formik.values.enableOverride}
                    onChange={formik.handleChange}
                    className={classNames({ 'p-invalid': isFormFieldValid('enableOverride'), 'ml-2': true })}
                />
                {getFormErrorMessage('enableOverride')}
            </div>
            <div className="flex field align-items-center">
                <label htmlFor="enableState">Live Agent Available</label>
                <InputSwitch name="enableState"
                    id="enableState"
                    value={formik.values.enableState}
                    onChange={formik.handleChange}
                    className={classNames({ 'p-invalid': isFormFieldValid('enableState'), 'ml-2': true })}
                />
                {getFormErrorMessage('enableState')}
            </div>
        </Dialog>
    )
}