import axios from "axios";
import { getAuth, signInWithEmailAndPassword, signOut, updatePassword, signInWithPopup, SAMLAuthProvider, sendPasswordResetEmail } from "firebase/auth";

/**
 * This function is used to login a user.
 * @returns
 */
export const loginUser = async (payload) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      payload?.email,
      payload?.password
    );
    return {
      status: 200,
      data: userCredential,
      statusText: "User successfully logged in",
    };
  } catch (error) {
    return {
      status: 500,
      data: null,
      statusText: error.code || "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to logout a user.
 * @returns
 */
export const logoutUser = async () => {
  try {
    const auth = getAuth();
    await signOut(auth);
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    // localStorage.removeItem('refresh_token');
    return { status: 200, data: null, statusText: 'Signout successful' };
  } catch (error) {
    return {
      status: 500,
      data: null,
      statusText: error.code || 'Oops! something went wrong'
    }
  }
}

/**
 * Login with central hudson
 */
export const loginWithSAML = async () => {
  try {
    const auth = getAuth();
    const provider = new SAMLAuthProvider(process.env.REACT_APP_FIREBASE_SAML_PROVIDER);
    const popupResponse = await signInWithPopup(auth, provider)
    return {
      status: 200,
      data: popupResponse,
      statusText: "User successfully logged in",
    };
  } catch (error) {
    return {
      status: 500,
      data: null,
      statusText: error.code || "Oops! something went wrong",
    };
  }
}

/**
 * This function is used to update a user password.
 * @returns 
 */
export const changePassword = async (payload) => {
  try {
    const auth = getAuth();
    const userCredential = await updatePassword((auth).currentUser, payload?.newPassword)
    return { status: 200, data: userCredential, statusText: 'Password updated successfully' };
  } catch (error) {
    return { status: 500, data: null, statusText: error.code || 'Oops! something went wrong' }
  }
};

/**
* This function is used to logout a user.
* @returns 
*/
export const refreshAccessToken = async () => {
  try {
    const endPoint = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
    const firebaseResponse = await axios.post(endPoint, { grant_type: 'refresh_token', refresh_token: localStorage.getItem('refresh_token') || '' });
    localStorage.setItem('id_token', firebaseResponse.data.id_token);
    localStorage.setItem('refresh_token', firebaseResponse.data.refresh_token);
    return firebaseResponse.data.id_token;
  } catch (error) {
    console.error(error);
  }
}

/**
 * This function is used to send a reset password email.
 * @returns 
 */
export const sendResetPasswordMail = async (payload) => {
  try {
    const auth = getAuth();
    const userCredential = await sendPasswordResetEmail(auth, payload?.emailAddress)
    return { status: 200, data: userCredential, statusText: 'Email Sent Successfully' };
  } catch (error) {
    return { status: 500, data: null, statusText: error.code || 'Oops! something went wrong' }
  }
};