
export const normalizeOnlyNums = (value) => {
    if (!value) {
        return value;
    }
    const onlyNums = (value + "").replace(/[^\d]/g, "");

    return onlyNums;
};


export const validateLength = (value, length) =>
    value.length >= length
        ? undefined
        : `This field must be ${length} characters long.`;

export const emailValidator = (email) => {
    const re = /\S+@\S+\.\S+/;

    if (!re.test(email)) return "Please enter a valid email address";

    return undefined;
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');