import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "../redux";

const persistConfig = {
  key: "root",
  version: 0,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore() {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

  let persistor = persistStore(store, null, () => {
    store.getState();
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("../redux", () => store.replaceReducer(persistedReducer));
  }

  return { store, persistor };
}
