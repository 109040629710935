import React, { useEffect, useState, useRef } from 'react';
import {  fetchConfigData,  updateConfigData } from "../../../../service/SettingsService";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { normalizeOnlyNums } from '../../../../helpers/validations';
import { Toast } from 'primereact/toast';
import {  Button } from 'primereact/button';

const Configuration = () => {
    const toast = useRef(null);
    
    const formik = useFormik({
        initialValues: {
            IsCutOverBot:false,
            Override:false,
            ResolveTime: 0,
            State:false,
            TimeAdjust:0
        },
        onSubmit: async (data) => {
            try {
                const resp = await updateConfigData(data);
                if (resp.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Configuration Modified', life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
                }
            } catch (e) {
                console.error(e);
                toast.current.show({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }

        },
    });

    useEffect(() => {
        (
            async function getSettings() {
                try {
                    const response = await fetchConfigData();
                    if (response.status === 200) {
                        formik.setValues({
                            ...response.data
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        )()
    }, [])



    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    return (
        <div className="card">
            <Toast ref={toast} />  
            <div className="field">
                <label htmlFor="ResolveTime">Auto resolve unassigned Live Agent Request(minutes)</label>
                <InputText id="ResolveTime"
                    value={formik.values.ResolveTime}
                    disabled
                    onChange={(e) => {
                        e.target.value = normalizeOnlyNums(e.target.value);
                        formik.handleChange(e);
                    }}
                    autoFocus
                    className={classNames({ 'p-invalid': isFormFieldValid('ResolveTime') })}
                />
                {getFormErrorMessage('ResolveTime')}
            </div>
            <div className="flex field align-items-center">
                <label htmlFor="Override">Override the current live agent availability check</label>
                <InputSwitch id="Override"
                    checked={Boolean(formik.values.Override)}
                    onChange={formik.handleChange}
                    className={classNames({ 'p-invalid': isFormFieldValid('Override'), 'ml-2': true })}
                />
                {getFormErrorMessage('Override')}
            </div>
            <div className="flex field align-items-center">
                <label htmlFor="State">Live Agent Available</label>
                <InputSwitch name="State"
                    id="State"
                    disabled
                    checked={Boolean(formik.values.State)}
                    onChange={formik.handleChange}
                    className={classNames({ 'p-invalid': isFormFieldValid('State'), 'ml-2': true })}
                />
                {getFormErrorMessage('State')}
            </div>
            <Button onClick={formik.handleSubmit} className="p-button-primary">Submit</Button>
        </div>
    )
}

export default Configuration;