import React, { useRef, useEffect } from 'react'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

const AnalyticsDialog = (props) => {
    const dt = useRef();


    const sessionIDBody = (rowData) => (
        <Link to={`/chats/hudson-chats?sessionid=${rowData.sessionId}`} target='_blank'>{rowData.sessionId}</Link>
    )

    return (
        <Dialog visible={props.visible} style={{ width: '550px' }} header={props.header} modal className="p-fluid" onHide={props.hideDialog}>
            <DataTable ref={dt} value={props.data}
                dataKey="id" className="datatable-responsive" emptyMessage="No data available." responsiveLayout="scroll">
                <Column field="slNo" header="SL No"  ></Column>
                {props.showIntent && <Column field="intentName" header="Intents" ></Column>}
                <Column header="Session ID" body={sessionIDBody}></Column>
            </DataTable>
        </Dialog>
    )
}

export default AnalyticsDialog