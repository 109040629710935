import * as alertType from "./types";

const types = {
  ...alertType,
};

const initialState = {
  show: false,
  message: '',
  type: ''
};

export default function alertReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.SHOW_ALERT:
      return {
        show: true,
        message: action.payload.message,
        type: action.payload.type
      };
    case types.RESET_ALERT:
      return initialState;
    default:
      return state;
  }
}
