import React, { useEffect, useState } from 'react';
import {  fetchTeamlist, updateUser } from "../../../../service/SettingsService";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';

const roles = [
    { label: 'Admin', value: 'Admin' },
    { label: 'Agent', value: 'Agent' },
    { label: 'Analyst', value: 'Analyst' },
    { label: 'Supervisor', value: 'Supervisor' }
]


const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "InActive" },
];

export const EditUserDialog = (props) => {
    const [teams, setTeams] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const formik = useFormik({
        initialValues: {
            id:"",
            name: "",
            emailAddress: "",
            password: "",
            confirmPassword: "",
            role: "",
            team: "",
            status: "Active",
            enableChatBot: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = "This field is required.";
            }

            if (!data.emailAddress) {
                errors.emailAddress = "This field is required.";
            }

            if (!data.emailAddress.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                errors.emailAddress = "Invalid Email."
            }


            if (!data.team) {
                errors.team = "This field is required.";
            }

            if (!data.role) {
                errors.role = "This field is required.";
            }


            return errors;
        },
        onSubmit: async (data) => {
            try {
                await editUser(data);
                formik.resetForm();
                props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'User Details Updated', life: 3000 });
            } catch (e) {
                console.error(e);
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }

        },
    });

    useEffect(() => {
        formik.setValues({
            ...props.userToBeEdited,
            emailAddress: props.userToBeEdited.email
        });
        (
            async function getTeams() {
                await fetchTeams();
            }
        )()
    }, [])

    const editUser = async (payload) => {
        console.log(payload)
        await updateUser(payload);
    }

    const fetchTeams = async () => {
        setIsLoadingData(true);
        const list = await fetchTeamlist();
        const teamsFetched = Object.keys(list || [])
            .map((key) => ({
                label: list[key].Team_Name,
                value: list[key].Team_Name,
            }))
        setTeams(teamsFetched || []);
        setIsLoadingData(false);
    }

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Edit User" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
            <div className="field">
                <label htmlFor="name">Name</label>
                <InputText id="name" value={formik.values.name} onChange={formik.handleChange} required autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                {getFormErrorMessage('name')}
            </div>
            <div className="field">
                <label htmlFor="emailAddress">Email</label>
                <InputText id="emailAddress" value={formik.values.emailAddress} onChange={formik.handleChange} required className={classNames({ 'p-invalid': isFormFieldValid('emailAddress') })} />
                {getFormErrorMessage('emailAddress')}
            </div>
            <div className="field">
                <label htmlFor="role">Role</label>
                <Dropdown placeholder="Select role" id="role" name="role" value={formik.values.role} onChange={formik.handleChange} options={roles} className={classNames({ 'p-invalid': isFormFieldValid('role') })}  />
                {getFormErrorMessage('role')}
            </div>
            <div className="field">
                <label htmlFor="team">Team</label>
                <Dropdown id="team" name="team" value={formik.values.team} onChange={formik.handleChange} options={teams} className={classNames({ 'p-invalid': isFormFieldValid('team') })}  />
                {getFormErrorMessage('team')}
            </div>
            <div className="field">
                <label htmlFor="status">Status</label>
                <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={statusOptions} />
                {getFormErrorMessage('status')}
            </div>
            <div className="field">
                <label htmlFor="enableChatbot">Enable ChatBot</label>
                <InputSwitch checked={formik.values.enableChatbot} onChange={(e) => formik.setFieldValue('enableChatbot', e.value)} />
                {getFormErrorMessage('team')}
            </div>
        </Dialog>
    )
}