import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Divider } from 'primereact/divider';
// import Itad_Destruction from "./Itad_destruciton.png";
// import Logo from "images/logos/logo.jpg";
import { authenticateUser, authenticateUserSAML } from "../../../redux/user/actions";
import { hideAlert } from "../../../redux/alerts/actions";
import { renameAlertMessage } from "../../../helpers/alert.helper";
import './Login.css';
import { emailValidator } from "../../../helpers/validations";

export default function Login({ history }) {
  const messages = useRef();
  const alertState = useSelector((state) => state?.alerts);
  const [loading, setLoading] = useState(false);
  const [loadingForCenhudLogin, setLoadingForCenhudLogin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alertState.show) {
      messages.current.replace({
        sticky: true,
        severity: alertState.type,
        detail: renameAlertMessage(alertState.message),
      });
    }
  }, [alertState]);

  const _handleUserLogin = async ({
    emailAddress,
    password,
  }) => {
    setLoading(true);
    const isAuthenticationSuccess = await dispatch(
      authenticateUser({ emailAddress, password })
    );
    setLoading(false);

    if (isAuthenticationSuccess) {
      history.push("/live-agent-request");
    }
  };

  const signInWithCenHud = async () => {
    setLoadingForCenhudLogin(true);
    const isAuthenticationSuccess = await dispatch(
      authenticateUserSAML()
    );
    setLoadingForCenhudLogin(false);
    if (isAuthenticationSuccess) {
      history.push("/live-agent-request");
    }
  }

  const _onCloseAlert = () => {
    dispatch(hideAlert());
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.emailAddress) {
        errors.emailAddress = "Email Address is required.";
      }

      if (emailValidator(data.emailAddress)) {
        errors.emailAddress = "Invalid email.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data) => {
      _handleUserLogin(data);

      formik.resetForm();
      formik.resetForm();
      formik.validateForm();
    },
    validateOnMount:true
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const passwordHeader = <h6>Enter password</h6>;

  const redirectTo = () => {
    dispatch(hideAlert());
    history.push('/forgot-password')
  }

  return (
    <div className="login-container">
      <div className="login-transparent-layer">

        <div
          className="login-form-container"
        >
          <Card className="login-form-card">
            <div className="login-form-col">
              <img src='images/logos/logo.jpg' className="login-logo" alt="logo" />
              <div className="flex align-items-center justify-content-center mb-5">
                <h5>Sign In</h5>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="p-fluid login-form-card"
              >
                <div className="p-field">
                  <span className="p-float-label p-input-icon-right">
                    {/* <i className="pi pi-envelope" /> */}
                    <InputText
                      id="emailAddress"
                      name="emailAddress"
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("emailAddress"),
                      })}
                    />
                    <label
                      htmlFor="emailAddress"
                      className={classNames({
                        "p-error": isFormFieldValid("emailAddress"),
                      })}
                    >
                      Email Address *
                    </label>
                  </span>
                  {getFormErrorMessage("emailAddress")}
                </div>

                <div className="p-field">
                  <span className="p-float-label">
                    <Password
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      // toggleMask
                      className={classNames({
                        "p-invalid": isFormFieldValid("password"),
                      })}
                      header={passwordHeader}
                    // footer={passwordFooter}
                    />
                    <label
                      htmlFor="password"
                      className={classNames({
                        "p-error": isFormFieldValid("password"),
                      })}
                    >
                      Password *
                    </label>
                  </span>
                  {getFormErrorMessage("password")}
                </div>

                <Messages ref={messages} onRemove={_onCloseAlert}></Messages>

                <div className="login-form-buttons-container">
                  <div className="my-2">
                    <Button
                      type="submit"
                      label="Login"
                      disabled={ !formik.isValid || formik.isSubmitting }
                      loading={loading}
                    />
                  </div>
                  {/* <div className="my-2">
                    <Button
                      type="button"
                      label="Sign In With CenHud"
                      disabled={!formik.isValid || formik.isSubmitting || loadingForCenhudLogin}
                      loading={formik.isSubmitting || loadingForCenhudLogin}
                      onClick={signInWithCenHud}
                    />
                  </div> */}
                </div>
                <Button label="Forgot Password" onClick={redirectTo} className="p-button-link" />
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
