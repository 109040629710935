import { combineReducers } from "@reduxjs/toolkit";

import userReducer from "./user/reducer";
import alertReducer from "./alerts/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  alerts: alertReducer
});

export default rootReducer;
