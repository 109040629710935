import React, { useState, useEffect } from 'react';
import {  fetchConfirmedMeterReading } from '../../../service/MeterReadingService';
import MeterContents from './MeterContents';
import moment from 'moment';


const ConfirmedTab = (props) => {

    const [currentTableData, setCurrentTableData] = useState([]);
    const [isLoadingConfirmedData, setIsLoadingConfirmedData] = useState(false)

    useEffect(() => {
        (
            async function getMeterSubmissionData() {
                await confirmedMeterReadingsFetch({ 
                    nameValue: '',
                    startDate: moment().startOf('day'),
                    stopDate: moment().endOf('day'),
                    sessionIdValue: '',
                })
            }
        )()

    }, []);

    const confirmedMeterReadingsFetch = async (payload) => {
        try {
            setIsLoadingConfirmedData(true);
            const responseData = await fetchConfirmedMeterReading(payload)
            const data = responseData.data
            if (data && data != '') {
                setCurrentTableData(data || []);
            } else {
                console.log('show no data available');
            }
        } catch (e) {
            console.log('error', e);
        } finally {
            setIsLoadingConfirmedData(false);
        }
    }

    const onRefresh = async () => {
        await confirmedMeterReadingsFetch({ 
            nameValue: '',
            startDate: moment().startOf('day'),
            stopDate: moment().endOf('day'),
            sessionIdValue: ''
        })
    }

    const applyChanges = async (payload) => {
        await confirmedMeterReadingsFetch({ nameValue: '',  sessionIdValue: '', ...payload}) 
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <MeterContents data={currentTableData} isLoading={isLoadingConfirmedData} onRefresh={onRefresh} applyChanges={applyChanges} status="CONFIRMED"/>
                </div>
            </div>
        </div>
    )
}
export default ConfirmedTab;