import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { fetchIntentsList } from '../../../../service/SettingsService';
import { fetchExcludeIntents, fetchAnalyticsData, fetchSliceEscalations, fetchAnalyticsCardData } from '../../../../service/ReportsService';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import AnalyticsDialog from './AnalyticsDialog';
import {zeroPad} from '../../../../helpers/validations';

import { Rating } from 'primereact/rating';
        


const Analytics = (props) => {

    const dtOverall = useRef();
    const dtIntents = useRef();
    const dtSentimentsPositive = useRef();
    const dtSentimentsNegative = useRef();

    const [overallData, setOverallData] = useState([]);
    const [intentsData, setIntentsData] = useState([]);
    const [sentimentsPositiveData, setSentimentsPositiveData] = useState([]);
    const [sentimentsNegativeData, setSentimentsNegativeData] = useState([]);
    const [jsonData, setJSONData] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);

    const [isLoadingDataIntents, setIsLoadingDataIntents] = useState(false);
    const [isLoadingDataOverall, setIsLoadingDataOverall] = useState(false);
    const [isLoadingDataPositiveSentiment, setIsLoadingDataPositiveSentiment] = useState(false);
    const [isLoadingDataNegativeSentiment, setIsLoadingDataNegativeSentiment] = useState(false);

    const [badIntentCount, setBadIntentCount] = useState(0)
    const [avgIntentCount, setAvgIntentCount] = useState(0)
    const [goodIntentCount, setGoodIntentCount] = useState(0)

    const [ratingsOneIntentCount, setRatingsOneIntentCount] = useState(0)
    const [ratingsTwoIntentCount, setRatingsTwoIntentCount] = useState(0)
    const [ratingsThreeIntentCount, setRatingsThreeIntentCount] = useState(0)
    const [ratingsFourIntentCount, setRatingsFourIntentCount] = useState(0)
    const [ratingsFiveIntentCount, setRatingsFiveIntentCount] = useState(0)

    const [avgRatingCount, setAvgRatingCount] = useState(0);
    
    const [badPercentage, setBadPercentage] = useState(0)
    const [avgPercentage, setAvgPercentage] = useState(0)
    const [goodPercentage, setGoodPercentage] = useState(0)

    const [ratingsOnePercentage, setRatingsOnePercentage] = useState(0)
    const [ratingsTwoPercentage, setRatingsTwoPercentage] = useState(0)
    const [ratingsThreePercentage, setRatingsThreePercentage] = useState(0)
    const [ratingsFourPercentage, setRatingsFourPercentage] = useState(0)
    const [ratingsFivePercentage, setRatingsFivePercentage] = useState(0)
    

    const [totalSessionEnglish, setTotalSessionEnglish] = useState(0);
    const [totalSessionSpanish, setTotalSessionSpanish] = useState(0);
    const [escalationsEnglish, setTotalEscalationsEnglish] = useState(0);
    const [escalationsSpanish, setTotalEscalationsSpanish] = useState(0);
    const [openEscalationsEnglish, setOpenEscalationsEnglish] = useState(0);
    const [openEscalationsSpanish, setOpenEscalationsSpanish] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);

    const [analyticsDialogData, setAnalyticsDialogData] = useState([]);
    const [analyticsDialog,setAnalyticsDialog] = useState(false);
    const [showIntentField, setShowIntentField] = useState(false);
    const [headerDialog, setHeaderDialog] = useState('');

    const op = useRef();
    const [currentDateRange, setCurrentDateRange] = useState(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`);
    const [payload, setPayload] = useState({
        startDate: moment().startOf('day'),
        stopDate: moment().endOf('day')
    })

    const onChangePicker = ({ selection }) => {
        setPayload({
            startDate: moment(selection.startDate).startOf('day'),
            stopDate: moment(selection.endDate).endOf('day'),
        })
        setCurrentDateRange(`${moment(selection.startDate).startOf('day').format('MMM DD, YYYY')} - ${moment(selection.endDate).endOf('day').format('MMM DD, YYYY')}`)
    }

    useEffect(() => {
        (
            async function initialize() {
                await initializeIntents();
                await sentimentDataLoad();
            }
        )()
    }, [])

    const initializeIntents = async () => {
        try {
            setIsLoadingDataIntents(true);
            const response = await fetchIntentsList(payload);
            const data = response;
            const fetchExcludeIntent = await fetchExcludeIntents();
            let excludeInt = [];
            for (var key in fetchExcludeIntent) {
                if (fetchExcludeIntent[key].ExcludeStatus) {
                    var listObj = {
                        IntentName: fetchExcludeIntent[key].Intent_Name
                    }
                    excludeInt.push(listObj);
                }
            }

            if (data && data != '') {

                let FinalArray = [];
                if (excludeInt.length > 0) {
                    for (var key in data) {
                        var found = false;
                        if (key != "null" && key != "") {
                            for (var obj in excludeInt) {
                                if (excludeInt[obj].IntentName === key) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                var li = {
                                    IntentName: key,
                                    IntentCount: data[key]
                                }
                                FinalArray.push(li);
                            }
                        }
                    }
                } else {
                    for (var key in data) {
                        if (key != "null" && key != "") {
                            var li = {
                                IntentName: key,
                                IntentCount: data[key]
                            }
                            FinalArray.push(li);
                        }
                    }
                }

                var percentCount = 0;
                for (var key in FinalArray) {
                    percentCount += FinalArray[key].IntentCount;
                }
                var output = [];
                for (var key in FinalArray) {
                    var percent = (FinalArray[key].IntentCount / percentCount) * 100;
                    try {
                        var result = {
                            title: FinalArray[key].IntentName,
                            count: FinalArray[key].IntentCount,
                            percentage: percent.toFixed(2) + "%",
                        };
                        output.push(result);
                    } catch (e) {
                        console.log('error', e);
                    }
                }

                output.sort((a, b) => a.count < b.count ? 1 : -1)
                const items = output
                setIntentsData(items)
            }
        }
        catch (e) {
            console.log('error', e);
        } finally {
            setIsLoadingDataIntents(false);
        }

    }

    const sentimentDataLoad = async () => {
        try {
            setIsLoadingDataOverall(true)
            setIsLoadingDataPositiveSentiment(true);
            setIsLoadingDataNegativeSentiment(true);

            const response = await fetchAnalyticsData(payload)
            const data = response.data;

            let escalationSlice = [];
            try {
                const escalationSliceResponse = await fetchSliceEscalations(payload);
                escalationSlice = escalationSliceResponse.data; 
            } catch (e) {
                console.error(e);
            }

            await analyticDataCard(data);

            if (data && data != '' && data != "No interactions Found") {
                setJSONData(data);
                var result = data.Raw.reduce((h, obj) => {
                    h[obj.Sentiment] = (h[obj.Sentiment] || []).concat(obj);
                    return h;
                }, {});

                result = Object.keys(result).map(key => {
                    var sentiment = parseFloat(key);
                    return {
                        SentimentScore: sentiment.toFixed(1),
                        SentimentCount: result[key].length
                    }
                });


                var positiveSentiment = [];
                var negativeSentiment = [];
                for (var obj in result) {
                    if (result[obj].SentimentScore > 0) {
                        var ps = {
                            SentimentScore: result[obj].SentimentScore,
                            SentimentCount: result[obj].SentimentCount
                        }
                        positiveSentiment.push(ps);
                    } else if (result[obj].SentimentScore < 0) {
                        var ns = {
                            SentimentScore: result[obj].SentimentScore,
                            SentimentCount: result[obj].SentimentCount
                        }
                        negativeSentiment.push(ns);
                    }
                }
                let posS = [];
                let negS = [];
                for (var i = 1; i <= 10; i++) {
                    var sentiflag = false;
                    for (var obj in positiveSentiment) {
                        if (i / 10 == positiveSentiment[obj].SentimentScore) {
                            //console.log(positiveSentiment[obj]);
                            var ns = {
                                SentimentScore: positiveSentiment[obj].SentimentScore,
                                SentimentCount: positiveSentiment[obj].SentimentCount
                            }
                            posS.push(ns);
                            sentiflag = true;
                        }
                    }
                    if (sentiflag == false) {
                        var score;
                        if (i == 10) {
                            score = '1.0'
                        } else {
                            score = i / 10
                        }
                        var ns1 = {
                            SentimentScore: score,
                            SentimentCount: 0
                        }
                        posS.push(ns1);
                        sentiflag = true;
                    }
                }
                for (var i = 1; i <= 10; i++) {
                    var sentiflag = false;
                    for (var obj in negativeSentiment) {
                        if (-i / 10 == negativeSentiment[obj].SentimentScore) {
                            var ps = {
                                SentimentScore: negativeSentiment[obj].SentimentScore,
                                SentimentCount: negativeSentiment[obj].SentimentCount
                            }
                            negS.push(ps);
                            sentiflag = true;
                        }
                    }
                    if (sentiflag == false) {
                        var score;
                        if (i == 10) {
                            score = '-1.0'
                        } else {
                            score = -i / 10
                        }
                        var ps1 = {
                            SentimentScore: score,
                            SentimentCount: 0
                        }
                        negS.push(ps1);
                        sentiflag = true;
                    }
                }

                setSentimentsPositiveData(posS.reverse());
                setSentimentsNegativeData(negS.reverse());

                var interactionResult = data.Interactions_session.reduce((h, obj) => {
                    h[obj.Slice] = (h[obj.Slice] || []).concat(obj);
                    return h;
                }, {});
                interactionResult = Object.keys(interactionResult).map(key => {
                    var SliceTime = sliceTime(key);
                    return {
                        Slice: key,
                        SliceTime: SliceTime,
                        InteractionCount: interactionResult[key].reduce((a, b) => a + (b.Interactions || 0), 0),
                        SessionCount: interactionResult[key].length
                    }
                });

                var agentEscalationResult = [];

                if (escalationSlice != "" && escalationSlice != null) {
                    for (var key in escalationSlice) {
                        if (key == "Escalation_Slice") {
                            for (var obj in escalationSlice[key]) {
                                if (escalationSlice[key][obj] != null) {
                                    var list = {
                                        Slice: obj,
                                        escalationCount: escalationSlice[key][obj]
                                    }
                                    agentEscalationResult.push(list);
                                }
                            }
                        }
                    }
                }

                let interactionResultArray = [];
                for (var key in interactionResult) {
                    var interactionflag = false;
                    for (var obj in agentEscalationResult) {
                        if (interactionResult[key].Slice == agentEscalationResult[obj].Slice) {
                            var sliceEql = {
                                Slice: interactionResult[key].Slice,
                                SliceTime: interactionResult[key].SliceTime,
                                InteractionCount: interactionResult[key].InteractionCount,
                                SessionCount: interactionResult[key].SessionCount,
                                AgentEscalationCount: agentEscalationResult[obj].escalationCount
                            }
                            interactionResultArray.push(sliceEql);
                            interactionflag = true;
                        }
                    }
                    if (interactionflag == false) {
                        var slicenoteql = {
                            Slice: interactionResult[key].Slice,
                            SliceTime: interactionResult[key].SliceTime,
                            InteractionCount: interactionResult[key].InteractionCount,
                            SessionCount: interactionResult[key].SessionCount,
                            AgentEscalationCount: 0
                        }
                        interactionResultArray.push(slicenoteql);
                    }
                }

                if (interactionResultArray.length <= 0) {
                    for (var key in interactionResult) {
                        var slicenoteql = {
                            Slice: interactionResult[key].Slice,
                            SliceTime: interactionResult[key].SliceTime,
                            InteractionCount: interactionResult[key].InteractionCount,
                            SessionCount: interactionResult[key].SessionCount,
                            AgentEscalationCount: 0
                        }
                        interactionResultArray.push(slicenoteql);
                    }
                }
                let dataAr = [];
                for (var i = 0; i < 24; i++) {
                    var flag = false;
                    for (var obj in interactionResultArray) {
                        if (i == interactionResultArray[obj].Slice) {
                            var SliceTime = sliceTime(i);
                            var d1 = {
                                Slice: interactionResultArray[obj].Slice,
                                SliceTime: SliceTime,
                                InteractionCount: interactionResultArray[obj].InteractionCount,
                                SessionCount: interactionResultArray[obj].SessionCount,
                                AgentEscalationCount: interactionResultArray[obj].AgentEscalationCount
                            }
                            dataAr.push(d1);
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        var SliceTime = sliceTime(i);
                        var d2 = {
                            Slice: i,
                            SliceTime: SliceTime,
                            InteractionCount: 0,
                            SessionCount: 0,
                            AgentEscalationCount: 0
                        }
                        dataAr.push(d2);
                    }
                }

                setOverallData(dataAr);
            }
        } catch (e) {
            console.log('error', e);
        } finally {
            setIsLoadingDataOverall(false);
            setIsLoadingDataPositiveSentiment(false);
            setIsLoadingDataNegativeSentiment(false);
        }
    }

    const sliceTime = (slice) => {
        switch (slice.toString()) {
            case '0':
                return '12AM to 1AM';
            case '1':
                return '1AM to 2AM';
            case '2':
                return '2AM to 3AM';
            case '3':
                return '3AM to 4AM';
            case '4':
                return '4AM to 5AM';
            case '5':
                return '5AM to 6AM';
            case '6':
                return '6AM to 7AM';
            case '7':
                return '7AM to 8AM';
            case '8':
                return '8AM to 9AM';
            case '9':
                return '9AM to 10AM';
            case '10':
                return '10AM to 11AM';
            case '11':
                return '11AM to 12PM';
            case '12':
                return '12PM to 1PM';
            case '13':
                return '1PM to 2PM';
            case '14':
                return '2PM to 3PM';
            case '15':
                return '3PM to 4PM';
            case '16':
                return '4PM to 5PM';
            case '17':
                return '5PM to 6PM';
            case '18':
                return '6PM to 7PM';
            case '19':
                return '7PM to 8PM';
            case '20':
                return '8PM to 9PM';
            case '21':
                return '9PM to 10PM';
            case '22':
                return '10PM to 11PM';
            case '23':
                return '11PM to 12AM';

        }
    }

    //excel file for intents
    const exportFileForIntents = () => {
        import("xlsx").then((xlsx) => {
            var headerVal = `Intents => Date Range: ${currentDateRange}`;


            const header = [['Intents']];
            const headerTitle = [[headerVal]];
            const wb = xlsx.utils.book_new();

            const ws = xlsx.utils.sheet_add_aoa(wb, headerTitle);


            /* merge cells A1:B1 */
            var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } };
            if (!ws['!merges']) ws['!merges'] = [];
            ws['!merges'].push(merge);


            var ints = [["INTENT NAME", "COUNT", "%"]]
            intentsData.forEach((analyt) => {
                let analytArray = [analyt.title, analyt.count, analyt.percentage]
                ints.push(analytArray)
            })

            //Starting in the second row to avoid overriding and skipping headers
            xlsx.utils.sheet_add_json(ws, ints, { origin: 'A3', skipHeader: true });
            xlsx.utils.book_append_sheet(wb, ws, 'Intents');
            xlsx.writeFile(wb, 'Intents.xls');
        });
    }
    //excel file for overall
    const exportFileForOverall = () => {
        import("xlsx").then((xlsx) => {
            var headerVal = `Overall => Date Range: ${currentDateRange}`;


            const headerTitle = [[headerVal]];
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.sheet_add_aoa(wb, headerTitle);

            /* merge cells A1:B1 */
            var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } };
            if (!ws['!merges']) ws['!merges'] = [];
            ws['!merges'].push(merge);



            var sesOverall = [["TIME", "SESSION", "INTERACTIONS", "LIVE AGENT REQUEST"]]
            overallData.forEach((sesOver) => {
                let sessOverArray = [sesOver.SliceTime, sesOver.SessionCount, sesOver.InteractionCount, sesOver.AgentEscalationCount]
                sesOverall.push(sessOverArray)
            })

            //Starting in the second row to avoid overriding and skipping headers
            xlsx.utils.sheet_add_json(ws, sesOverall, { origin: 'A3', skipHeader: true });
            xlsx.utils.book_append_sheet(wb, ws, 'Overall');
            xlsx.writeFile(wb, 'Overall.xls');

        })
    }
    //excel file for sentiment(+)
    const exportFileForSentimentPos = () => {
        import("xlsx").then((xlsx) => {

            var headerVal = `Sentiments(Positive) => Date Range: ${currentDateRange}`;


            const headerTitle = [[headerVal]];
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.sheet_add_aoa(wb, headerTitle);

            /* merge cells A1:B1 */
            var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } };
            if (!ws['!merges']) ws['!merges'] = [];
            ws['!merges'].push(merge);


            var sentimentPos = [["SCORE", "COUNT"]]
            sentimentsPositiveData.forEach((sesOver) => {
                let sessOverArray = [sesOver.SentimentScore, sesOver.SentimentCount]
                sentimentPos.push(sessOverArray)
            })
            //Starting in the second row to avoid overriding and skipping headers
            xlsx.utils.sheet_add_json(ws, sentimentPos, { origin: 'A3', skipHeader: true });
            xlsx.utils.book_append_sheet(wb, ws, "Sentiments(Positive)");
            xlsx.writeFile(wb, 'Sentiments(Positive).xls');
        })
    }

    //excel file for sentiment(-)
    const exportFileForSentimentNeg = () => {
        import("xlsx").then((xlsx) => {


            var headerVal = `Sentiments(Negative) => Date Range: ${currentDateRange}`;


            const headerTitle = [[headerVal]];
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.sheet_add_aoa(wb, headerTitle);

            /* merge cells A1:B1 */
            var merge = { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } };
            if (!ws['!merges']) ws['!merges'] = [];
            ws['!merges'].push(merge);

            var sentimentNeg = [["SCORE", "COUNT"]]
            sentimentsNegativeData.forEach((sesOver) => {
                let sessOverArray = [sesOver.SentimentScore, sesOver.SentimentCount]
                sentimentNeg.push(sessOverArray)
            })
            //Starting in the second row to avoid overriding and skipping headers
            xlsx.utils.sheet_add_json(ws, sentimentNeg, { origin: 'A3', skipHeader: true });
            xlsx.utils.book_append_sheet(wb, ws, "Sentiments(Negative)");
            xlsx.writeFile(wb, 'Sentiments(Negative).xls');
        })
    }

    const analyticDataCard = async (data) => {
        try {
            if (data && data != '') {

                setTotalSessionEnglish(data['Session-en'] || 0);
                setTotalSessionSpanish(data['Session-es'] || 0);
                setTotalEscalationsEnglish(data['TotalEscalation-en'] || 0);
                setTotalEscalationsSpanish(data['TotalEscalation-es'] || 0);
                setOpenEscalationsEnglish((data['TotalEscalation-en'] - data['CompletedEscalation-en']) || 0)
                setOpenEscalationsSpanish((data['TotalEscalation-es'] - data['CompletedEscalation-es']) || 0)


                var totalSessions = data.Sessions;
                var escalationCount = data['Open Escalations'] + data['Completed Escalations'];
                var containmentRate = 1 - (escalationCount / totalSessions);
                containmentRate = containmentRate * 100;
                containmentRate = Math.round(containmentRate);
                setCompletedCount(containmentRate || 0);
                let sum = 0;
                let great = 0;
                let averge = 0;
                let poor = 0;
                let ratingsOne = 0;
                let ratingsTwo = 0;
                let ratingsThree = 0;
                let ratingsFour = 0;
                let ratingsFive = 0;

                great = data['CsatGrt'] ? data['CsatGrt'] : 0;
                poor = data['CsatPoor'] ? data['CsatPoor'] : 0;
                averge = data['CsatAvg'] ? data['CsatAvg'] : 0;
                ratingsOne = data['CsatOne'] ? data['CsatOne'] : 0;
                ratingsTwo = data['CsatTwo'] ? data['CsatTwo'] : 0;
                ratingsThree = data['CsatThree'] ? data['CsatThree'] : 0;
                ratingsFour = data['CsatFour'] ? data['CsatFour'] : 0;
                ratingsFive = data['CsatFive'] ? data['CsatFive'] : 0;
                sum = great + poor + averge + ratingsOne + ratingsTwo + ratingsThree + ratingsFour + ratingsFive;
                if (sum == 0) {
                    sum = 1;
                }
                // setBadIntentCount(poor);
                // setAvgIntentCount(averge);
                // setGoodIntentCount(great);
                setRatingsOneIntentCount(ratingsOne);
                setRatingsTwoIntentCount(ratingsTwo + poor);
                setRatingsThreeIntentCount(ratingsThree + averge);
                setRatingsFourIntentCount(ratingsFour);
                setRatingsFiveIntentCount(ratingsFive + great)
                // setBadPercentage((poor / sum) * 100)
                // setAvgPercentage((averge / sum) * 100)
                // setGoodPercentage((great / sum) * 100)
                setRatingsOnePercentage((ratingsOne / sum) * 100);
                setRatingsTwoPercentage(((ratingsTwo + poor) / sum) * 100);
                setRatingsThreePercentage(((ratingsThree + averge) / sum) * 100);
                setRatingsFourPercentage((ratingsFour / sum) * 100);
                setRatingsFivePercentage(((ratingsFive + great) / sum) * 100);


                setAvgRatingCount(((1*ratingsOne) + (2*(ratingsTwo+poor)) + (3*(ratingsThree + averge)) + (4*ratingsFour) + (5*(ratingsFive + great)))/sum)
            }
        }
        catch (e) {
            console.log('error', e);
        }
    }

    const applyChanges = async (e) => {
        e.preventDefault();
        op.current.hide(e);
        await initializeIntents();
        await sentimentDataLoad();
    }


    const headerOverall = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <h5 className="m-0">Overall </h5>
            </div>
            <div className="flex flex-row">
                <Button icon="pi pi-download" className="p-button-info mr-2" onClick={exportFileForOverall} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );
    const headerIntents = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <h5 className="m-0">Intents </h5>
            </div>
            <div className="flex flex-row">
                <Button icon="pi pi-download" className="p-button-info mr-2" onClick={exportFileForIntents} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );
    const headerSentimentsPositive = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <h5 className="m-0">Sentiments (Positive)</h5>
            </div>
            <div className="flex flex-row">
                <Button icon="pi pi-download" className="p-button-info mr-2" onClick={exportFileForSentimentPos} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );
    const headerSentimentsNegative = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <h5 className="m-0">Sentiments (Negative)</h5>
            </div>
            <div className="flex flex-row">
                <Button icon="pi pi-download" className="p-button-info mr-2" onClick={exportFileForSentimentNeg} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                </span>
            </div>
        </div>
    );

    const toolbarLeft = (
        <>
            <div className="p-col-12 p-md-4">
                <div className="p-inputgroup">
                    <InputText value={currentDateRange} readOnly />
                    <Button icon="pi pi-calendar" type="button" onClick={(e) => op.current.toggle(e)} />
                </div>
            </div>

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" >
                <DateRangePicker
                    onChange={onChangePicker}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[{
                        startDate: payload.startDate.toDate(),
                        endDate: payload.stopDate.toDate(),
                        key: 'selection'
                    }]}
                    direction="horizontal"
                />
                <Button type="button" label="Apply" onClick={applyChanges} />
            </OverlayPanel>
        </>
    )

    const onRowClickOverall = (event) => {
        let val = event.data.Slice;
        val = zeroPad(val, 2);
        let sessionRaw = jsonData;
        let sessData = [];
        let count = 1;
        for (var key in sessionRaw.Interactions_session) {
            if (sessionRaw.Interactions_session[key] && sessionRaw.Interactions_session[key].Slice) {
                if (val == sessionRaw.Interactions_session[key].Slice) {
                    var dt = {
                        slNo: count,
                        sessionId: sessionRaw.Interactions_session[key].SessionID
                    }
                    count = count + 1;
                    sessData.push(dt);
                }
            }
        }
        setShowIntentField(false);
        setHeaderDialog('Sessions')

        setAnalyticsDialogData(sessData);
        setAnalyticsDialog(true);
    }

    const onRowClickSentiments = (event) => {
        var val = event.data.SentimentScore;
        let sentimentRaw = jsonData;
        let sentData = []
        let count = 1;
        for (var key in sentimentRaw.Raw) {
            if (sentimentRaw.Raw[key] && sentimentRaw.Raw[key].Sentiment) {
                if (val == sentimentRaw.Raw[key].Sentiment.toFixed(1)) {
                    var intentNa;
                    if (sentimentRaw.Raw[key].Intent) {
                        intentNa = sentimentRaw.Raw[key].Intent;
                    } else {
                        intentNa = 'No Intent';
                    }
                    var dt = {
                        slNo: count,
                        intentName: intentNa,
                        sessionId: sentimentRaw.Raw[key].SessionID
                    }
                    count = count + 1;
                    sentData.push(dt);
                }
            }
        }

        setShowIntentField(true);
        setHeaderDialog(`Interactions with ${event.data.SentimentScore}`)

        setAnalyticsDialogData(sentData);
        setAnalyticsDialog(true);
    }

    return (
        <div className="grid p-fluid">
            <div className="col-12">
                <Toolbar left={toolbarLeft} />
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-blue" style={{ height: '100%' }}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{ color: 'white' }}>Total # of Sessions</span>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>English: {totalSessionEnglish}</div>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>Español: {totalSessionSpanish}</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-3">
                <div className="card mb-0 bg-c-purple" style={{ height: '100%' }}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{ color: 'white' }}>Total # of Live Agent Request</span>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>English: {escalationsEnglish}</div>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>Español: {escalationsSpanish}</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">%52+ </span>
                    <span className="text-500">since last week</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-3">
                <div className="card mb-0 bg-c-yellow" style={{ height: '100%' }}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium text-sm mb-3" style={{ color: 'white' }}>Total # of Live Agent Request - Open</span>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>English: {openEscalationsEnglish}</div>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>Español: {openEscalationsSpanish}</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-inbox text-cyan-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">520  </span>
                    <span className="text-500">newly registered</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-pink" style={{ height: '100%' }}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{ color: 'white' }}>Containment Rate %</span>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>{completedCount} %</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-comment text-purple-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">85 </span>
                    <span className="text-500">responded</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-green" style={{ height: '100%' }}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="flex justify-content-between font-medium mb-3" style={{ color: 'white' }}>CSAT<span className='text-xl'>{avgRatingCount?.toFixed(2) || 0}</span></span>
                            {/* <div className="font-medium text-xl" style={{ color: 'white' }}>{goodIntentCount}-{Math.round(goodPercentage)}%-&#128578; Great</div>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>{avgIntentCount}-{Math.round(avgPercentage)}%-&#128528; Average</div>
                            <div className="font-medium text-xl" style={{ color: 'white' }}>{badIntentCount}-{Math.round(badPercentage)}%-&#128577; Poor</div> */}
                            {/* <div className="font-medium text-xl" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{goodIntentCount}-{Math.round(goodPercentage)}%-&#128578;</span> <Rating value={5} readOnly cancel={false} /></div>
                            <div className="font-medium text-xl" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{avgIntentCount}-{Math.round(avgPercentage)}%-&#128528;</span> <Rating value={3} readOnly cancel={false} /></div>
                            <div className="font-medium text-xl" style={{ color: 'white' , display:'flex', gap: '2em'}}><span>{badIntentCount}-{Math.round(badPercentage)}%-&#128577;</span> <Rating value={1} readOnly cancel={false} /></div> */}
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsFiveIntentCount}-{Math.round(ratingsFivePercentage)}%</span> <Rating value={5} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsFourIntentCount}-{Math.round(ratingsFourPercentage)}%</span> <Rating value={4} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsThreeIntentCount}-{Math.round(ratingsThreePercentage)}%</span> <Rating value={3} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsTwoIntentCount}-{Math.round(ratingsTwoPercentage)}%</span> <Rating value={2} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white' , display:'flex', gap: '2em'}}><span>{ratingsOneIntentCount}-{Math.round(ratingsOnePercentage)}%</span> <Rating value={1} readOnly cancel={false} /></div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-comment text-purple-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">85 </span>
                    <span className="text-500">responded</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <DataTable ref={dtOverall} value={overallData}
                    onRowClick={onRowClickOverall} selectionMode="single"
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                    globalFilter={globalFilter} emptyMessage="No data available." header={headerOverall} responsiveLayout="scroll" loading={isLoadingDataOverall}>
                    <Column field="SliceTime" header="Time" sortable ></Column>
                    <Column field="SessionCount" header="Session" sortable></Column>
                    <Column field="InteractionCount" header="Interactions" sortable></Column>
                    <Column field="AgentEscalationCount" header="Live Agent Request" sortable></Column>
                    {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                    {/* <Column  header="ACTIONS"  body={actionBodyTemplate}></Column> */}
                </DataTable>
            </div>
            <div className="col-12 lg:col-6">
                <DataTable ref={dtIntents} value={intentsData} 
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} intents"
                    globalFilter={globalFilter} emptyMessage="No data available." header={headerIntents} responsiveLayout="scroll" loading={isLoadingDataIntents}>
                    <Column field="title" header="Intent Name" sortable ></Column>
                    <Column field="count" header="Count" sortable></Column>
                    <Column field="percentage" header="%" sortable></Column>
                    {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                    {/* <Column  header="ACTIONS"  body={actionBodyTemplate}></Column> */}
                </DataTable>
            </div>
            <div className="col-12 lg:col-6">
                <DataTable ref={dtSentimentsPositive} value={sentimentsPositiveData}
                    onRowClick={onRowClickSentiments} selectionMode="single"
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teams"
                    globalFilter={globalFilter} emptyMessage="No data available." header={headerSentimentsPositive} responsiveLayout="scroll" loading={isLoadingDataPositiveSentiment}>
                    <Column field="SentimentScore" header="Score" sortable ></Column>
                    <Column field="SentimentCount" header="Count" sortable></Column>
                </DataTable>
            </div>
            <div className="col-12 lg:col-6">
                <DataTable ref={dtSentimentsNegative} value={sentimentsNegativeData}
                onRowClick={onRowClickSentiments} selectionMode="single"
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teams"
                    globalFilter={globalFilter} emptyMessage="No data available." header={headerSentimentsNegative} responsiveLayout="scroll" loading={isLoadingDataNegativeSentiment}>
                    <Column field="SentimentScore" header="Score" sortable ></Column>
                    <Column field="SentimentCount" header="Count" sortable></Column>
                </DataTable>
            </div>

            {analyticsDialog && <AnalyticsDialog showIntent={showIntentField} header={headerDialog} visible={analyticsDialog} data={analyticsDialogData} hideDialog={() => {setAnalyticsDialog(false); setAnalyticsDialogData([])}} />}
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Analytics, comparisonFn);
