import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardLayout from "./DashboardLayout";


export default function ProtectedRoute({
  component: Component,
  ...rest
}) {
  const isUserLoggedIn = useSelector((state) => state.user.userId);
  
  return (
    <Route
      {...rest}
      render={(componentProps) =>
        isUserLoggedIn ? (
          <DashboardLayout {...componentProps}>
            <Component {...componentProps} />
          </DashboardLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}
