import React, { useRef, useState, useEffect } from 'react'
import { fetchMeterReadingDetails } from '../../../service/MeterReadingService';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

const ImageDialog = (props) => {
    const dt = useRef();
    const [imageData, setImageData] = useState([])
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (
            async function getImageURLs() {
                setLoading(true)
                const response = await fetchMeterReadingDetails(props.data.sessionID);
                setImageData(response.data.find((_x) => _x.accountNo === props.data.accountNo && _x.meterNumber === props.data.meterNumber ).imageURL || []);
                setLoading(false)
            }
        )()
    }, [])

    const filenameBody = (rowData) => (
        <Link to={{pathname: rowData.imageurl}} target="_blank">{rowData.filename}</Link>
    )


    return (
        <Dialog visible={props.visible} style={{ width: '550px' }} header={props.header} modal className="p-fluid" onHide={props.hideDialogImage}>
            <DataTable ref={dt} value={imageData} loading={isLoading}
                className="datatable-responsive" emptyMessage="No data available." responsiveLayout="scroll">
                <Column header="Filename"  body={filenameBody}></Column>
            </DataTable>
        </Dialog>
    )
}

export default ImageDialog;