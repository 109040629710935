import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { fetchTeamlist } from '../../../../service/SettingsService';
import { AddTeamDialog } from './AddTeamDialog';
import { EditTeamDialog } from './EditTeamDialog';
import { Checkbox } from 'primereact/checkbox';


const statusOptions = [
    { name: "Active", code: "Active" },
    { name: "Inactive", code: "InActive" },
];

const TeamManagement = () => {
    const [teams, setTeams] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [filteredTeams,setFilteredTeams] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(["Active","InActive"]);
  
    const [createTeamDialog, setCreateTeamDialog] = useState(false);
    const [editTeamDialog, setEditTeamDialog] = useState(false);
    const [teamToBeEdited, setTeamToBeEdited] = useState();
    const [selectedTeams, setSelectedTeams] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const menu = useRef();
    const filterMenuRef = useRef();

    useEffect(() => {
        (async function getAllTeams() {
            const teamsFetched = await fetchTeams();
            setFilteredTeams(teamsFetched || []);
        })()
    }, []);


    const fetchTeams = async () => {
        setIsLoadingData(true);
        const list = await fetchTeamlist();
        const teamsFetched = Object.keys(list || [])
            .map((key) => ({
                id: key,
                teamName: list[key].Team_Name,
                description: list[key].Description,
                channel: list[key].Channel,
                status: !!list[key].Status ? "Active" : "InActive",
            }))
            .filter((user) => user.teamName);
        setTeams(teamsFetched || []);
        setIsLoadingData(false);
        return teamsFetched;
    }

    const openNew = () => {
        setCreateTeamDialog(true);
    }

    const hideDialog = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            const teamsFetched = await fetchTeams();
            setFilteredTeams(teamsFetched || []);
        }
        setCreateTeamDialog(false);
    }

    const editTeam = (teamToBeEdited) => {
        setTeamToBeEdited({ ...teamToBeEdited });
        setEditTeamDialog(true);
    }

    /**
      * Export as CSV
      */
    const exportCSV = () => {
        if (dt && dt.current) {
            dt.current.exportCSV();
        }
    }

    /**
    * Export as pdf
    */
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, teams);
                doc.save("teams.pdf");
            });
        });
    };

    /**
    * Export columns
    */
    const exportColumns = teams?.[0]
        ? Object.keys(teams?.[0]).reduce((a, c) => {
            const result = c?.replace(/([A-Z])/g, " $1");
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

            a.push({
                title: finalResult,
                dataKey: c,
            });

            return a;
        }, [])
        : [];

    /**
    * Save as excel
    * @param {*} buffer 
    * @param {*} fileName 
    */
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };

    /**
    * Export as excel
    */
    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(teams);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "teams");
        });
    };

    const onFilterSelect = (event) => {
        let selectedStatusClone = [...selectedStatus];
        const teamsClone = [...teams];
        if(event.checked)
        selectedStatusClone.push(event.value);
        else
        selectedStatusClone.splice(selectedStatusClone.indexOf(event.value), 1);

        const filteredTeamsClone = teamsClone.filter((_t) => selectedStatusClone.includes(_t.status))
        

        setSelectedStatus(selectedStatusClone);
        setFilteredTeams(filteredTeamsClone);
    }

    const refreshData =async () => {
        const teamsFetched = await fetchTeams();
        let selectedStatusClone = [...selectedStatus];
        const filteredTeamsClone = teamsFetched.filter((_t) => selectedStatusClone.includes(_t.status))
        setFilteredTeams(filteredTeamsClone);
    }

    const hideDialogEdit = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            const teamsFetched = await fetchTeams();
            setFilteredTeams(teamsFetched || []);
            setTeamToBeEdited();
        }
        setEditTeamDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} tooltip="Add New Team" tooltipOptions={{ position: 'bottom' }} />
                    {/* <Button label="" icon="pi pi-ban" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedTeams || !selectedTeams.length} tooltip="Invalidate Team" tooltipOptions={{ position: 'bottom' }} /> */}
                    <Button label="" icon="pi pi-refresh" className="p-button-info" onClick={refreshData} tooltip="Refresh" tooltipOptions={{ position: 'bottom' }} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Filter" icon="pi pi-ellipsis-v" className="p-button-success mr-2" onClick={(event) => filterMenuRef.current.toggle(event)} tooltip="Filter" tooltipOptions={{ position: 'bottom' }} />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={(event) => menu.current.toggle(event)} />
                <Menu
                    model={[
                        {
                            label: "Export as CSV",
                            icon: "pi pi-file-o",
                            command: () => exportCSV(),
                        },
                        {
                            label: "Export as Excel",
                            icon: "pi pi-file-excel",
                            command: () => exportExcel(),
                        },
                        {
                            label: "Export as PDF",
                            icon: "pi pi-file-pdf",
                            command: () => exportPdf(),
                        },
                    ]}
                    popup
                    ref={menu}
                    id="popup_menu"
                />
                <Menu
                    ref={filterMenuRef}
                    popup
                    id="filtermenu"
                    model={[
                        {
                            template: () => {
                                return (
                                    <ul className="p-menu-list p-reset" role="menu">
                                        {
                                            statusOptions && statusOptions.map((option, index) => (
                                                <li className="p-menuitem" role="none" key={index}>
                                                    <a className="p-menuitem-link">
                                                        <span className="p-menuitem-icon"><Checkbox id="status" value={option.code} onChange={onFilterSelect} checked={selectedStatus.includes(option.code)} /></span>
                                                        <span className="p-menuitem-text" style={{ marginLeft: '10px' }}>{statusColor(option)}</span>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                );
                            }
                        }
                    ]} />
            </React.Fragment>
        )
    }

    const statusBodyTemplate = (rowData) => {
        let color = "#388e3c";

        if (rowData.status === "Hold") {
            color = "#f7773c";
        }

        if (rowData.status === "InActive") {
            color = "#d65c49";
        }

        return (
            <span
                style={{
                    padding: "0.5rem",
                    backgroundColor: color,
                    fontSize: "0.85rem",
                    color: "#fff",
                    borderRadius: "5px",
                }}
            >
                {rowData.status}
            </span>
        );
    }


    /**
     * Status color
     * @param {*} rowData 
     */
    const statusColor = (rowData) => {
        let color = "#388e3c";


        if (rowData.code === "InActive") {
            color = "#d65c49";
        }

        return (
            <span
                style={{
                    padding: "0.5rem",
                    backgroundColor: color,
                    fontSize: "0.85rem",
                    color: "#fff",
                    borderRadius: "5px",
                }}
            >
                {rowData.name}
            </span>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTeam(rowData)} tooltip='Edit Team' tooltipOptions={{position: 'bottom'}}/>
                {/* <Button icon="pi pi-ban" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} tooltip='Invalidate' tooltipOptions={{position: 'bottom'}} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Teams</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={filteredTeams} selection={selectedTeams} onSelectionChange={(e) => setSelectedTeams(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} teams"
                        globalFilter={globalFilter} emptyMessage="No data available." header={header} responsiveLayout="scroll" loading={isLoadingData}>
                        <Column selectionMode="multiple"></Column>
                        <Column field="teamName" header="TEAM NAME" sortable ></Column>
                        <Column field="description" header="DESCRIPTION" sortable></Column>
                        <Column field="channel" header="CHANNEL" sortable></Column>
                        <Column field="status" header="STATUS" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column header="ACTIONS" body={actionBodyTemplate}></Column>
                    </DataTable>

                    {createTeamDialog && <AddTeamDialog visible={createTeamDialog} hideDialog={hideDialog} />}

                    {editTeamDialog && teamToBeEdited && <EditTeamDialog visible={editTeamDialog} hideDialog={hideDialogEdit} teamToBeEdited={teamToBeEdited}/>}

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TeamManagement, comparisonFn);