import { getDatabase, ref, get,set,update } from "firebase/database";
import axios from "axios";
import moment from 'moment';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


async function getadminKey() {
    const db = getDatabase();
    const key = await get(ref(db,'AdminKey'));
    return key.val();

}

/**
 * Get all users
 */
  export const fetchUsersInformation = async () => {
    const db = getDatabase();
    var fetchUsersData = await get(ref(db,"User Fanout"));
    return fetchUsersData.val();
  };
  
/**
 * Get all teams
 */
  export const fetchTeamlist = async () => {
    const db = getDatabase();
    const fetchTeamlist = await get(ref(db,"Team"));
    return fetchTeamlist.val();
  };


export const fetchSettingsData = async () => {
    const db = getDatabase();
    const fetchSettingsDataResponse = await get(ref(db,"Settings"));
    return fetchSettingsDataResponse.val();
}
  
/**
 * Insert the team details.
 * 
 */
export const createTeamAPI = async (teamDetails) => {
    let UID = localStorage.getItem('isAdmin');
    let email = getAuth().currentUser.email;
    let teamID = uuidv4();
    const db = getDatabase();
    return await set(ref(db,`Team/${teamID}`),{
        'Team_Name': teamDetails.name,
        'Description': teamDetails.description,
        'Channel': teamDetails.channel,
        'Created_On': Date.now(),
        'CreatedByUid': UID,
        'CreatedByEmail': email,
        'Status': true
    });
};

/**
 * Update team details
 * @param {*} teamDetails 
 */
export const updateTeamAPI = async (teamDetails) => {
    let UID = localStorage.getItem('isAdmin');
    let email = getAuth().currentUser.email;
    var stat = false;
    const db = getDatabase();
    if (teamDetails.status == 'Active') {
        stat = true;
    }
    return await update(ref(db,`Team/${teamDetails.id}`),{
        'Team_Name': teamDetails.name,
        'Description': teamDetails.description,
        'Channel': teamDetails.channel,
        'UpdatedTs': Date.now(),
        'updatedByUid': UID,
        'updatedByEmail': email,
        'Status': stat
    });
};

/**
 * Unique id
 */
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Update user
 * @param {*} event 
 * @param {*} isAdmin 
 */
export const updateUser = async (payload) => {
    const db = getDatabase();
    let UID = localStorage.getItem('isAdmin');
    let email = getAuth().currentUser.email;

    return await update(ref(db,`User Fanout/${payload.id}`),{
        'Name': payload.name,
        'Email': payload.email,
        'isAdmin': payload.role === 'Admin',
        'Team': payload.team,
        'Status': payload.status === 'Active',
        'Role': payload.role,
        'UpdatedTs': Date.now(),
        'updatedByUid': UID,
        'updatedByEmail': email,
        'EnableChatBot': payload.enableChatBot
    });
}


export const registerUser = async (payload) => {
    const auth = getAuth();
    return await createUserWithEmailAndPassword(auth, payload.emailAddress, payload.password)
};


/**
 * Insert the user management fanout data.
 * @param accountNumber 
 * @param userDetails 
 */
export const createUserDetails = async (payload, userUID) => {
    let UID = localStorage.getItem('isAdmin');
    let email = getAuth().currentUser.email;
    const db = getDatabase()
    return set(ref(db,`User Fanout/${userUID}`),{
        'Name': payload.name,
        'Email': payload.emailAddress,
        'Created On': Date.now(),
        'Last Login': Date.now(),
        'isAdmin': payload.role === 'Admin',
        'Role': payload.role,
        'Status': true,
        'Team': payload.team,
        'CreatedByUid': UID,
        'CreatedByEmail': email,
        'EnableChatBot': payload.enableChatBot
    });
};


export const fetchIntentsList = async (payload) => {
    const adminKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let url = ``;
    if (payload) {
        url = `${process.env.REACT_APP_API_BASE_URL}/v1/analytics_new/intents?key=${adminKey}&startDate=${payload.startDate}&stopDate=${payload.stopDate}`;
    } else {
        url = `${process.env.REACT_APP_API_BASE_URL}/v1/analytics_new/intents?key=${adminKey}`;
    }
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.data || [];
};


/**
 * update the settings data in firebase.
 * @param settingsData 
 */
export const updateSettingsData = async (settingsData) => {
    const db = getDatabase();

    return await update(ref(db,`Settings`),{
        'Override': settingsData.enableOverride,
        'ResolveTimer': settingsData.resolveTimer,
        'State': settingsData.enableState,
    });

};

export const fetchAgentHours = async () => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/getAgentHours`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.data || [];
}

export const fetchAgentHolidays = async () => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/getHolidays`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.data || [];
};

export const addAgentHolidays = async (payload) => {
    const token = await getAuth().currentUser.getIdToken();
    const date = moment(payload.date).format('MM-DD-YYYY')
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/updateHoliday?holidayDate=${date}&holidayName=${payload.description}`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.status;
};

export const deleteAgentHolidays = async (payload) => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/deleteHoliday?holidayName=${payload.description}`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.status;
};


export const fetchConfigData = async () => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/getUserSettings`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response;
}

export const updateConfigData = async (payload) => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/setOverride?override=${payload.Override}`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response;
}

export const updateAgentHours = async (payload) => {
    const token = await getAuth().currentUser.getIdToken();
    let   url = `${process.env.REACT_APP_API_BASE_URL}/v1/updateAgentHours?day=${payload.day}&startTime=${payload.startTime}&endTime=${payload.endTime}&active=${payload.active}`;
    const response = await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}` , 
            'Content-Type': 'application/json'
        },
    });
    return response.status;
}

