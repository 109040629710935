import React, { useEffect, useState, useRef } from 'react';
import {Chart} from "primereact/chart";
import 'chartjs-adapter-date-fns';
import { fetchIntentsList } from '../../../../service/SettingsService';
import { fetchExcludeIntents, fetchAnalyticsData } from '../../../../service/ReportsService';
import moment from 'moment';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { DateRangePicker } from 'react-date-range';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Rating } from 'primereact/rating';
import { format } from 'date-fns';


const sentimentOptionsDefault = {
    chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
        },
        toolbar: {
            autoSelected: 'zoom'
        }
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
    },
    title: {
        text: 'Sentiment Analysis',
        align: 'left'
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
        },
    },
    yaxis: {
        labels: {
            offsetX: 14,
            offsetY: -5
        },
        tooltip: {
            enabled: true
        }
    },
    xaxis: {
        type: "datetime",
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        }
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 1,
        dashArray: 0,
    }
};


const AnalyticsGraph = (props) => {

    const [sessionOptions, setSessionOptions] = useState({});
    const [sessionSeries, setSessionSeries] = useState([]);
    const [intentOptions, setIntentOptions] = useState({});
    const [intentSeries, setIntentSeries] = useState([]);
    const [sentimentOptions, setSentimentOptions] = useState();
    const [sentimentSeries, setSentimentSeries] = useState([]);
    const [interactionSeries, setInteractionSeries] = useState([]);
    const [interactionOptions, setInteractionOptions] = useState({});

    const [badIntentCount, setBadIntentCount] = useState(0)
    const [avgIntentCount, setAvgIntentCount] = useState(0)
    const [goodIntentCount, setGoodIntentCount] = useState(0)

    const [ratingsOneIntentCount, setRatingsOneIntentCount] = useState(0)
    const [ratingsTwoIntentCount, setRatingsTwoIntentCount] = useState(0)
    const [ratingsThreeIntentCount, setRatingsThreeIntentCount] = useState(0)
    const [ratingsFourIntentCount, setRatingsFourIntentCount] = useState(0)
    const [ratingsFiveIntentCount, setRatingsFiveIntentCount] = useState(0)

    const [avgRatingCount, setAvgRatingCount] = useState(0);
    
    const [badPercentage, setBadPercentage] = useState(0)
    const [avgPercentage, setAvgPercentage] = useState(0)
    const [goodPercentage, setGoodPercentage] = useState(0)

    const [ratingsOnePercentage, setRatingsOnePercentage] = useState(0)
    const [ratingsTwoPercentage, setRatingsTwoPercentage] = useState(0)
    const [ratingsThreePercentage, setRatingsThreePercentage] = useState(0)
    const [ratingsFourPercentage, setRatingsFourPercentage] = useState(0)
    const [ratingsFivePercentage, setRatingsFivePercentage] = useState(0)
    
    const [totalSessionEnglish, setTotalSessionEnglish] = useState(0);
    const [totalSessionSpanish, setTotalSessionSpanish] = useState(0);
    const [escalationsEnglish, setTotalEscalationsEnglish] = useState(0);
    const [escalationsSpanish, setTotalEscalationsSpanish] = useState(0);
    const [openEscalationsEnglish, setOpenEscalationsEnglish] = useState(0);
    const [openEscalationsSpanish, setOpenEscalationsSpanish] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);

    const op = useRef();
    const [currentDateRange, setCurrentDateRange] = useState(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`);
    const [payload, setPayload] = useState({
        startDate: moment().startOf('day'),
        stopDate: moment().endOf('day')
    })

    const [isLoadingDataIntents, setIsLoadingDataIntents] = useState(false);
    const [isLoadingDataOverall, setIsLoadingDataOverall] = useState(false);

    const onChangePicker = ({ selection }) => {
        setPayload({
            startDate: moment(selection.startDate).startOf('day'),
            stopDate: moment(selection.endDate).endOf('day'),
        })
        setCurrentDateRange(`${moment(selection.startDate).startOf('day').format('MMM DD, YYYY')} - ${moment(selection.endDate).endOf('day').format('MMM DD, YYYY')}`)
    }

    useEffect(() => {
        (
            async function initialize() {
                setIsLoadingDataIntents(true)
                setIsLoadingDataOverall(true);
                await initializeIntentOnLoad();
                await sentimentDataLoad();
                setIsLoadingDataIntents(false);
                setIsLoadingDataOverall(false);
            }
        )();
    }, []);

    const initializeIntentOnLoad = async () => {
        try {
            const response = await fetchIntentsList(payload)
            const data = response;
            const fetchExcludeIntent = await fetchExcludeIntents();
            if (data && data != '') {

                let excludeInt = [];
                for (var key in fetchExcludeIntent) {
                    if (fetchExcludeIntent[key].ExcludeStatus) {
                        var listObj = {
                            IntentName: fetchExcludeIntent[key].Intent_Name
                        }
                        excludeInt.push(listObj);
                    }
                }

                let FinalArray = [];
                if (excludeInt.length > 0) {
                    for (var key in data) {
                        var found = false;
                        if (key != "null" && key != "") {
                            for (var obj in excludeInt) {
                                if (excludeInt[obj].IntentName === key) {
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                var li = {
                                    IntentName: key,
                                    IntentCount: data[key]
                                }
                                FinalArray.push(li);
                            }
                        }
                    }
                } else {
                    for (var key in data) {
                        if (key != "null" && key != "") {
                            var li = {
                                IntentName: key,
                                IntentCount: data[key]
                            }
                            FinalArray.push(li);
                        }
                    }
                }

                var percentCount = 0;
                for (var key in FinalArray) {
                    percentCount += FinalArray[key].IntentCount;
                }
                var output = [];
                for (var key in FinalArray) {
                    var percent = (FinalArray[key].IntentCount / percentCount) * 100;
                    try {
                        var result = {
                            title: FinalArray[key].IntentName,
                            count: FinalArray[key].IntentCount,
                            percentage: percent.toFixed(2) + "%",
                        };
                        output.push(result);
                    } catch (e) {
                        console.log('error', e);
                    }
                }

                output.sort((a, b) => a.count < b.count ? 1 : -1)
                const size = 10
                const items = output
                var intentArr = [];
                var intentlblArr = [];

                for (var obj in items) {
                    intentArr.push(items[obj].count).toString();
                    intentlblArr.push(items[obj].title).toString();
                }

                setIntentSeries({
                    labels: intentlblArr,
                    datasets: [{
                        data: intentArr,
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726",
                            "#EC7C26",
                            "#F80000",
                            "#AEA04B",
                            "#308446",
                            "#79553D"
                        ],
                        hoverOffset: 4
                    }]
                });
                setIntentOptions({
                    maintainAspectRatio: true,
                    aspectRatio: 2,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Intents',
                            align: 'start'
                        }
                    },
                });

                // setIntentSeries(intentArr)
                // setIntentOptions({
                //     chart: {
                //         width: 380,
                //         type: 'pie',
                //     },
                //     labels: intentlblArr,
                //     legend: {
                //         show: false
                //     },
                //     responsive: [{
                //         breakpoint: 480,
                //         options: {
                //             chart: {
                //                 width: 500,
                //                 height: 500
                //             },
                //         }
                //     }],
                //     title: {
                //         text: 'Intents',
                //         align: 'left'
                //     },
                // })
            }
        }
        catch (e) {
            console.log('error', e);
        }

    }

    const sentimentDataLoad = async () => {
        try {
            const response = await fetchAnalyticsData(payload)
            const data = response.data;
            sessionDataLoad(data);
            interactionDataLoad(data);
            analyticDataCard(data);

            if (data && data != '') {
                var output = [];
                for (var key in data.Raw) {
                    if (data.Raw.hasOwnProperty(key) && data.Raw[key].Sentiment != null) {
                        output.push([data.Raw[key].Timestamp, data.Raw[key].Sentiment.toFixed(1)]);
                    }
                }

                // setSentimentSeries([{
                //     name: 'Sentiment Analysis',
                //     data: output
                // }]);
                setSentimentSeries({
                    type: 'line',
                    labels: output.map((_o) => format(new Date(_o[0]), 'dd MMM yy')),
                    datasets: [{
                        tension: 0,
                        fill: {
                            target: 'origin',
                            above: 'rgb(0, 0, 0,.2)',   // Area will be red above the origin
                            below: 'rgb(0, 0, 0,.2)'    // And blue below the origin
                        },                  
                        data: output.map((_o) => ({x: format(new Date(_o[0]), 'dd MMM yy'), y: _o[1]}))
                    }]
                });

                setSentimentOptions({
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: 'Sentiment Analysis',
                            align: 'start'
                        }
                    }
                });
            }

        } catch (e) {
            console.log('error', e);
        }
    }

    const sessionDataLoad = async (data) => {
        try {

            let output = [];
            if (data && data != '' && data != "No interactions Found") {
                var interactionResult = data.Interactions_session.reduce(function (h, obj) {
                    h[obj.Slice] = (h[obj.Slice] || []).concat(obj);
                    return h;
                }, {});
                interactionResult = Object.keys(interactionResult).map(key => {
                    var SliceTime = sliceTime(key);
                    return {
                        Slice: key,
                        SliceTime: SliceTime,
                        InteractionCount: interactionResult[key].reduce((a, b) => a + (b.Interactions || 0), 0),
                        SessionCount: interactionResult[key].length
                    }
                });
                output.push(interactionResult);
            }
            var sessionArr = [];
            var sessionLblArr = [];
            let dataAr = [];
            for (var i = 0; i < 24; i++) {
                var flag = false;
                for (var obj in output[0]) {
                    if (i == output[0][obj].Slice) {
                        var SliceTime = sliceTime(i);
                        var d1 = {
                            Slice: output[0][obj].Slice,
                            SliceTime: SliceTime,
                            InteractionCount: output[0][obj].InteractionCount,
                            SessionCount: output[0][obj].SessionCount,
                            AgentEscalationCount: output[0][obj].AgentEscalationCount
                        }
                        dataAr.push(d1);
                        flag = true;
                    }
                }
                if (flag == false) {
                    var SliceTime = sliceTime(i);
                    var d2 = {
                        Slice: i,
                        SliceTime: SliceTime,
                        InteractionCount: 0,
                        SessionCount: 0,
                        AgentEscalationCount: 0
                    }
                    dataAr.push(d2);
                }
            }

            for (var obj in dataAr) {
                sessionArr.push([dataAr[obj].SessionCount].toString());
                sessionLblArr.push([dataAr[obj].SliceTime].toString());
            }

            setSessionSeries({
                labels: sessionLblArr,
                datasets: [{
                    backgroundColor: '#42A5F5',
                    data: sessionArr
                }]
            });
            setSessionOptions({
                animation: {
                    onComplete: function(context) {
                      if (context.initial) {
                        console.log('Initial animation finished');
                      } else {
                        console.log('animation finished');
                      }
                    }
                  },
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Sessions',
                        align: 'start'
                    }
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    const sliceTime = (slice) => {
        switch (slice.toString()) {
            case '0':
                return '12AM to 1AM';
            case '1':
                return '1AM to 2AM';
            case '2':
                return '2AM to 3AM';
            case '3':
                return '3AM to 4AM';
            case '4':
                return '4AM to 5AM';
            case '5':
                return '5AM to 6AM';
            case '6':
                return '6AM to 7AM';
            case '7':
                return '7AM to 8AM';
            case '8':
                return '8AM to 9AM';
            case '9':
                return '9AM to 10AM';
            case '10':
                return '10AM to 11AM';
            case '11':
                return '11AM to 12PM';
            case '12':
                return '12PM to 1PM';
            case '13':
                return '1PM to 2PM';
            case '14':
                return '2PM to 3PM';
            case '15':
                return '3PM to 4PM';
            case '16':
                return '4PM to 5PM';
            case '17':
                return '5PM to 6PM';
            case '18':
                return '6PM to 7PM';
            case '19':
                return '7PM to 8PM';
            case '20':
                return '8PM to 9PM';
            case '21':
                return '9PM to 10PM';
            case '22':
                return '10PM to 11PM';
            case '23':
                return '11PM to 12AM';

        }
    }

    const interactionDataLoad = async (data) => {
        try {

            let output = [];
            if (data && data != '' && data != "No interactions Found") {
                var interactionResult = data.Interactions_session.reduce(function (h, obj) {
                    h[obj.Slice] = (h[obj.Slice] || []).concat(obj);
                    return h;
                }, {});
                interactionResult = Object.keys(interactionResult).map(key => {
                    var SliceTime = sliceTime(key);
                    return {
                        Slice: key,
                        SliceTime: SliceTime,
                        InteractionCount: interactionResult[key].reduce((a, b) => a + (b.Interactions || 0), 0),
                        SessionCount: interactionResult[key].length
                    }
                });
                output.push(interactionResult);
            }
            var interactionArr = [];
            var interactionLblArr = [];
            let dataAr = [];
            for (var i = 0; i < 24; i++) {
                var flag = false;
                for (var obj in output[0]) {
                    if (i == output[0][obj].Slice) {
                        var SliceTime = sliceTime(i);
                        var d1 = {
                            Slice: output[0][obj].Slice,
                            SliceTime: SliceTime,
                            InteractionCount: output[0][obj].InteractionCount,
                            SessionCount: output[0][obj].SessionCount,
                            AgentEscalationCount: output[0][obj].AgentEscalationCount
                        }
                        dataAr.push(d1);
                        flag = true;
                    }
                }
                if (flag == false) {
                    var SliceTime = sliceTime(i);
                    var d2 = {
                        Slice: i,
                        SliceTime: SliceTime,
                        InteractionCount: 0,
                        SessionCount: 0,
                        AgentEscalationCount: 0
                    }
                    dataAr.push(d2);
                }
            }

            for (var obj in dataAr) {
                interactionArr.push([dataAr[obj].InteractionCount].toString());
                interactionLblArr.push([dataAr[obj].SliceTime].toString());
            }


            setInteractionSeries({
                labels: interactionLblArr,
                datasets: [{
                    backgroundColor: '#42A5F5',
                    data: interactionArr
                }]
            });
            setInteractionOptions({
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Interaction',
                        align: 'start'
                    }
                },
            });

            // setInteractionSeries([{
            //     name: 'Interaction',
            //     data: interactionArr
            // }]);

            // setInteractionOptions({
            //     chart: {
            //         type: 'bar',
            //         height: 350
            //     },
            //     dataLabels: {
            //         enabled: false
            //     },
            //     xaxis: {
            //         categories: interactionLblArr,
            //     },
            //     title: {
            //         text: 'Interaction',
            //         align: 'left'
            //     },
            // });
        } catch (e) {
            console.log('error', e);
        }
    }

    const analyticDataCard = async (data) => {
        try {
            if (data && data != '') {
                setTotalSessionEnglish(data['Session-en'] || 0);
                setTotalSessionSpanish(data['Session-es'] || 0);
                setTotalEscalationsEnglish(data['TotalEscalation-en'] || 0);
                setTotalEscalationsSpanish(data['TotalEscalation-es'] || 0);
                setOpenEscalationsEnglish((data['TotalEscalation-en'] - data['CompletedEscalation-en']) || 0)
                setOpenEscalationsSpanish((data['TotalEscalation-es'] - data['CompletedEscalation-es']) || 0)


                var totalSessions = data.Sessions;
                var escalationCount = data['Open Escalations'] + data['Completed Escalations'];;
                var containmentRate = 1 - (escalationCount / totalSessions);
                containmentRate = containmentRate * 100;
                containmentRate = Math.round(containmentRate);
                setCompletedCount(containmentRate || 0);

                let sum = 0;
                let great = 0;
                let averge = 0;
                let poor = 0;
                let ratingsOne = 0;
                let ratingsTwo = 0;
                let ratingsThree = 0;
                let ratingsFour = 0;
                let ratingsFive = 0;
                great = data['CsatGrt'] ? data['CsatGrt'] : 0;
                poor = data['CsatPoor'] ? data['CsatPoor'] : 0;
                averge = data['CsatAvg'] ? data['CsatAvg'] : 0;
                ratingsOne = data['CsatOne'] ? data['CsatOne'] : 0;
                ratingsTwo = data['CsatTwo'] ? data['CsatTwo'] : 0;
                ratingsThree = data['CsatThree'] ? data['CsatThree'] : 0;
                ratingsFour = data['CsatFour'] ? data['CsatFour'] : 0;
                ratingsFive = data['CsatFive'] ? data['CsatFive'] : 0;
                sum = great + poor + averge + ratingsOne + ratingsTwo + ratingsThree + ratingsFour + ratingsFive;
                if (sum == 0) {
                    sum = 1;
                }
                // setBadIntentCount(poor);
                // setAvgIntentCount(averge);
                // setGoodIntentCount(great);
                setRatingsOneIntentCount(ratingsOne);
                setRatingsTwoIntentCount(ratingsTwo + poor);
                setRatingsThreeIntentCount(ratingsThree + averge);
                setRatingsFourIntentCount(ratingsFour);
                setRatingsFiveIntentCount(ratingsFive + great)
                // setBadPercentage((poor / sum) * 100)
                // setAvgPercentage((averge / sum) * 100)
                // setGoodPercentage((great / sum) * 100)
                setRatingsOnePercentage((ratingsOne / sum) * 100);
                setRatingsTwoPercentage(((ratingsTwo + poor) / sum) * 100);
                setRatingsThreePercentage(((ratingsThree + averge) / sum) * 100);
                setRatingsFourPercentage((ratingsFour / sum) * 100);
                setRatingsFivePercentage(((ratingsFive + great) / sum) * 100);


                setAvgRatingCount(((1*ratingsOne) + (2*(ratingsTwo+poor)) + (3*(ratingsThree + averge)) + (4*ratingsFour) + (5*(ratingsFive + great)))/sum)
            }
        }
        catch (e) {
            console.log('error', e);
        }

    }


    const applyChanges = async (e) => {
        e.preventDefault();
        op.current.hide(e);
        setIsLoadingDataIntents(true)
        setIsLoadingDataOverall(true);
        await initializeIntentOnLoad();
        await sentimentDataLoad();
        setIsLoadingDataIntents(false)
        setIsLoadingDataOverall(false);
    }

    const toolbarLeft = (
        <>
            <div className="p-col-12 p-md-4">
                <div className="p-inputgroup">
                    <InputText value={currentDateRange} readOnly />
                    <Button icon="pi pi-calendar" type="button" onClick={(e) => op.current.toggle(e)} />
                </div>
            </div>
            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" >
                <DateRangePicker
                    onChange={onChangePicker}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[{
                        startDate: payload.startDate.toDate(),
                        endDate: payload.stopDate.toDate(),
                        key: 'selection'
                    }]}
                    direction="horizontal"
                />
                <Button type="button" label="Apply" onClick={applyChanges} />
            </OverlayPanel>
        </>
    )


    return (
        <div className="grid p-fluid">
            <div className="col-12">
                <Toolbar left={toolbarLeft} />
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-blue" style={{height:'100%'}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{color: 'white'}}>Total # of Sessions</span>
                            <div className="font-medium text-xl" style={{color: 'white'}}>English: {totalSessionEnglish}</div>
                            <div className="font-medium text-xl" style={{color: 'white'}}>Español: {totalSessionSpanish}</div>

                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-3">
                <div className="card mb-0 bg-c-purple" style={{height:'100%'}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{color: 'white'}}>Total # of Live Agent Request</span>
                            <div className="font-medium text-xl" style={{color: 'white'}}>English: {escalationsEnglish}</div>
                            <div className="font-medium text-xl" style={{color: 'white'}}>Español: {escalationsSpanish}</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">%52+ </span>
                    <span className="text-500">since last week</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-3">
                <div className="card mb-0 bg-c-yellow" style={{height:'100%'}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium mb-3" style={{color: 'white'}}>Total # of Live Agent Request - Open</span>
                            <div className="font-medium text-xl" style={{color: 'white'}}>English: {openEscalationsEnglish}</div>
                            <div className="font-medium text-xl" style={{color: 'white'}}>Español: {openEscalationsSpanish}</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-inbox text-cyan-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">520  </span>
                    <span className="text-500">newly registered</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-pink" style={{height:'100%'}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block font-medium text-sm mb-3" style={{color: 'white'}}>Containment Rate %</span>
                            <div className="font-medium text-xl" style={{color: 'white'}}>{completedCount} %</div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-comment text-purple-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">85 </span>
                    <span className="text-500">responded</span> */}
                </div>
            </div>
            <div className="col-12 md:col-4 xl:col-2">
                <div className="card mb-0 bg-c-green" style={{height:'100%'}}>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="flex justify-content-between font-medium mb-3" style={{ color: 'white' }}>CSAT<span className='text-xl'>{avgRatingCount?.toFixed(2) || 0}</span></span>
                            {/* <div className="font-medium text-xl" style={{color: 'white'}}>{goodIntentCount}-{Math.round(goodPercentage)}%-&#128578; Great</div>
                            <div className="font-medium text-xl" style={{color: 'white'}}>{avgIntentCount}-{Math.round(avgPercentage)}%-&#128528; Average</div>
                            <div className="font-medium text-xl" style={{color: 'white'}}>{badIntentCount}-{Math.round(badPercentage)}%-&#128577; Poor</div> */}
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsFiveIntentCount}-{Math.round(ratingsFivePercentage)}%</span> <Rating value={5} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsFourIntentCount}-{Math.round(ratingsFourPercentage)}%</span> <Rating value={4} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsThreeIntentCount}-{Math.round(ratingsThreePercentage)}%</span> <Rating value={3} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white', display:'flex', gap: '2em' }}><span>{ratingsTwoIntentCount}-{Math.round(ratingsTwoPercentage)}%</span> <Rating value={2} readOnly cancel={false} /></div>
                            <div className="font-medium justify-content-between text-l" style={{ color: 'white' , display:'flex', gap: '2em'}}><span>{ratingsOneIntentCount}-{Math.round(ratingsOnePercentage)}%</span> <Rating value={1} readOnly cancel={false} /></div>
                        </div>
                        {/* <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-comment text-purple-500 text-xl" />
                        </div> */}
                    </div>
                    {/* <span className="text-green-500 font-medium">85 </span>
                    <span className="text-500">responded</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card charts">
                    <h5>Sessions</h5>
                    {isLoadingDataIntents && <div className="flex flex-column align-items-center spinnerloader"><ProgressSpinner /></div>}
                    {/* {!isLoadingDataIntents && <Chart options={sessionOptions} series={sessionSeries} type="bar" />} */}
                    <Chart options={sessionOptions} data={sessionSeries} type="bar" />

                    {/* <Chart type="line" data={lineData} options={lineOptions} /> */}
                </div>

                <div className="card charts">
                    <h5>Interactions</h5>
                    {isLoadingDataOverall && <div className="flex flex-column align-items-center spinnerloader"><ProgressSpinner /></div>}
                    {/* {!isLoadingDataOverall && <Chart id="2" options={interactionOptions} series={interactionSeries} type="bar" />} */}
                    <Chart options={interactionOptions} data={interactionSeries} type="bar" />

                    {/* <Chart type="bar" data={barData} options={barOptions} /> */}
                </div>


                {/* <div className="card flex flex-column align-items-center">
                    <h5>Polar Area Chart</h5>
                    <Chart type="polarArea" data={polarData} options={polarOptions} style={{ width: '50%' }} />
                </div> */}
            </div>
            <div className="col-12 lg:col-6">

                <div className="card charts">
                    <h5>Intents</h5>
                    {isLoadingDataOverall && <div className="flex flex-column align-items-center spinnerloader"><ProgressSpinner /></div>}
                    {/* {!isLoadingDataOverall && <Chart id="3" options={intentOptions} series={intentSeries} type="pie" />} */}
                    <Chart height='380' width='380' options={intentOptions} data={intentSeries} type="pie" />
                    {/* <Chart type="pie" data={pieData} options={pieOptions} style={{ width: '50%' }} /> */}
                </div>
                
                <div className="card charts">
                    <h5>Sentiments</h5>
                    {isLoadingDataOverall && <div className="flex flex-column align-items-center spinnerloader"><ProgressSpinner /></div>}
                    {/* {!isLoadingDataOverall && <Chart id="Z" options={sentimentOptions} series={sentimentSeries} type="area" />} */}
                    <Chart options={sentimentOptions} data={sentimentSeries} type="line" />

                    {/* <Chart type="doughnut" data={doughnutData} options={pieOptions} style={{ width: '50%' }} /> */}
                </div>

                {/* <div className="card flex flex-column align-items-center">
                    <h5>Radar Chart</h5>
                    <Chart type="radar" data={radarData} options={radarOptions} style={{ width: '50%' }} />
                </div> */}
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(AnalyticsGraph, comparisonFn);
