import { getDatabase, ref, get } from "firebase/database";
import axios from "axios";
import moment from 'moment';
import { getAuth } from "firebase/auth";


async function getadminKey() {
    const db = getDatabase();
    const key = await get(ref(db,'AdminKey'));
    return key.val();

}

export const fetchFeedbackList = async (payload) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    let endpoint = ``;
    if (!payload) {
        endpoint =  `${process.env.REACT_APP_API_BASE_URL}/v1/getFeedbackList?key=${tokenKey}`;
    } else {
        const startDate = moment(payload.startDate).format('MM/DD/yyyy');
        const stopDate = moment(payload.stopDate).format('MM/DD/yyyy');
        endpoint =  `${process.env.REACT_APP_API_BASE_URL}/v1/getFeedbackList?key=${tokenKey}&startDate=${startDate}&stopDate=${stopDate}`;
    }
    return await axios.get(endpoint, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const fetchAnalyticsData = async (payload) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/analytics_new?key=${tokenKey}&startDate=${payload.startDate}&stopDate=${payload.stopDate}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });
};


export const fetchExcludeIntents = async () => {
    const db = getDatabase();
    var fetchAgent = await get(ref(db,'IntentsExclude'));
    return fetchAgent.val();
}

export const fetchSliceEscalations = async (payload) => {
    const tokenKey = await getadminKey();
    const token = await getAuth().currentUser.getIdToken();
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/v1/analytics_new/escalations?key=${tokenKey}&startDate=${payload.startDate}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })
}