import React, { useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { useFormik } from 'formik'
import { Button } from 'primereact/button';
import { normalizeOnlyNums, emailValidator, validateLength } from '../../../helpers/validations'

const ctOptions = [{
  label: 'Existing Customer',
  value: "Existing Customer"
}, {
  label: "New Customer",
  value: "New Customer"
}]
function EditUserDialogChats(props) {

  const formik = useFormik({
    initialValues: {
      userId: "",
      userName: "",
      email: "",
      phone: "",
      address: "",
      customerType: "Existing Customer",
      comments: ""
    },
    validate: (data) => {
      var errors = {}

      if (emailValidator(data.email)) {
        errors.email = 'Invalid Email Format';
      }

      if (data.phone.length > 10) {
        errors.phone=`This field must be 10 characters long.`
      }
      console.log(errors);
      return errors;
    },
    onSubmit: (data) => {
      props.onSubmit(data);
      formik.resetForm();
    },
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (props.visible && props.propsData) {
      formik.setValues({
        userName: props.propsData['name'] || '',
        email: props.propsData['email'] || '',
        phone: props.propsData['phone'] || '',
        userId: props.propsData['chatID'] || '',
        address: props.propsData['address'] || '',
        customerType: props.propsData['customerType'] || "Existing Customer",
        comments: props.propsData['comments'] || ''
      })
    }
  }, [props.visible, props.propsData])


  const footer = (
    <div className="flex flex-row justify-content-end">
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button type='submit' onClick={() => formik.handleSubmit()}>Update</Button>
    </div>
  )


  return (
    <Dialog header={`Edit Session Info`} visible={props.visible} style={{ width: '50vw' }} onHide={() => props.onCancel()} footer={footer}>
      <div className="d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-8 mx-auto">
              <div className="card card-signin my-5">
                <div className="card-body">
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                    <label style={{ width: '10%' }}>Full Name</label>
                    <InputText
                      name="userName"
                      id="userName"
                      placeholder="Full Name"
                      style={{ width: '90%' }}
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                    <label style={{ width: '10%' }}>Email</label>
                    <InputText
                      name="email"
                      id="email"
                      placeholder="Email address"
                      className={isFormFieldValid('email') && `p-invalid`}
                      style={{ width: '90%' }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="flex flex-row mb-3">
                    <span style={{width: '10%'}}></span>
                     {getFormErrorMessage('email')}
                  </div>
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                    <label style={{ width: '10%' }}>Phone</label>
                    <InputText
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      style={{ width: '90%' }}
                      value={formik.values.phone}
                      className={isFormFieldValid('phone') && `p-invalid`}
                      onChange={(event) => 
                        {
                          event.target.value = normalizeOnlyNums(event.target.value);
                          formik.handleChange(event);
                        }
                      }
                    />
                  </div>
                  <div className="flex flex-row mb-3">
                    <span style={{width: '10%'}}></span>
                    {getFormErrorMessage('phone')}
                  </div>
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                    <label style={{ width: '10%' }}>Address</label>
                    <InputText
                      name="address"
                      id="address"
                      style={{ width: '90%' }}
                      placeholder="Address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3">
                    <label style={{ width: '10%' }}>Customer Type</label>
                    <Dropdown name="customerType" id="customerType" options={ctOptions} value={formik.values.customerType} onChange={formik.handleChange} style={{ width: '90%' }}
                    />
                  </div>
                  <div className="flex flex-row justify-content-evenly align-items-center mb-3" style={{ display: 'none' }}>
                    <label style={{ width: '10%' }}>Question</label>
                    <InputTextarea
                      name="comments"
                      id="comments"
                      placeholder="Question" style={{ width: '90%' }}

                      value={formik.values.comments}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default EditUserDialogChats