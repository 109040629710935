import React from 'react';

const EmptyRecords = (props) => {

    return (
        <div className="grid" style={{width: '100%'}}>
            <div className="col-12">
                {!props.content && <div className="card">
                    <h5>Empty Page</h5>
                    <p>Use this page to start from scratch and place your custom content.</p>
                </div>}
                {
                    props.content &&  (
                        <div className="card">
                            <p>{props.content || ''}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default EmptyRecords;