import React, { useEffect } from 'react';
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import {Dialog} from 'primereact/dialog';
import { Menu } from 'primereact/menu';
// import AddHolidayDialog from './AddHolidayDialog';
// import EditHolidayDialog from './EditHolidayDialog';
import { fetchAgentHolidays, deleteAgentHolidays, fetchAgentHours, updateAgentHours } from '../../../../service/SettingsService';
import moment from 'moment'
import { InputSwitch } from 'primereact/inputswitch';
import EditHoursDialog from './EditHoursDialog';


const AgentHours = () => {

    const [selectedHours, setSelectedHours] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [holiday, setHours] = useState([]);
    const [holidayDialog, setHoursDialog] = useState(false);
    const [deleteHolidayDialog ,setDeleteHolidayDialog] = useState(false)
    const [editHoursDialog, setEditHoursDialog] = useState(false); 
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filteredHours,setFilteredHours] = useState([]);
    

    const [hoursToBeEdited, setHoursToBeEdited] = useState();
    const [holidayToBeDeleted,setHoursToBeDeleted] = useState();
   
   
   

    const toast = useRef(null);
    const dt = useRef(null);
    const menu = useRef();

    useEffect(() => {
        (
            async function getHoursList() {
                setIsLoadingData(true);
                const response = await fetchAgentHours();
                const hoursList = Object.keys(response).map((_x, index) => ({
                    id: response[_x].id || (index + 1),
                    day: _x,
                    startTime: response[_x].startTime,
                    endTime: response[_x].endTime,
                    active: response[_x].active === 'true'
                }));
                setHours(hoursList);
                setFilteredHours(hoursList)
                setIsLoadingData(false);
            }
        )()
      
    }, [])
    

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} tooltip="Add New Holiday" tooltipOptions={{position: 'bottom'}}/> */}
                    {/* <Button label="" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedHours || !selectedHours.length} tooltip="Delete Holidays" tooltipOptions={{position: 'bottom'}} /> */}
                    <Button label="" icon="pi pi-refresh" className="p-button-info" onClick={onRefresh} tooltip="Refresh" tooltipOptions={{position: 'bottom'}}/>
              </div>
            </React.Fragment>
        )
    }

    const openNew = () => {
        setHoursDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteHolidayDialog(true);
        setHoursToBeDeleted(selectedHours)
    }

    const confirmDeleteHolidays = (rowData) => {
        setDeleteHolidayDialog(true);
        setHoursToBeDeleted(rowData)
    }

    const onRefresh = () => {
        (
            async function getHoursList() {
                setIsLoadingData(true);
                const response = await fetchAgentHours();
                const hoursList = Object.keys(response).map((_x, index) => ({
                    id: response[_x].id || (index + 1),
                    day: _x,
                    startTime: response[_x].startTime,
                    endTime: response[_x].endTime,
                    active: response[_x].active === 'true'
                }));
                setHours(hoursList);
                setFilteredHours(hoursList)
                setIsLoadingData(false);
            }
        )()
    }

    const hideDialog = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            await onRefresh();
        }
        setHoursDialog(false);
    }

    const hideDialogEdit = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            await onRefresh();
        }
        setEditHoursDialog(false);
    }

      /**
      * Export as CSV
      */
      const exportCSV = () => {
        if (dt && dt.current) {
            dt.current.exportCSV();
        }
    }

    /**
    * Export as pdf
    */
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, filteredHours);
                doc.save("AgentHours.pdf");
            });
        });
    };

    /**
    * Export columns
    */
    const exportColumns = filteredHours?.[0]
        ? Object.keys(filteredHours?.[0]).reduce((a, c) => {
            const result = c?.replace(/([A-Z])/g, " $1");
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

            a.push({
                title: finalResult,
                dataKey: c,
            });

            return a;
        }, [])
        : [];

    /**
    * Save as excel
    * @param {*} buffer 
    * @param {*} fileName 
    */
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };

    /**
    * Export as excel
    */
    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(filteredHours);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "AgentHours");
        });
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label="Filter" icon="pi pi-ellipsis-v" className="p-button-success mr-2" onClick={openNew} tooltip="Filter"/> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={(event) => menu.current.toggle(event)} />
                <Menu
                    model={[
                        {
                            label: "Export as CSV",
                            icon: "pi pi-file-o",
                            command: () => exportCSV(),
                        },
                        {
                            label: "Export as Excel",
                            icon: "pi pi-file-excel",
                            command: () => exportExcel(),
                        },
                        {
                            label: "Export as PDF",
                            icon: "pi pi-file-pdf",
                            command: () => exportPdf(),
                        },
                    ]}
                    popup
                    ref={menu}
                    id="popup_menu"
                />
            </React.Fragment>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Agent Hours</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateStatus = async (rowData, event) => {
        const payload = {
            day: rowData.day,
            startTime: rowData.startTime,
            endTime: rowData.endTime,
            active: event.value
        }
        const response = await updateAgentHours(payload)
        await onRefresh();
    }
    const statusBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <InputSwitch checked={rowData.active} onChange={(e) => updateStatus(rowData,e)} />
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHolidays(rowData)} tooltip='Edit Holiday' tooltipOptions={{position: 'bottom'}}/>
                <Button icon="pi pi-ban" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteHolidays(rowData)} tooltip='Invalidate Holiday' tooltipOptions={{position: 'bottom'}} /> */}
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <InputSwitch checked={rowData.active} onChange={(e) => console.log(e.value)} /> */}
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHours(rowData)} tooltip='Edit Holiday' tooltipOptions={{position: 'bottom'}}/>
                {/* <Button icon="pi pi-ban" className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteHolidays(rowData)} tooltip='Invalidate Holiday' tooltipOptions={{position: 'bottom'}} /> */}
            </div>
        );
    }

    const editHours = (hoursToBeEdited) => {
        setHoursToBeEdited({ ...hoursToBeEdited });
        setEditHoursDialog(true);
    }

    const hideDialogDelete = async () => { 
        setHoursToBeDeleted(null);
        setDeleteHolidayDialog(false);
     }

    const deleteHoliday = async () => {
        const response  = await deleteAgentHolidays(holidayToBeDeleted);
        if (response === 200) {
            setHoursToBeDeleted(null);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Holiday Added', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
        }
        await onRefresh();
        setDeleteHolidayDialog(false);
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialogDelete} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHoliday} />
        </>
    );

    const sortFn =(event) => {
        const data = [...filteredHours];
        return data.sort((l,r) => {
            if(event.order === -1) {
               return moment.utc(l.date).diff(moment.utc(r.date))
            } else {
               return moment.utc(r.date).diff(moment.utc(l.date))
            }
        })
    }
    

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={filteredHours} selection={selectedHours} onSelectionChange={(e) => setSelectedHours(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Agent Hours"
                        globalFilter={globalFilter} emptyMessage="No data available." header={header} responsiveLayout="scroll" loading={isLoadingData}>
                        <Column field="day" header="DAY" sortable ></Column>
                        <Column field="startTime" header="STARTING HOUR" sortable></Column>
                        <Column field="endTime" header="STOPING HOUR" sortable></Column>
                        <Column  header="STATUS"  body={statusBodyTemplate}></Column>
                        <Column  header="ACTIONS"  body={actionBodyTemplate}></Column>
                    </DataTable>

                    {/* {holidayDialog && <AddHolidayDialog visible={holidayDialog} hideDialog={hideDialog} />} */}
                    {editHoursDialog && hoursToBeEdited && <EditHoursDialog visible={editHoursDialog} hideDialog={hideDialogEdit} hoursToBeEdited={hoursToBeEdited} />}


                    {/* {deleteHolidayDialog && holidayToBeDeleted && <Dialog visible={deleteHolidayDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDialogDelete}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            <span>Are you sure you want to delete these holidays?</span>
                        </div>
                    </Dialog>
                    } */}
                </div>
            </div>
        </div>
    )
}

export default AgentHours;