import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Toolbar } from 'primereact/toolbar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DateRangePicker } from 'react-date-range';
import ImageDialog from './ImageDialog';
import { updateConfirmedMeterReading } from '../../../service/MeterReadingService';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom';
import ChatSessionModal from './ChatSessionModal';

const MeterContents = (props) => {

    const op = useRef();
    const toast = useRef();

    const [contents, setContents] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const [currentDateRange, setCurrentDateRange] = useState(`${moment().startOf('day').format('MMM DD, YYYY')} - ${moment().endOf('day').format('MMM DD, YYYY')}`);
    const [imageData,setImageData] = useState(null);
    const [imageModalVisible,setImageModalVisible] = useState(false);
    const [payload, setPayload] = useState({
        startDate: moment().startOf('day'),
        stopDate: moment().endOf('day')
    })

    const [sessionIdModalOpen,setSessionIdModalOpen] = useState(false);
    const [selectedSession,setSelectedSession] = useState('')


    const dt = useRef();

    useEffect(() => {
        if (props.data) {
            setContents(props.data || [])
        }
    }, [props.data])

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-col-12 p-md-4">
                    <div className="p-inputgroup">
                        <InputText value={currentDateRange} readOnly />
                        <Button icon="pi pi-calendar" type="button" onClick={(e) => op.current.toggle(e)} />
                    </div>
                </div>

                <div className="m-2">
                    <Button label="" icon="pi pi-refresh" className="p-button-info" onClick={props.onRefresh} tooltip="Refresh" tooltipOptions={{ position: 'bottom' }} />
                </div>

                <OverlayPanel ref={op} showCloseIcon id="overlay_panel" >
                    <DateRangePicker
                        onChange={onChangePicker}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={[{
                            startDate: payload.startDate.toDate(),
                            endDate: payload.stopDate.toDate(),
                            key: 'selection'
                        }]}
                        direction="horizontal"
                    />
                    <Button type="button" label="Apply" onClick={(event) => props.applyChanges(payload)} />
                </OverlayPanel>
            </React.Fragment>
        )
    }

    const onChangePicker = ({ selection }) => {
        setPayload({
            startDate: moment(selection.startDate).startOf('day'),
            stopDate: moment(selection.endDate).endOf('day'),
        })
        setCurrentDateRange(`${moment(selection.startDate).startOf('day').format('MMM DD, YYYY')} - ${moment(selection.endDate).endOf('day').format('MMM DD, YYYY')}`)
    }

    const meterReading = (data, field) => {
        return (
            data[field] === 'undefined' ? '' : data[field]
        )
    }

    const imageBody = (data) => {
        return (
            data.files.length > 0 && <Button icon="pi pi-camera" className="p-button-rounded p-button-success mr-2" onClick={() => openImageModal(data)} tooltip='View Image' tooltipOptions={{ position: 'bottom' }} />
        )
    }

    const openImageModal = (data) => {
        setImageData(data);
        setImageModalVisible(true)
    }

    const onHideDialogImage = () => {
        setImageData(null);
        setImageModalVisible(false)
    }

    const uploadDate = (data) => {
        return moment(data.Timestamp).format('DD MMM, YYYY');
    }

    const onConfirm = async (sessionID) => {
        try {
            const response = await updateConfirmedMeterReading(sessionID, true);
            if (response.status === 200) {
                toast.current.show({severity: 'success', summary: 'Success Message', detail: 'Confirmed Successfully'});
                props.onRefresh();
            }
        } catch (err) {
            console.log(err);
            await this.closeLoadingPanel();
        }
    }

    const headerTemplate = (data) => {
        return (
            <>
                    <span style={{fontSize: '12px'}}>{data.sessionID}</span>
                    {props.status === 'SUBMITTED' && <Button tooltip='Confirm' tooltipOptions={{position: 'bottom'}}  icon="pi pi-check" className="p-button-rounded" style={{float: 'right'}} onClick={() => onConfirm(data.sessionID)}/>}
            </>
        );
    }

    const openSessionIDModal = (sessionID) => {
        setSessionIdModalOpen(true);
        setSelectedSession(sessionID);
    }

    const onHideDialogSession = () => {
        setSessionIdModalOpen(false);
        setSelectedSession('');
    }

    const sessionIdBody = (data) => {
        return (
            <a style={{cursor:'pointer'}} onClick={() => openSessionIDModal(data.sessionID)}>{data.sessionID || 'N/A'}</a>
        )
    }


    return (
        <>
            <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
            <Toast ref={toast} />
            <DataTable ref={dt} value={contents} rowGroupMode="subheader" groupRowsBy="sessionID" sortOrder={1} 
                sortMode="single" sortField="sessionID" dataKey="sessionID"
                expandableRowGroups rowGroupHeaderTemplate={headerTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                globalFilter={globalFilter} emptyMessage="No data available." header={header} responsiveLayout="scroll" loading={props.isLoading}
                >
                {/* <Column selectionMode="multiple"></Column> */}
                <Column header="Session#" body={sessionIdBody} />
                <Column header="Account#" field="accountNo" />
                <Column header="Address" field="address" />
                <Column header="Meter Type" field="serviceType" />
                <Column header="Meter#" field="meterNumber" />
                <Column header="Reading1" body={(data) => meterReading(data, "meterReading1")} />
                <Column header="Reading2" body={(data) => meterReading(data, "meterReading2")} />
                <Column header="Reading3" body={(data) => meterReading(data, "meterReading3")} />
                <Column header="Reading4" body={(data) => meterReading(data, "meterReading4")} />
                <Column header="Reading5" body={(data) => meterReading(data, "meterReading5")} />
                <Column header="Reading6" body={(data) => meterReading(data, "meterReading6")} />
                <Column header="Timestamp" body={uploadDate} />
                <Column header="Email" field="email" />
                <Column header="Image" body={imageBody} />
                <Column header="Status" field="proccssedState" />
                <Column header="Processed By" field="processedBy" />
                {props.status === 'CONFIRMED' && <Column header="Approved By" field="ApprovedBy" />}
            </DataTable>
            {imageModalVisible && imageData && <ImageDialog visible={imageModalVisible} data={imageData} header={`Image Details - ${imageData.sessionID ||''}`} hideDialogImage={onHideDialogImage}/>}
            {sessionIdModalOpen && selectedSession && <ChatSessionModal visible={sessionIdModalOpen} sessionID={selectedSession} onHideDialogSession={onHideDialogSession}/>}
        </>
    )
}

export default MeterContents;