export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_ERROR = "AUTHENTICATE_USER_ERROR";

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';

export const RESET_PASSWORD_UPDATE_SUCCESS = 'RESET_PASSWORD_UPDATE_SUCCESS';
export const RESET_PASSWORD_UPDATE_ERROR = 'RESET_PASSWORD_UPDATE_ERROR';
