import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { fetchUsersInformation } from '../../../../service/SettingsService';
import moment from 'moment';
import { AddUserDialog } from './AddUserDialog';
import { EditUserDialog } from './EditUserDialog';
import { sendResetPasswordMail } from '../../../../service/AuthenticationService';
import { UserSettingsDialog } from './UserSettingsDialog';

const statusOptions = [
    { name: "Active", code: "Active" },
    { name: "Inactive", code: "InActive" },
];

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(["Active", "InActive"]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userToBeEdited, setUserToBeEdited] = useState();
    const [editUserDialog, setEditUserDialog] = useState(false);
    const [createUserDialog, setCreateUserDialog] = useState(false);
    const [settingsDialog, setSettingsDialog] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const menu = useRef();
    const filterMenuRef = useRef();


    useEffect(() => {
        (async function getAllUsers() {
            const usersFetched = await fetchUsersList();
            setFilteredUsers(usersFetched || []);
        })()
    }, []);


    const fetchUsersList = async () => {
        setIsLoadingData(true);
        const list = await fetchUsersInformation();
        const usersFetched = Object.keys(list)
            .map((key) => {
                return {
                    id: key,
                    businessNo: "",
                    createdOn: list[key]["Created On"],
                    email: list[key]["Email"],
                    lastLogin: list[key]["Last Login"],
                    admin: list[key]["isAdmin"],
                    role: list[key]["Role"],
                    name: list[key]["Name"],
                    team: list[key]["Team"],
                    status: !!list[key].Status ? "Active" : "InActive",
                };
            })
            .filter((user) => user.email);
        setUsers(usersFetched);
        setIsLoadingData(false);
        return usersFetched;
    }

    const openNew = () => {
        setCreateUserDialog(true);
    }

    const openSettings = () => {
        setSettingsDialog(true);
    }

    const hideDialog = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            const usersFetched = await fetchUsersList();
            setFilteredUsers(usersFetched || []);
        }
        setCreateUserDialog(false);
    }

    const hideDialogUserSettings = (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
        }
        setSettingsDialog(false);
    }


    const editUser = (userToBeEdited) => {
        setUserToBeEdited({ ...userToBeEdited });
        setEditUserDialog(true);
    }

    /**
     * Export as CSV
     */
    const exportCSV = () => {
        if (dt && dt.current) {
            dt.current.exportCSV();
        }
    }

    /**
   * Export as pdf
   */
    const exportPdf = () => {
        const filteredData = users.map((user) => ({      
            id: user.id, 
            email: user.email,
            name: user.name,
            role: user.role,
            status: user.status,
            team: user.team,
            createdOn: moment(user.createdOn).format("MM/DD/YYYY"),
            lastLogin: moment(user.lastLogin).format("MM/DD/YYYY")
        }))
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, filteredData , {
                    columnStyles: {
                        0: { cellWidth: 25 },
                        1: { cellWidth: 25 },
                        2: { cellWidth: 25 },
                        3: { cellWidth: 25 },
                        4: { cellWidth: 25 },
                        5: { cellWidth: 25 },
                        6: { cellWidth: 25 },
                        7: { cellWidth: 20 },
                    }
                });
                doc.save("users.pdf");
            });
        });
    };

    /**
     * Export columns
     */
    const exportColumns = users?.[0]
        ? Object.keys(users?.[0]).reduce((a, c) => {
            if (c != 'businessNo' && c!= 'admin') {
                const result = c?.replace(/([A-Z])/g, " $1");
                const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    
                a.push({
                    title: finalResult,
                    dataKey: c,
                });
            }

            return a;
        }, [])
        : [];

    /**
     * Save as excel
     * @param {*} buffer 
     * @param {*} fileName 
     */
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((FileSaver) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            FileSaver.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };

    /**
     * Export as excel
     */
    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(users.map((user) => ({
                ...user,
                createdOn: moment(user.createdOn).format("MM/DD/YYYY"),
                lastLogin: moment(user.lastLogin).format("MM/DD/YYYY")
            })));
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "users");
        });
    };

    /**
     * Status color
     * @param {*} rowData 
     */
    const statusColor = (rowData) => {
        let color = "#388e3c";


        if (rowData.code === "InActive") {
            color = "#d65c49";
        }

        return (
            <span
                style={{
                    padding: "0.5rem",
                    backgroundColor: color,
                    fontSize: "0.85rem",
                    color: "#fff",
                    borderRadius: "5px",
                }}
            >
                {rowData.name}
            </span>
        );
    }

    const onFilterSelect = (event) => {
        let selectedStatusClone = [...selectedStatus];
        const usersClone = [...users];
        if (event.checked)
            selectedStatusClone.push(event.value);
        else
            selectedStatusClone.splice(selectedStatusClone.indexOf(event.value), 1);

        const filteredUsersClone = usersClone.filter((_t) => selectedStatusClone.includes(_t.status))


        setSelectedStatus(selectedStatusClone);
        setFilteredUsers(filteredUsersClone);
    }

    const hideDialogEdit = async (payload) => {
        if (payload && payload.severity) {
            toast.current.show(payload);
            const usersFetched = await fetchUsersList();
            setFilteredUsers(usersFetched || []);
            setUserToBeEdited();
        }
        setEditUserDialog(false);
    }

    const onRefresh = async () => {
        const usersFetched = await fetchUsersList();
        let selectedStatusClone = [...selectedStatus];
        const filteredUsersClone = usersFetched.filter((_t) => selectedStatusClone.includes(_t.status))
        setFilteredUsers(filteredUsersClone);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} tooltip="Add User" tooltipOptions={{ position: 'bottom' }} />
                    {/* <Button label="" icon="pi pi-ban" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} tooltip="Invalidate User"  tooltipOptions={{position: 'bottom'}}/> */}
                    <Button label="" icon="pi pi-refresh" className="p-button-info  mr-2" onClick={onRefresh} tooltip="Refresh" tooltipOptions={{ position: 'bottom' }} />
                    {/* <Button label="" icon="pi pi-cog" className="p-button-warning" onClick={openSettings} tooltip="Settings" tooltipOptions={{ position: 'bottom' }} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Filter" icon="pi pi-ellipsis-v" className="p-button-success mr-2" onClick={(event) => filterMenuRef.current.toggle(event)} tooltip="Filter" tooltipOptions={{ position: 'bottom' }} />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={(event) => menu.current.toggle(event)} />
                <Menu
                    model={[
                        {
                            label: "Export as CSV",
                            icon: "pi pi-file-o",
                            command: () => exportCSV(),
                        },
                        {
                            label: "Export as Excel",
                            icon: "pi pi-file-excel",
                            command: () => exportExcel(),
                        },
                        {
                            label: "Export as PDF",
                            icon: "pi pi-file-pdf",
                            command: () => exportPdf(),
                        },
                    ]}
                    popup
                    ref={menu}
                    id="popup_menu"
                />
                <Menu
                    ref={filterMenuRef}
                    popup
                    id="filtermenu"
                    model={[
                        {
                            template: () => {
                                return (
                                    <ul className="p-menu-list p-reset" role="menu">
                                        {
                                            statusOptions && statusOptions.map((option, index) => (
                                                <li className="p-menuitem" role="none" key={index}>
                                                    <a className="p-menuitem-link">
                                                        <span className="p-menuitem-icon"><Checkbox id="status" value={option.code} onChange={onFilterSelect} checked={selectedStatus.includes(option.code)} /></span>
                                                        <span className="p-menuitem-text" style={{ marginLeft: '10px' }}>{statusColor(option)}</span>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                );
                            }
                        }
                    ]} />
            </React.Fragment>
        )
    }

    /**
     * Status template
     * @param {*} rowData 
     */
    const statusBodyTemplate = (rowData) => {
        let color = "#388e3c";

        if (rowData.status === "Hold") {
            color = "#f7773c";
        }

        if (rowData.status === "InActive") {
            color = "#d65c49";
        }

        return (
            <span
                style={{
                    padding: "0.5rem",
                    backgroundColor: color,
                    fontSize: "0.85rem",
                    color: "#fff",
                    borderRadius: "5px",
                }}
            >
                {rowData.status}
            </span>
        );
    }

    /**
    * Date template
    * @param {*} value 
    * @param {*} formatVal 
    */
    const dateTemplate = (value, formatVal) => {
        return moment(value).format(formatVal);
    }

    /**
     * Actions template
     * @param {*} rowData 
     */
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} className="p-button-rounded p-button-success mr-2" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-envelope" tooltip="Reset Password" tooltipOptions={{ position: 'bottom' }} className="p-button-rounded p-button-info mr-2" onClick={() => resetPasswordRow(rowData)} />
                {/* <Button icon="pi pi-ban" tooltip="Invalidate" tooltipOptions={{position: 'bottom'}} className="p-button-rounded p-button-danger mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const resetPasswordRow = async (rowData) => {
        const response = await sendResetPasswordMail({ emailAddress: rowData?.email })
        if (response.status === 200) {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Email Sent Successfully!', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
        }
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={filteredUsers} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        globalFilter={globalFilter} emptyMessage="No data available." header={header} responsiveLayout="scroll" loading={isLoadingData}>
                        <Column selectionMode="multiple"></Column>
                        <Column field="email" header="EMAIL ADDRESS" sortable ></Column>
                        <Column field="role" header="ROLE" sortable></Column>
                        <Column field="createdOn" header="CREATED ON" body={(rowData) => dateTemplate(rowData.createdOn, 'MM/DD/YYYY')} sortable></Column>
                        <Column field="lastLogin" header="LAST SIGNED IN" body={(rowData) => dateTemplate(rowData.createdOn, 'MM/DD/YYYY')} sortable></Column>
                        <Column field="team" header="TEAM" sortable></Column>
                        <Column header="STATUS" body={statusBodyTemplate} sortable></Column>
                        {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column header="ACTIONS" body={actionBodyTemplate}></Column>
                    </DataTable>

                    {createUserDialog && <AddUserDialog visible={createUserDialog} hideDialog={hideDialog} />}
                    
                    {settingsDialog && <UserSettingsDialog visible={settingsDialog} hideDialog={hideDialogUserSettings} />}

                    {editUserDialog && userToBeEdited && <EditUserDialog visible={userToBeEdited} hideDialog={hideDialogEdit} userToBeEdited={userToBeEdited} />}


                    {/* <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to invalidate <b>{product.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to invalidate the selected users?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserManagement, comparisonFn);