import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { hideAlert } from "../../../redux/alerts/actions";
import { renameAlertMessage } from "../../../helpers/alert.helper";
import { resetPasswordAction } from "../../../redux/user/actions";
import { emailValidator } from "../../../helpers/validations";

export default function ForgotPassword({ history }) {
    const messages = useRef();
    const alertState = useSelector((state) => state?.alerts);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (alertState.show) {
            messages.current.replace({
                sticky: true,
                severity: alertState.type,
                detail: renameAlertMessage(alertState.message),
            });
        }
    }, [alertState]);

    const _handleForgotPassword = async ({
        emailAddress,
    }) => {
        setLoading(true);
        await dispatch(
            resetPasswordAction({ emailAddress })
        );
        setLoading(false);
    };

    const _onCloseAlert = () => {
        dispatch(hideAlert());
    };

    const formik = useFormik({
        initialValues: {
            emailAddress: "",
        },
        validate: (data) => {
            let errors = {};

            if (!data.emailAddress) {
                errors.emailAddress = "Email Address is required.";
            }

            if (emailValidator(data.emailAddress)) {
                errors.emailAddress = "Invalid email.";
            }

            return errors;
        },
        onSubmit: (data) => {
            _handleForgotPassword(data);

            formik.resetForm();
            formik.validateForm();
        },
        validateOnMount: true
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const redirectTo = () => {
        dispatch(hideAlert());
        history.push('/')
    }

    return (
        <div className="login-container">
            <div className="login-transparent-layer">

                <div
                    className="login-form-container"
                >
                    <Card className="login-form-card">
                        <div className="login-form-col">
                            <img src='images/logos/logo.jpg' className="login-logo" alt="logo" />
                            <div className="flex align-items-center justify-content-center mb-5">
                                <h5>Reset Password</h5>
                            </div>
                            <form
                                onSubmit={formik.handleSubmit}
                                className="p-fluid login-form-card"
                            >
                                <div className="p-field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi pi-envelope" />
                                        <InputText
                                            id="emailAddress"
                                            name="emailAddress"
                                            value={formik.values.emailAddress}
                                            onChange={formik.handleChange}
                                            className={classNames({
                                                "p-invalid": isFormFieldValid("emailAddress"),
                                            })}
                                        />
                                        <label
                                            htmlFor="emailAddress"
                                            className={classNames({
                                                "p-error": isFormFieldValid("emailAddress"),
                                            })}
                                        >
                                            Email Address *
                                        </label>
                                    </span>
                                    {getFormErrorMessage("emailAddress")}
                                </div>


                                <Messages ref={messages} onRemove={_onCloseAlert}></Messages>

                                <div className="login-form-buttons-container">
                                    <div className="my-2">
                                        <Button
                                            type="submit"
                                            label="Reset Password"
                                            disabled={!formik.isValid || formik.isSubmitting}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                                {/* <Button label="Login" onClick={redirectTo} className="p-button-link" /> */}
                            </form>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
