import React, {useEffect, useState} from 'react';
import { Password } from "primereact/password";
import { registerUser, createUserDetails, fetchTeamlist } from "../../../../service/SettingsService";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';

const roles = [
    {label: 'Admin', value: 'Admin'},
    {label: 'Agent', value: 'Agent'},
    {label: 'Analyst', value: 'Analyst'},
    {label: 'Supervisor', value: 'Supervisor'}
]

export const AddUserDialog = (props) => {
    const [teams, setTeams] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const formik = useFormik({
        initialValues: {
            name: "",
            emailAddress: "",
            password: "",
            confirmPassword: "",
            role: "",
            team: "",
            enableChatBot:false
        },
        validate: (data) => {
          let errors = {};
    
          if (!data.name) {
            errors.name = "This field is required.";
          }

          if (!data.emailAddress) {
            errors.emailAddress = "This field is required.";
          }

          if (!data.emailAddress.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            errors.emailAddress = "Invalid Email."
          }
    
          if (!data.password) {
            errors.password = "This field is required.";
          }

          if (!data.confirmPassword) {
            errors.confirmPassword = "This field is required.";
          }

          if (!data.team) {
            errors.team = "This field is required.";
          }

          if (!data.role) {
            errors.role = "This field is required.";
          }

          if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Password does not match.";
          }
    
          return errors;
        },
        onSubmit: async (data) => {
            try {
                await createNewUser(data);
                formik.resetForm();
                props.hideDialog({ severity: 'success', summary: 'Successful', detail: 'User Added', life: 3000 });
            } catch (e) {
                console.error(e);
                props.hideDialog({ severity: 'error', summary: 'Oops!', detail: 'Something Went Wrong. Please try after sometime.', life: 3000 });
            }
          
        },
      });

      useEffect(() => {
        (
            async function getTeams() {
                await fetchTeams();
            }
        )()
      }, [])

      const createNewUser = async (payload) => {
        await registerUser(payload);
        await createUserDetails(payload);
      }

      const fetchTeams = async () => {
        setIsLoadingData(true);
        const list = await fetchTeamlist();
        const teamsFetched = Object.keys(list || [])
            .map((key) => ({
                label: list[key].Team_Name,
                value: list[key].Team_Name,
            }))
        setTeams(teamsFetched || []);
        setIsLoadingData(false);
    }
    
      const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
      const getFormErrorMessage = (name) => {
        return (
          isFormFieldValid(name) && (
            <small className="p-error">{formik.errors[name]}</small>
          )
        );
      };

    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={formik.handleSubmit} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: '450px' }} header="Add User" modal className="p-fluid" footer={dialogFooter} onHide={props.hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={formik.values.name} onChange={formik.handleChange} required autoFocus  className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            {getFormErrorMessage('name')}
                        </div>
                        <div className="field">
                            <label htmlFor="emailAddress">Email</label>
                            <InputText id="emailAddress" value={formik.values.emailAddress} onChange={formik.handleChange} required  className={classNames({ 'p-invalid': isFormFieldValid('emailAddress') })} />
                            {getFormErrorMessage('emailAddress')}
                        </div>
                        <div className="field">
                            <label htmlFor="password">Password</label>
                            <Password name="password" id="password" value={formik.values.password} onChange={formik.handleChange} required  className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="field">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <Password name="confirmPassword" id="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} required  className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} />
                            {getFormErrorMessage('confirmPassword')}
                        </div>
                        <div className="field">
                            <label htmlFor="role">Role</label>
                            <Dropdown placeholder="Select Role" id="role" name="role" value={formik.values.role} onChange={formik.handleChange} options={roles} className={classNames({ 'p-invalid': isFormFieldValid('role') })} />
                            {getFormErrorMessage('role')}
                        </div>
                        <div className="field">
                            <label htmlFor="team">Team</label>
                            <Dropdown id="team" name="team" value={formik.values.team} onChange={formik.handleChange} options={teams} className={classNames({ 'p-invalid': isFormFieldValid('team') })} />
                            {getFormErrorMessage('team')}
                        </div>
                        <div className="field">
                            <label htmlFor="enableChatbot">Enable ChatBot</label>
                            <InputSwitch checked={formik.values.enableChatbot} onChange={(e) => formik.setFieldValue('enableChatbot', e.value)} />
                            {getFormErrorMessage('team')}
                        </div>
                    </Dialog>
    )
}