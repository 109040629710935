import * as userType from "./types";
import { changePassword, loginUser, loginWithSAML, logoutUser, sendResetPasswordMail } from "../../service/AuthenticationService";
import * as alertType from "../alerts/types";
import { hideAlert } from "../alerts/actions";

const types = {
  ...userType,
  ...alertType,
};

export const authenticateUser =
  ({
    emailAddress,
    password,
  }) =>
  async (dispatch) => {
    const userCredentials = await loginUser({
      email: emailAddress || "",
      password: password || "",
    });
    if (userCredentials.status === 200) {
      const userPayload = {
        userId: userCredentials?.data?.user?.uid || "",
        emailAddress: userCredentials?.data?.user?.email || "",
        role: "CUSTOMER",
        theme: "light",
      };

      dispatch(hideAlert());
      dispatch({
        type: types.AUTHENTICATE_USER_SUCCESS,
        payload: { user: userPayload },
      });

      return true;
    } else {
      dispatch({ type: types.AUTHENTICATE_USER_ERROR });
      dispatch({
        type: types.SHOW_ALERT,
        payload: { message: userCredentials.statusText, type: "error" },
      });

      return false;
    }
};

export const authenticateUserSAML = () =>
  async (dispatch) => {
    const userCredentials = await loginWithSAML();
    if (userCredentials.status === 200) {
      const userPayload = {
        userId: userCredentials?.data?.user?.uid || "",
        emailAddress: userCredentials?.data?.user?.email || "",
        role: "CUSTOMER",
        theme: "light",
      };

      dispatch(hideAlert());
      dispatch({
        type: types.AUTHENTICATE_USER_SUCCESS,
        payload: { user: userPayload },
      });

      return true;
    } else {
      dispatch({ type: types.AUTHENTICATE_USER_ERROR });
      dispatch({
        type: types.SHOW_ALERT,
        payload: { message: userCredentials.statusText, type: "error" },
      });

      return false;
    }
};


export const logout =
  () =>
  async (dispatch) => {
    const logoutResponse = await logoutUser();
    if (logoutResponse.status === 200) {
      dispatch({ type: types.LOGOUT_USER_SUCCESS });
    } else {
      dispatch({ type: types.AUTHENTICATE_USER_ERROR });
      dispatch({
        type: types.SHOW_ALERT,
        payload: { message: logoutResponse.statusText, type: "error" },
      });
    }
  };

export const changePasswordAction =
  (payload) =>
  async (dispatch) => {
    const changePasswordResponse = await changePassword(payload)
    if (changePasswordResponse.status === 200) {
      dispatch({ type: types.PASSWORD_UPDATE_SUCCESS });
      dispatch({type: types.SHOW_ALERT, payload: {message: 'Password updated sucessfully', type: 'success'}})
    } else {
      dispatch({ type: types.PASSWORD_UPDATE_ERROR });
      dispatch({type: types.SHOW_ALERT, payload: {message: changePasswordResponse.statusText, type: 'error'}})
    }
};

export const resetPasswordAction =
  (payload) =>
  async (dispatch) => {
    const resetPasswordResponse = await sendResetPasswordMail(payload)
    if (resetPasswordResponse.status === 200) {
      dispatch({ type: types.RESET_PASSWORD_UPDATE_SUCCESS });
      dispatch({type: types.SHOW_ALERT, payload: {message: 'Email sent. Please check your mail.', type: 'success'}})
    } else {
      dispatch({ type: types.RESET_PASSWORD_UPDATE_ERROR });
      dispatch({type: types.SHOW_ALERT, payload: {message: resetPasswordResponse.statusText, type: 'error'}})
    }
};

